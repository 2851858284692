import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import Error from "./pages/Error";
import ProtectedRoute from "./pages/ProtectedRoute";
import { Home, Profile, ProfileEdit } from "./pages/dashboard";
import SharedLayout from "./pages/dashboard/SharedLayout";
import CheckOutDetails from "./pages/dashboard/CheckOutDetails";
import Service from "./pages/dashboard/Service";
import MyBookings from "./pages/dashboard/MyBookings";
import BookingDetails from "./pages/dashboard/BookingDetails";
import Reviews from "./pages/dashboard/Reviews";
import SetPassword from "./pages/SetPassword";
import SavedAddress from "./pages/dashboard/SavedAddress";
import GetOtp from "./pages/GetOtp";
import ChangePassword from "./pages/dashboard/ChangePassword";
import CartView from "./pages/dashboard/Checkout/CartView";
import CheckoutAddress from "./pages/dashboard/Checkout/CheckoutAddress";
import CheckoutSlot from "./pages/dashboard/Checkout/CheckoutSlot";
import CheckoutPayment from "./pages/dashboard/Checkout/CheckoutPayment";
import BookingCompleted from "./pages/dashboard/BookingCompleted";
import Help from "./pages/dashboard/Help";
import RentalHome from "./pages/dashboard/RentalHome";
import ExploreMore from "./pages/dashboard/ExploreMore";
import Faqs from "./pages/dashboard/Faqs";
import ViewProductDetail from "./pages/dashboard/ViewProductDetail";
import RentalCart from "./pages/dashboard/RentalCart";
import Search from "./pages/dashboard/Search/Search";
import "./App.css";
import UnProtectedRoute from "./pages/UnProtectedRoute";
import RazorPay from "./pages/dashboard/RazorPay";
import {
  CustomerService,
  GetLoginOtp,
  OtpLogin,
  PrivacyPolicy,
  RateChart,
  RefundsCancellations,
  TermsConditions,
  Upcomming,
} from "./pages";
import Guide from "./pages/dashboard/Guide/Guide";
import { CommingSoonLayout } from "./components";
import Settings from "./pages/dashboard/setttings/Settings";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Online> */}
        <Routes>
          {/* ###################### UNPROTECTED ROUTE ##################### */}
          <Route
            element={
              <UnProtectedRoute>
                <SharedLayout />
              </UnProtectedRoute>
            }>
            <Route path="/" element={<Home />} />
            <Route path="/service/:subCatgId" element={<Service />} />
            <Route
              path="/service/rate-chart/:subCatgId"
              element={<RateChart />}
            />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/search" element={<Search />} />
            <Route path="/search/:query" element={<Search />} />

            <Route path="/_" element={<Upcomming />} />
            <Route path="/help" element={<Help />} />
            <Route path="/help/FAQs" element={<Faqs />} />
            <Route path="/help/guide" element={<Guide />} />
            <Route path="/help/support" element={<CustomerService />} />
            <Route path="/nouser/service/:subCatgId" element={<Service />} />
            <Route path="/nouser/rental" element={<RentalHome />} />
            <Route
              path="/nouser/rental/explore-more"
              element={<ExploreMore />}
            />
            <Route
              path="/nouser/rental-home/explore-more/view-detail"
              element={<ViewProductDetail />}
            />
            <Route path="/term-condition" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/refunds/cancellations"
              element={<RefundsCancellations />}
            />
          </Route>
          {/* ##################### AUTHS ##################### */}
          <Route path="/register" element={<Register />} />
          <Route path="/register/verify-otp/:emailId" element={<GetOtp />} />
          <Route path="/password-login" element={<Login />} />
          <Route path="/login" element={<OtpLogin />} />
          <Route
            path="/login/verify-otp/:phoneNoEnc"
            element={<GetLoginOtp />}
          />
          <Route path="/forgotpassword" element={<ForgetPassword />} />

          {/* ###################### PROTECTED ROUTE ##################### */}
          <Route
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }>
            <Route path="/profile" element={<Profile />} />
            <Route path="/pay" element={<RazorPay />} />
            <Route path="/profile/edit" element={<ProfileEdit />} />
            <Route path="/my-bookings" element={<MyBookings />} />
            <Route path="/rental" element={<RentalHome />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/rental/explore-more" element={<ExploreMore />} />
            <Route
              path="/booking-details/:bookingId"
              element={<BookingDetails />}
            />
            <Route
              path="/check-out-details/:subCatgId"
              element={<CheckOutDetails />}
            />
            <Route
              path="/check-out-details/cart/:subCatgId"
              element={<CartView />}
            />
            <Route
              path="/check-out-details/address/:subCatgId"
              element={<CheckoutAddress />}
            />
            <Route
              path="/check-out-details/slot/:subCatgId"
              element={<CheckoutSlot />}
            />
            <Route
              path="/check-out-details/payment/:subCatgId"
              element={<CheckoutPayment />}
            />
            <Route
              path="/check-out-details/Completed/:bookingId"
              element={<BookingCompleted />}
            />

            <Route path="/address" element={<SavedAddress />} />
            <Route path="/change-password" element={<ChangePassword />} />

            <Route
              path="/rental-home/explore-more/view-detail"
              element={<ViewProductDetail />}
            />
            <Route path="/rental-cart" element={<RentalCart />} />
          </Route>

          <Route path="*" element={<Error />} />
          <Route path="/setPassword" element={<SetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
