import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UnProtectedRoute = ({ children }) => {
  //   const location = useLocation();
  //   console.log(location);

  // const pathName = location?.state?.path;

  //   console.log(unProtectedUrls?.indexOf(pathName));

  return children;
};

export default UnProtectedRoute;
