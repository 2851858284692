import React, { useEffect, useState } from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { useDispatch } from "react-redux";
import { clearStore } from "../../../features/user/UserSlice";
import Confirm from "../../../components/Notifications/Confirm";

const Settings = () => {
  const dispatch = useDispatch();
  const [isConfinrmLogout, setConfirmLogout] = useState(false);

  const handleLogout = (flag) => {
    if (flag) {
      dispatch(clearStore("Logged Out Successfully!"));
    }

    setConfirmLogout(false);
  };
  //Viewport width
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    console.log(window.innerWidth);
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  //   #####################################
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen">
      {/* Popups */}
      {isConfinrmLogout && (
        <Confirm
          question="Are you sure you want to logout?"
          handleConfirmation={handleLogout}
          sence={true}
        />
      )}
      {isDesktop ? (
        <Desktop handleLogOut={() => setConfirmLogout(true)} />
      ) : (
        <Mobile handleLogOut={() => setConfirmLogout(true)} />
      )}
    </div>
  );
};

export default Settings;
