import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { forgotPassword, loginUser } from "../features/user/UserSlice";
import { useNavigate } from "react-router-dom";
import { signin_banner } from "../utils/Urls";
import talency_logo from "../Images/talency_logo.png";

const initialState = {
  email: "",
};

const ForgetPassword = () => {
  const [values, setValues] = useState(initialState);
  const { user, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const { email } = values;
    if (!email || !validateEmail(email)) {
      alert("Please fill out all fields");
      return;
    }
    dispatch(forgotPassword({ email: email }));
  };

  const toggleMember = () => {
    setTimeout(() => {
      navigate("/login");
    }, 10);
  };

  // ###################################
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyname: "",
    password: "",
    confirmpassword: "",
  });

  const validateName = (firstName) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(firstName);
  };

  const validateLastName = (lastName) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(lastName);
  };

  const validateCompanyName = (companyname) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(companyname);
  };

  const validateEmail = (login) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(login);
  };
  const validatePassword = (Password) => {
    // const regex = /^(?=.\d)(?=.[!@#$%^&])(?=.[a-z])(?=.*[A-Z]).{8,}$/;
    const regex = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    return regex.test(Password);
  };

  const handleInputFocusOut = (event) => {
    const { name, value } = event.target;
    if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, email: "Please enter a valid email" });
    } else if (name === "firstName" && !validateName(value)) {
      setErrors({
        ...errors,
        firstName:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "lastName" && !validateLastName(value)) {
      setErrors({
        ...errors,
        lastName:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "companyname" && !validateCompanyName(value)) {
      setErrors({
        ...errors,
        companyname:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "password" && !validatePassword(value)) {
      setErrors({
        ...errors,
        password:
          "password should contain atleast one number and one special character",
      });
    } else if (name === "confirmpassword" && value != values.password) {
      setErrors({
        ...errors,

        confirmpassword: "Password is not matching.",
      });
    } else {
      // input is correct, remove error message for this field
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 10);
    }
  }, [user, navigate]);

  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
        {/* <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center text-center">
          <h2 className="text-4xl text-white font-bold mb-4">
            Welcome to ClapUrban
          </h2>
          <p className="text-medium text-white">
            simply dummy text of the printing and typesetting industry
          </p>
        </div> */}
      </div>

      <div className="w-full lg:w-1/2">
        <div className="mt-10">
          <img src={talency_logo} alt="Logo" className="mx-auto" />
        </div>

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className="form-section">
            <form onSubmit={onSubmit}>
              <div className="mb-2 mt-5">
                {/* Email field */}
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border roundued-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  onBlur={handleInputFocusOut}
                  // pattern="/^[^\s@]+@[^\s@]+\.[^\s@]+$/"
                  // title="Enter a valid email!"
                  style={errors.email ? { borderColor: "red" } : null}
                  required
                />
                {errors.email && (
                  <div style={{ color: "red" }} className="text-xs pl-2">
                    {errors.email}
                  </div>
                )}
              </div>

              <div className="mt-6">
                <button
                  disabled={isLoading}
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-neutral-800 rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600">
                  {isLoading ? "Loading..." : "Forgot Password"}
                </button>
              </div>

              <p className="mt-8 text-xs font-light text-center text-gray-700">
                Remember Password?
                <button
                  type="button"
                  className="font-medium text-neutral-800 outline-white hover:underline ml-2"
                  onClick={toggleMember}>
                  <strong>Sign In</strong>
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
