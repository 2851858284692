import React from "react";
import { useEffect, useState } from "react";
import Desktop from "../../components/CheckOutDetails/Desktop";
import Mobile from "../../components/CheckOutDetails/Mobile";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStatus,
  postBooking,
  setLoadingBooking,
} from "../../features/Bookings/BookingSlice";
import { useParams } from "react-router-dom";
import { fetchIssue } from "../../features/Issue/IssuesSlice";

const CheckOutDetails = () => {
  const dispatch = useDispatch();
  const { subCatgId } = useParams();

  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const [addressId, setAddress] = useState(1);
  const [mobileNo, setMobileNo] = useState(null);
  const date = new Date();
  date.setTime(date.getTime() + 5.5 * 60 * 60 * 1000);
  const [slot, setSlot] = useState(date.toISOString().substr(0, 16));
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isConfirm, setConfirm] = useState(false);
  const { totalAmount } = useSelector((state) => state.cart);
  const { status, confirmed } = useSelector((state) => state.booking);
  const { subCatagory } = useSelector((store) => store.issue);
  const { categoryName } = useSelector((store) => store.subcategory);

  const handleAddressSelect = (id) => {
    setAddress(id);
  };
  const handleMobileSelect = (number) => {
    setMobileNo(number);
  };
  const handleSlotChange = (e) => {
    const selectedDateTime = e.target.value;
    // Convert to ISO format
    const isoDateTime = new Date(selectedDateTime).toISOString().substr(0, 16);
    isoDateTime.replace(/:\d{2}$/, ":00");
    setSlot(isoDateTime);
  };
  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleSubmit = (e) => {
    console.log("Submiting");
    if (paymentMethod === "cash") {
      dispatch(
        postBooking({
          subCategoryName: subCatagory?.name,
          categoryName: categoryName,
          bookingSlot: new Date(slot).toISOString(),
          amount: totalAmount,
          paymentMode: paymentMethod,
          status: "future",
          active: true,
        })
      );
    } else
      dispatch(
        postBooking({
          subCategoryName: subCatagory?.name,
          categoryName: categoryName,
          bookingSlot: new Date(slot).toISOString(),
          amount: totalAmount,
          paymentMode: "online",
          status: "future",
          active: true,
        })
      );
  };
  const updateMedia = () => {
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => {
      window.removeEventListener("resize", updateMedia);
    };
  });

  useEffect(() => {
    if (status === 201) {
      setConfirm(true);
    }
  }, [status]);

  useEffect(() => {
    dispatch(setLoadingBooking());
    dispatch(fetchIssue(subCatgId));
    dispatch(clearStatus());
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="h-full w-full">
      {isDesktop ? (
        <Desktop
          addressId={addressId}
          handleAddressSelect={handleAddressSelect}
          handleMobileSelect={handleMobileSelect}
          mobileNo={mobileNo}
          slot={slot}
          handleSlotChange={handleSlotChange}
          handlePaymentMethodChange={handlePaymentMethodChange}
          paymentMethod={paymentMethod}
          handleSubmit={handleSubmit}
          isConfirmed={isConfirm}
          confirmed={confirmed}
        />
      ) : (
        <Mobile />
      )}
    </div>
  );
};

export default CheckOutDetails;
