import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLocationFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { toast } from "sonner";
import axios from "axios";

const initialState = {
  isLoadingLocation: false,
  autoFillLocations: [],
  userLocation: {
    address: getLocationFromLocalStorage(),
  },
};

//post lot long
export const checkMyLocation = createAsyncThunk(
  "home/checkMyLocation",
  async ({ city, location }, thunkAPI) => {
    try {
      const resp = await customFetch.post("/api/map/geolocation", city);
      console.log(resp);
      return { data: resp?.data, location: location };
    } catch (error) {
      if (error) {
        toast.error("Error! Please refresh.");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

//get locations list
export const getAddressFromLatLon = createAsyncThunk(
  "home/getLocations",
  async ({ lat, lon }, thunkAPI) => {
    const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    try {
      const resp = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${API_KEY}`
      );
      console.log(resp);
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

const LocationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLoadingLocation: (state, { payload }) => {
      state.isLoadingLocation = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(checkMyLocation.pending, (state) => {
        state.isLoadingLocation = true;
      })
      .addCase(checkMyLocation.fulfilled, (state, { payload }) => {
        state.isLoadingLocation = false;
      })
      .addCase(checkMyLocation.rejected, (state) => {
        state.isLoadingLocation = false;
      });
  },
});

export default LocationSlice.reducer;
