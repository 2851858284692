import { removeStoreFromLocalStorage } from "../../utils/localStorage";
import { clearLocalCart } from "../Cart/CartSlice";
import { logoutUser } from "./UserSlice";

export const clearStoreThunk = async (message, thunkAPI) => {
  try {
    thunkAPI.dispatch(logoutUser(message));
    thunkAPI.dispatch(clearLocalCart());
    // removeStoreFromLocalStorage();

    return Promise.resolve();
  } catch (error) {
    return Promise.reject();
  }
};
