import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  postPartnerRating,
  postRating,
} from "../features/Bookings/BookingSlice";
import { useDispatch } from "react-redux";
import Ratings from "./Ratings";

const WriteReview = ({ handleWriting, bookingId, partnerId }) => {
  const dispatch = useDispatch();

  const [reviewTitle, setReviewTitle] = useState("");
  const handleReviewTitle = (e) => {
    setReviewTitle(e.target.value);
  };

  const [reviewDescription, setReviewDescription] = useState("");
  const handleReviewDescription = (e) => {
    setReviewDescription(e.target.value);
  };

  const [rating, setRating] = useState(0);
  const handleRating = (stars) => {
    setRating(stars);
  };

  const handleSubmit = () => {
    if (rating && reviewTitle != "") {
      if (partnerId) {
        dispatch(
          postPartnerRating({
            data: {
              rating: rating,
              review: reviewTitle,
              description: reviewDescription,
              show: true,
            },
            bookingId: bookingId,
            partnerId: partnerId,
          })
        );
      } else {
        dispatch(
          postRating({
            data: {
              rating: rating,
              review: reviewTitle,
              description: reviewDescription,
              show: true,
            },
            bookingId: bookingId,
          })
        );
      }

      handleWriting();
    } else {
      alert("Please fill out all the fields");
    }
  };

  return (
    <div className="fixed z-50 w-full h-full flex left-0 top-0 items-center justify-center bg-[#00000083] overflow-auto max-sm:items-end max-sm:justify-end">
      {/* main popupo */}
      <div className="w-full h-full absolute" onClick={handleWriting}></div>
      <div className="relative flex flex-col items-center justify-between w-[26rem] h-[30rem] bg-white rounded-md   expand max-sm:mt-20 max-sm:w-full max-sm:rounded-t-md">
        <div className="flex items-center  justify-center bg-white rounded-t-md p-3 font-bold mt-[0.7rem]">
          <span className="text-xl">Write Review</span>
          <span
            className="absolute -top-12 right-0 bg-white rounded-full  cursor-pointer max-sm:hidden"
            onClick={handleWriting}>
            <AiOutlineClose className="m-2 text-2xl" />
          </span>
        </div>
        <div className="flex flex-col mt-[0.7rem] gap-[0.5rem]">
          <div className="flex gap-[0.3rem] text-[32px] ">
            <Ratings ratings={rating} getStar={handleRating} isHover={false} />
          </div>
        </div>
        <div className="flex flex-col mt-[1.4rem] gap-[0.5rem] max-sm:w-11/12">
          <div className="font-semibold">Review Title</div>
          <input
            type="text"
            className="border border-[#707070] w-[22rem] max-sm:w-full h-[2.5rem] rounded-md outline-none px-[0.6rem] text-sm py-[0.2rem] "
            placeholder="Enter the title for your review"
            value={reviewTitle}
            onChange={handleReviewTitle}
          />
        </div>
        <div className="flex flex-col mt-[1.4rem] gap-[0.5rem] max-sm:w-11/12">
          <div className="font-semibold">Review Description</div>
          <textarea
            type="text"
            className="border border-[#707070] w-[22rem] max-sm:w-full h-[8rem] rounded-md px-[0.6rem] text-sm py-[0.5rem] outline-none"
            placeholder="Enter the decription for your review"
            value={reviewDescription}
            onChange={handleReviewDescription}
          />
        </div>

        <div className="flex items-center justify-center  w-full h-1/6  mx-auto max-sm:items-end  ">
          <button
            type="Submit"
            className="p-1 py-3 my-4  rounded-md bg-primary-600 cursor-pointer uppercase font-semibold w-[22rem] text-white text-center max-sm:w-full max-sm:rounded-none max-sm:my-0 "
            onClick={handleSubmit}>
            Submit Review
          </button>
        </div>
      </div>
    </div>
  );
};

export default WriteReview;
