import React from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { Decode } from "../../../utils/GlobalFunctions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const CustomerService = () => {
  //Viewport width
  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 720);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 720);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return <>{isDesktop ? <Desktop /> : <Mobile />}</>;
};

export default CustomerService;
