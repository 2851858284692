import React from "react";
import Cart from "../Cart";
import { BiSolidLocationPlus } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import {
  FaTools,
  FaAddressCard,
  FaBusinessTime,
  FaRupeeSign,
} from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";
import { useState } from "react";
import ChangeNumber from "../ChangeNumber";
import SelectAddress from "../SelectAddress";
import SlotOption from "../SlotOption";
import { useSelector } from "react-redux";
import Completed from "../Completed";
import { useEffect } from "react";

const Desktop = ({
  addressId,
  handleAddressSelect,
  handleMobileSelect,
  mobileNo,
  handleSlotChange,
  slot,
  handlePaymentMethodChange,
  paymentMethod,
  handleSubmit,
  isConfirmed,
  confirmed,
}) => {
  const [steps, setSteps] = useState(1);
  const [isChanging, setChanging] = useState(false);
  const [isPaying, setPaying] = useState(null);

  const { isLoadingBooking } = useSelector((state) => state.booking);
  const handleClick = (step) => {
    setChanging(!isChanging);
    {
      step > 0 ? setSteps(step) : setSteps(steps);
    }
  };

  const onSubmit = () => {
    if (paymentMethod === "cash") {
      handleSubmit();
    } else if (isPaying === true) {
      setPaying(false);
    } else {
      alert("Under Development!");
      setPaying(true);
    }
  };

  useEffect(() => {
    if (isConfirmed === true) setPaying(false);
  }, [isConfirmed]);
  return (
    <div className="h-full w-full overflow-hidden ">
      {isConfirmed && <Completed confirmed={confirmed} />}
      {/* Popups */}
      {isChanging && steps === 1 && (
        <ChangeNumber
          handleClick={handleClick}
          setMobileNo={handleMobileSelect}
          mobileNo={mobileNo}
        />
      )}
      {isChanging && steps === 2 && (
        <SelectAddress
          addressId={addressId}
          setAddress={handleAddressSelect}
          handleClick={handleClick}
        />
      )}
      {isChanging && steps === 3 && (
        <SlotOption
          handleClick={handleClick}
          slot={slot}
          setSlot={handleSlotChange}
        />
      )}

      <div className=" w-full flex flex-col items-center  mb-20">
        {/* heading */}
        <div className=" flex justify-center w-full">
          <div className="relative w-5/6 border-b-2 px-8 flex items-center gap-4 bg-gray-500 text-white py-2">
            <FaTools className="text-3xl my-2 " />

            <h1 className="uppercase text-3xl font-normal">Checkout</h1>
            <AiOutlineRollback className="absolute  text-3xl my-2 hover:bg-gray-300 hover:text-black right-12" />
          </div>
        </div>
        {/* Mian Content */}
        <div className="p-1 py-4 w-5/6 px-8">
          <div className="flex   gap-8">
            <div>
              {/* Cart */}
              <div className="w-96">
                <Cart />
              </div>
              {/* Offers */}
              <div>O</div>
              {/* Payment sumary */}
              <div>Q</div>
            </div>
            {/* Booking steps */}
            <div className="relative w-full min-h-max h-full">
              <div className="sticky top-0 w-4/5 m-auto border rounded-lg min-w-max">
                {/* Mobiol number */}
                {steps >= 1 && (
                  <div
                    className="  px-2 py-3 border-y"
                    onClick={() => handleClick(1)}>
                    <div className="flex items-center gap-2 capitalize mb-4 mt-1 text-lg">
                      {/*ICON*/}
                      <div className="p-2 ">
                        <BiSolidLocationPlus className="text-4xl" />
                      </div>
                      <div>
                        <h1>Select mobile number</h1>
                        <span className="text-lg font-normal text-gray-500">
                          +91{mobileNo ? mobileNo : "______________ "}
                        </span>
                      </div>
                    </div>
                    {steps === 1 && (
                      <div className="w-11/12 m-auto">
                        <button className="bg-primary-600 hover:bg-[#ff169a] text-md w-full p-2 rounded-md  font-bold text-white capitalize">
                          Proceed
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {/* Address */}
                {steps >= 2 && (
                  <div
                    className="px-2 py-3 border-y"
                    onClick={() => handleClick(2)}>
                    <div className="flex items-center gap-2 capitalize mb-4 mt-1 text-lg">
                      {/*ICON*/}
                      <div className="p-2 ">
                        <FaAddressCard className="text-4xl" />
                      </div>
                      <h1>Select Address</h1>
                    </div>
                    {steps === 2 && (
                      <div className="w-11/12 m-auto">
                        <button className="bg-primary-600 hover:bg-[#ff169a] text-md w-full p-2 rounded-md capitalize font-bold text-white">
                          Change Address
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {/* Time Slot */}
                {steps >= 3 && (
                  <div
                    className="  px-2 py-3 border-y "
                    onClick={() => handleClick(3)}>
                    <div className="flex items-center gap-2 capitalize mb-4 mt-1 text-lg">
                      {/*ICON*/}
                      <div className="p-2 ">
                        <FaBusinessTime className="text-4xl" />
                      </div>
                      <h1>Booking Slot</h1>
                    </div>
                    {steps === 3 && (
                      <div className="w-11/12 m-auto">
                        <button className="bg-primary-600 hover:bg-[#ff169a] capitalize text-md w-full p-2 rounded-md  font-bold text-white">
                          Select Slot
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {/* Payment */}
                {steps >= 4 && (
                  <div className="  px-2 py-3 border-y " onClick={handleClick}>
                    <div className="flex items-center gap-2 capitalize mb-2 mt-1 text-lg">
                      {/*ICON*/}
                      <div className="p-2 ">
                        <FaRupeeSign className="text-4xl" />
                      </div>
                      <h1>Payment</h1>
                    </div>

                    <div className="w-11/12 m-auto">
                      <div className="flex gap-1 items-center p-1 ">
                        <div
                          className={
                            "flex items-center gap-2 border-2 rounded-md px-2 py-1 cursor-pointer w-1/4 justify-center " +
                            (paymentMethod == "cash"
                              ? "  border-green-400 bg-[#9fff5143]"
                              : " border-gray-400")
                          }
                          onClick={() => handlePaymentMethodChange("cash")}>
                          {paymentMethod === "cash" && (
                            <TiTick className="text-green-400 text-xl" />
                          )}
                          <span>Cash</span>
                        </div>
                        <div
                          className={
                            "flex items-center gap-2 border-2 rounded-md px-2 py-1 cursor-pointer w-1/4 justify-center " +
                            (paymentMethod == "online"
                              ? "  border-green-400 bg-[#9fff5143]"
                              : " border-gray-400")
                          }
                          onClick={() => handlePaymentMethodChange("online")}>
                          {paymentMethod === "online" && (
                            <TiTick className="text-green-400 text-xl" />
                          )}
                          <span>Online</span>
                        </div>
                      </div>

                      <button
                        className="bg-primary-600 hover:bg-[#ff169a] text-md w-full p-2 capitalize rounded-md  font-bold text-white"
                        onClick={() => onSubmit()}
                        disabled={isLoadingBooking}>
                        {!paymentMethod ? (
                          <div>Select Payment Method</div>
                        ) : paymentMethod === "cash" ? (
                          <div>Confirm</div>
                        ) : (
                          <div>Make Payment</div>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
