import React, { useEffect } from "react";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="text-sm text-gray-600 mb-4">Last Updated: 15th Aug 2024</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
        <p>
          Welcome to Emplux.com! These Terms and Conditions outline the rules
          and regulations for the use of our platform. By accessing this
          website, we assume you accept these terms and conditions. Do not
          continue to use Emplux.com if you do not agree to all of the terms and
          conditions stated on this page.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Services Offered</h2>
        <p>
          Emplux.com provides a range of services including but not limited to:
        </p>
        <ul className="list-disc pl-5 text-white flex flex-col">
          <li>Electrical and electronic repair services.</li>
          <li>Rental of electrical and electronic devices.</li>
          <li>Sales of electrical and electronic products.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">User Responsibilities</h2>
        <p>
          Users are responsible for providing accurate information and ensuring
          that any device or product provided for repair, rental, or sale is
          legal and in accordance with our guidelines. Any misuse of the
          platform will result in termination of services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Payments</h2>
        <p>
          All payments must be made in full before the delivery of services or
          products. We accept various payment methods as listed on our website.
          Users are responsible for ensuring that payment information is
          accurate.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Liability</h2>
        <p>
          Emplux.com will not be held liable for any damages resulting from the
          use or inability to use the services provided. Our liability is
          limited to the amount paid for the services or products.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. Users will be
          notified of any significant changes through the website or via email.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
        <p>
          If you have any questions about these Terms and Conditions, please
          contact us:
        </p>
        <p>Phone: +91 91133 88758</p>
        <p>Email: help.emplux@gmail.com</p>
        <p>
          Address: #Eco Mitra Road, Near Pinnacle Apartment, Virgo Nagar Post,
          Hirandhalli, Bangalore-49, India
        </p>
      </section>
    </div>
  );
};

export default TermsConditions;
