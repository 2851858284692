// Save user to session storage
export const addUserToSessionStorage = (user) => {
  sessionStorage.setItem("user", JSON.stringify(user));
};

// Remove user from session storage
export const removeUserFromSessionStorage = () => {
  sessionStorage.removeItem("user");
};

// Remove entire store (root) from session storage
export const removeStoreFromSessionStorage = () => {
  sessionStorage.removeItem("root");
};

// Get user from session storage
export const getUserFromSessionStorage = () => {
  const result = sessionStorage.getItem("user");
  const user = result ? JSON.parse(result) : null;
  return user;
};
