import React, { useEffect, useState } from "react";
import { home_banner_6 } from "../../utils/Urls";
import { motion } from "framer-motion";

const HeroSection = () => {
  //Typing effect ##############################################
  const [firstWord, setFirstWord] = useState("");
  const [remainingWords, setRemainingWords] = useState("");
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);
  const words = [
    "electronic ",
    "laptop ",
    "washing machine ",
    "camera ",
    "PC ",
  ];
  // Typing Effect
  useEffect(() => {
    const interval = setInterval(() => {
      setText((prev) => {
        if (prev.length === words[index].length) {
          clearInterval(interval);
          setTimeout(() => {
            setIndex((prev) => (prev === words.length - 1 ? 0 : prev + 1));
            setText("");
          }, 1000);
        }
        return words[index].slice(0, prev.length + 1);
      });
    }, 150);

    return () => clearInterval(interval);
  }, [index]);
  useEffect(() => {
    const [fW, ...rW] = text.split(" ");
    setFirstWord(fW);
    setRemainingWords(rW?.join(" "));
  }, [text]);
  return (
    <div className="w-full relative h-[21rem] home-banner rounded-b-[1.5rem] flex justify-between px-[5rem] ">
      {/* <img
            src={home_banner_5}
            alt="Clap Urban"
            // id="home_img"
            className="w-full h-full object-cover  rounded-b-[2rem] "
          /> */}
      <div className="text-4xl font-bold text-center text-primary-600   capitalize w-[28rem] my-auto flex flex-col items-center">
        <div className="averia">
          {firstWord}
          {remainingWords && <br />}
          {remainingWords}
          <br /> {" Repair Services"}
          {/* <span className="blinking">|</span> */}
        </div>
        <div className="text-4xl font-extrabold text-primary-max mt-4 relative w-max uppercase">
          <motion.img
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            src="https://clapurban.blr1.digitaloceanspaces.com/1712483439957_paperPlane.svg"
            alt=""
            className="absolute right-[105%] "
          />
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}>
            Bengaluru
          </motion.div>
        </div>
      </div>
      <img
        src={home_banner_6}
        frameborder="0"
        className="h-[18rem] max-[900px]:h-[15rem] mt-auto"
      />

      {/* <div className="absolute bg-gradient-to-b from-[#7c7c7c22] to-[#7b7777c8] h-full w-full top-0 "></div> */}
    </div>
  );
};

export default HeroSection;
