import React, { useEffect, useState } from "react";
import Mobile from "../../components/BookingDetails/Mobile";
import Desktop from "../../components/BookingDetails/Desktop";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBookingDetails } from "../../features/BookingDetails/BookingDetailSlice";
import {
  cancelBooking,
  clearStatus,
  getInvoice,
} from "../../features/Bookings/BookingSlice";
import Confirm from "../../components/Notifications/Confirm";

const BookingDetails = () => {
  const [ratings, setRating] = useState(2);
  const [isCancelling, setCancelling] = useState(false);
  const { bookingId } = useParams();
  const dispatch = useDispatch();
  // const { BookingDetails } = useSelector((store) => store.bookingdetail);

  const { bookings } = useSelector((store) => store?.booking);
  const BookingDetails = bookings?.find(
    (elem) => elem?.confirmBooking?.slice(0, 1)[0]?.id?.toString() === bookingId
  );
  console.log(BookingDetails);
  const booking = BookingDetails?.confirmBooking?.slice(0, 1)[0];

  var slotDate;
  if (booking) {
    slotDate = new Date(booking?.bookingSlot);
  }
  const options = { hour: "2-digit", minute: "2-digit" };
  const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
    slotDate
  );

  const dateDifference = () => {
    const today = new Date();
    const tommorow = new Date();
    const yesterday = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    yesterday.setDate(tommorow.getDate() - 1);
    if (
      today.getDate() === slotDate?.getDate() &&
      today.getMonth() === slotDate?.getMonth() &&
      today.getFullYear() === slotDate?.getFullYear()
    ) {
      return 0;
    } else if (
      tommorow.getDate() === slotDate?.getDate() &&
      tommorow.getMonth() === slotDate?.getMonth() &&
      tommorow.getFullYear() === slotDate?.getFullYear()
    ) {
      return 1;
    } else if (
      yesterday.getDate() === slotDate?.getDate() &&
      yesterday.getMonth() === slotDate?.getMonth() &&
      yesterday.getFullYear() === slotDate?.getFullYear()
    ) {
      return -1;
    } else {
      return 999;
    }
  };
  const bookingSlot =
    dateDifference() === 0
      ? "Today, " + formattedTime
      : dateDifference() === 1
      ? "Tommorow, " + formattedTime
      : dateDifference() === -1
      ? "Yesterday, " + formattedTime
      : slotDate?.toDateString() + " " + formattedTime;

  const getStar = (getValue) => {
    setRating(getValue);
  };

  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const handelWindow = () => {
    setDesktop(window.innerWidth > 720);
  };

  // generate invoice
  const generateInvoice = async () => {
    await dispatch(
      getInvoice({ bookingId: bookingId, orderId: booking?.orderId })
    );
  };

  // Cancel Booking
  const handleCancelBooking = (flag) => {
    if (flag) {
      dispatch(
        cancelBooking({
          bookingId: bookingId,
          couponId: BookingDetails?.couponId,
        })
      );
    }
    setCancelling(false);
  };
  useEffect(() => {
    window.addEventListener("resize", handelWindow);
    return () => window.removeEventListener("resize", handelWindow);
  });

  useEffect(() => {
    console.log("Main chala");
    dispatch(fetchBookingDetails());
    dispatch(clearStatus());
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {isCancelling && (
        <Confirm
          question="Are you sure you want to cancel?"
          handleConfirmation={handleCancelBooking}
          sence={true}
          // description="A <strong>10% cancelation</strong> charge will be asked if you cancel the booking within <strong>1 hour."
        />
      )}
      {isDesktop ? (
        <Desktop
          ratings={ratings}
          getStar={getStar}
          bookingId={bookingId}
          booking={booking}
          bookingSlot={bookingSlot}
          BookingDetails={BookingDetails}
          generateInvoice={generateInvoice}
          setCancelling={() => setCancelling(true)}
        />
      ) : (
        <Mobile
          ratings={ratings}
          getStar={getStar}
          setCancelling={() => setCancelling(true)}
          bookingId={bookingId}
          booking={booking}
          bookingSlot={bookingSlot}
          BookingDetails={BookingDetails}
        />
      )}
    </div>
  );
};

export default BookingDetails;
