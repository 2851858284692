import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { logoutUser } from "../user/UserSlice";

const initialState = {
  isLoadingCart: false,
  totalAmount: 0,
  cartItems: [],
};

export const getCart = createAsyncThunk(
  "catagry/getCart",
  async (_, thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.get(`api/addToCard/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      thunkAPI.dispatch(
        setTotalAmount(resp?.data?.content?.slice(0, 1)[0]?.addToCard)
      );
      return resp?.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const postCart = createAsyncThunk(
  "cartItems/postCart",
  async (data, thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.post(`api/addToCard/${userId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const deleteCart = createAsyncThunk(
  "cartItems/deleteCart",
  async (cartId, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.delete(`api/addToCard/${cartId}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      thunkAPI.dispatch(getCart());
      return resp?.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const clearCart = createAsyncThunk(
  "cartItems/clearcart",
  async (cartIds, thunkAPI) => {
    console.log(cartIds);
    const { accessToken } = getUserFromLocalStorage();
    try {
      let resp = [];
      for (var i = 0; i < cartIds.length; i++) {
        resp?.push(
          await customFetch.delete(`api/addToCard/${cartIds[i]}`, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          })
        );
        console.log(resp);
      }
      thunkAPI.dispatch(getCart());
      return resp;
    } catch (error) {
      if (error.response.status === 401) {
        // thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setLoadingCatg: (state, { payload }) => {
      state.isLoadingCart = payload;
    },
    clearLocalCart: (state) => {
      state.cartItems = [];
      state.totalAmount = 0;
    },
    setTotalAmount: (state, { payload }) => {
      let amountsArray = payload
        ? payload?.map((elem) => elem.issuePrice)
        : state.cartItems?.map((elem) => elem.issuePrice);
      let Amounts = amountsArray?.map(Number);
      state.totalAmount = Amounts?.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCart.fulfilled, (state, { payload }) => {
        state.cartItems.push(payload);
        state.totalAmount = state.totalAmount + payload?.issuePrice;
        state.isLoadingCart = false;
      })
      .addCase(postCart.pending, (state) => {
        state.isLoadingCart = true;
      })
      .addCase(postCart.rejected, (state) => {
        state.isLoadingCart = false;
      })
      .addCase(getCart.fulfilled, (state, { payload }) => {
        state.cartItems = payload.content[0].addToCard;
        // alert(payload.message);
        state.isLoadingCart = false;
      })
      .addCase(getCart.pending, (state) => {
        state.isLoadingCart = true;
      })
      .addCase(getCart.rejected, (state) => {
        state.isLoadingCart = false;
      })
      .addCase(deleteCart.fulfilled, (state, { payload }) => {
        state.isLoadingCart = false;
      })
      .addCase(deleteCart.pending, (state) => {
        state.isLoadingCart = true;
      })
      .addCase(deleteCart.rejected, (state) => {
        state.isLoadingCart = false;
      })
      .addCase(clearCart.fulfilled, (state, { payload }) => {
        state.isLoadingCart = false;
        state.cartItems = [];
      })
      .addCase(clearCart.pending, (state) => {
        state.isLoadingCart = true;
      })
      .addCase(clearCart.rejected, (state) => {
        state.isLoadingCart = false;
      });
  },
});

export default CartSlice.reducer;
export const { setLoadingCatg, setTotalAmount, clearLocalCart } =
  CartSlice.actions;
