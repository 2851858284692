import React from "react";
import Navigation from "../../components/Navigation";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";
import { Toaster } from "sonner";

const SharedLayout = () => {
  const { isLoadingIssue } = useSelector((store) => store.issue);
  const { isLoadingHome } = useSelector((store) => store.home);

  const { isLoadingAddress } = useSelector((store) => store.address);
  const { isLoadingBooking } = useSelector((store) => store.booking);

  return (
    <div>
      {/* pop-ups */}
      {(isLoadingIssue ||
        isLoadingBooking ||
        isLoadingAddress ||
        isLoadingHome) && <PageLoader />}
      <Toaster position="top-center" />
      <div>
        <Navigation />
        <div>
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SharedLayout;
