import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  VerifyLoginOtp,
  VerifyOtp,
  generateOtp,
  removeStatus,
} from "../features/user/UserSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { empluxIcon, signin_banner } from "../utils/Urls";
import talency_logo from "../Images/talency_logo.png";
import { IoIosRefresh } from "react-icons/io";
import { Toaster, toast } from "sonner";
import { Decode, findEmptyValueKey } from "../utils/GlobalFunctions";

const initialState = {
  otp1: "",
  otp2: "",
  otp3: "",
  otp4: "",
  otp5: "",
  otp6: "",
};

const GetLoginOtp = () => {
  const [values, setValues] = useState(initialState);
  const [countdown, setCountdown] = useState(120);
  const { status, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { phoneNoEnc } = useParams();
  const phoneNo = Decode(phoneNoEnc);
  const rememberMe = location?.state?.rememberMe;

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/[^0-9]/g, "");
    const array = ["otp1", "otp2", "otp3", "otp4", "otp5", "otp6"];
    let target;
    // ######################## paste to filll#######################
    if (name === "otp1") {
      let valueTemp = value;
      console.log(valueTemp);
      for (let index = 0; index < value?.length; index++) {
        let element = null;
        if (index < array?.length) {
          element = array[index];
        } else {
          break;
        }

        valueTemp = { ...valueTemp, [element]: value[index] };
      }
      const emptyKey = array[value?.length];

      if (emptyKey) {
        target = document.getElementById(emptyKey);
      } else {
        if (value?.length >= 6) {
          target = document.getElementById("submit");
        } else {
          target = document.getElementById("otp1");
        }
      }

      if (value?.length <= 0) {
        setValues({ ...values, [name]: value });
      } else setValues({ ...values, ...valueTemp });
      // ######################## paste to filll (end)#######################
    } else {
      setValues({ ...values, [name]: value });
      const nextKeyIndex = array?.indexOf(name);
      const nextKey = array[nextKeyIndex + 1];
      if (nextKey) {
        target = document.getElementById(nextKey);
      } else {
        target = document.getElementById("submit");
      }
    }

    if (target) {
      target.focus();
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const { otp1, otp2, otp3, otp4, otp5, otp6 } = values;

    if (!(otp1 && otp2 && otp3 && otp4 && otp5 && otp6)) {
      toast("Please fill 6 digit OTP");
      return;
    }
    const otp =
      otp1 + "" + otp2 + "" + otp3 + "" + otp4 + "" + otp5 + "" + otp6;
    if (location?.pathname?.indexOf("login") >= 0) {
      const resp = await dispatch(
        VerifyLoginOtp({ phoneNo: phoneNo, otp: otp, rememberMe: rememberMe })
      );

      if (resp?.type?.indexOf("fulfilled") > -1) {
        navigate("/");
      }
    } else await dispatch(VerifyOtp({ otp: otp }));
  };

  const handleRememberMe = (e) => {
    const isChecked = e?.target?.checked;
    setValues({ ...values, rememberMe: isChecked });
  };

  const inputfocus = (elmnt) => {
    if (
      elmnt.key === "Delete" ||
      elmnt.key === "Backspace" ||
      elmnt.key === "ArrowLeft"
    ) {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else if (elmnt.key === "ArrowRight") {
      const next = elmnt.target.tabIndex;
      if (next < 7) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const handleFocus = (e) => {
    const inputElement = document.getElementById(e.target.id);
    if (inputElement) {
      inputElement.select();
    }
  };
  //   Resend password
  const resendPassword = () => {
    if (countdown <= 0) {
      setCountdown(120);
      dispatch(generateOtp({ phoneNo: phoneNo }));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((countdown) => countdown - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (status != 201 && location?.pathname?.indexOf("login") < 0) {
      navigate("/register");
    }
  }, []);

  useEffect(() => {
    if (status == 200) {
      navigate("/login");
    }
  }, [status]);
  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <Toaster position="top-center" reverseOrder={false} richColors />
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
      </div>

      <div className="w-full lg:w-1/2">
        <div className="mt-10">
          <img src={empluxIcon} alt="Logo" className="mx-auto w-28" />
        </div>
        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className=" form-section ">
            <form onSubmit={onSubmit}>
              <div className="flex items-center gap-4 mb-2 mt-5 w-5/6 m-auto justify-center  ">
                {/* Email field */}
                {/* <input
                  type="email"
                  name="digit1"
                  placeholder="Email Address"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                /> */}
                <input
                  type="text"
                  id="otp1"
                  onFocus={handleFocus}
                  className="max-sm:w-12 max-xxs:w-8 max-xs:w-9 w-14  aspect-[7/8]  rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  name="otp1"
                  value={values?.otp1}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  style={{
                    "-webkit-appearance": "none",
                    "-moz-appearance": "textfield",
                  }}
                  tabIndex="1"
                />

                <input
                  type="text"
                  id="otp2"
                  onFocus={handleFocus}
                  className="max-sm:w-12 max-xxs:w-8 max-xs:w-9 w-14  aspect-[7/8] rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  name="otp2"
                  maxLength={1}
                  value={values?.otp2}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  tabIndex="2"
                />

                <input
                  type="text"
                  id="otp3"
                  onFocus={handleFocus}
                  name="otp3"
                  className="max-sm:w-12 max-xxs:w-8 max-xs:w-9 w-14  aspect-[7/8] rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  maxLength={1}
                  value={values?.otp3}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  tabIndex="3"
                />

                <input
                  type="text"
                  id="otp4"
                  onFocus={handleFocus}
                  name="otp4"
                  className="max-sm:w-12 max-xxs:w-8 max-xs:w-9 w-14  aspect-[7/8] rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  maxLength={1}
                  value={values?.otp4}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  tabIndex="4"
                />
                <input
                  type="text"
                  id="otp5"
                  onFocus={handleFocus}
                  name="otp5"
                  className="max-sm:w-12 max-xxs:w-8 max-xs:w-9 w-14  aspect-[7/8] rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  maxLength={1}
                  value={values?.otp5}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  tabIndex="5"
                />
                <input
                  type="text"
                  id="otp6"
                  onFocus={handleFocus}
                  name="otp6"
                  className="max-sm:w-12 max-xxs:w-8 max-xs:w-9 w-14  aspect-[7/8] rounded-lg  text-black border border-gray-300 text-3xl font-extrabold text-center"
                  placeholder="_"
                  maxLength={1}
                  value={values?.otp6}
                  onChange={handleChange}
                  onKeyUp={inputfocus}
                  tabIndex="6"
                />
              </div>

              <div className="mt-8 w-full flex justify-center">
                <button
                  disabled={isLoading}
                  id="submit"
                  className="w-5/6  py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600">
                  {isLoading ? "Loading..." : "Verify OTP "}
                </button>
              </div>

              <p
                className="mt-8 text-xs font-light text-center text-gray-700 cursor-pointer"
                onClick={resendPassword}>
                Resend Password
                <button
                  type="button"
                  className="font-medium text-neutral-800 outline-white hover:underline ml-2">
                  <strong>
                    {countdown > 0 ? countdown : <IoIosRefresh />}
                  </strong>
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetLoginOtp;
