import React from "react";
import { toast } from "sonner";

const Desktop = ({ handleLogOut, handleDelete }) => {
  return (
    <div className="min-h-screen  pt-10">
      <div className="flex flex-col w-[85%] m-auto gap-12 ">
        <div className="text-xl font-bold text-primary-max w-1/3 min-w-max pb-2 border-b-2 border-primary-600">
          Settings
        </div>
        <div className="w-full flex flex-col gap-8 ">
          <div
            className="w-full px-5 py-2 bg-gray-50 border border-primary-70 flex justify-between items-center text-primary-600 font-thin text-lg top-shadow cursor-pointer"
            onClick={handleLogOut}>
            <span className="capitalize"> Log Out</span>
          </div>
          <div
            className="w-full px-5 py-2 bg-gray-50 border border-primary-70 flex justify-between items-center text-primary-600 font-thin text-lg top-shadow cursor-pointer"
            onClick={() => toast.warning("Error! Please try after sometime.")}>
            <span className="capitalize"> Delete Account</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
