import React, { useState } from "react";
import "../../style/loader.css";
import { Link } from "react-router-dom";
import SelectAddress from "../SelectAddress";
import { useSelector } from "react-redux";
import ObjectLoader from "../ObjectLoader";
import { motion } from "framer-motion";
import { home_banner_4, mobile_home_banner_4 } from "../../utils/Urls";
import SubCategoryPopupMobile from "./SubCategoryPopupMobile";
import TestimonialsCarousel from "./TestimonialsCarousel";

const Mobile = ({
  data,
  category,
  SubCategories,
  handleCategoryIdChange,
  CategoryId,
  home,
}) => {
  const { isLoadingSubCatg } = useSelector((store) => store.subcategory);
  const { isLoadingHome } = useSelector((store) => store.home);
  const [popup, setPopup] = useState(false);
  const [isAddressSelecting, setAddSelecting] = useState(false);

  const handleAddressSelecting = () => {
    setAddSelecting(!isAddressSelecting);
  };

  const handleCategoryClick = (id) => {
    console.log(id);
    handleCategoryIdChange(id);
    setPopup(true);
  };

  return (
    <div className=" w-full overflow-hidden px-3 home-mobile  ">
      {/* Popups */}
      {/* sub catagory popup */}

      {popup && (
        <SubCategoryPopupMobile
          isLoadingSubCatg={isLoadingSubCatg}
          handleSubcategory={(flag) => setPopup(flag)}
          category={category}
          CategoryId={CategoryId}
          SubCategories={SubCategories}
        />
      )}

      {/* Select assress Pop-Up */}
      {isAddressSelecting && (
        <SelectAddress handleClick={handleAddressSelecting} />
      )}

      {/* Background image */}
      <div className="w-full relative h-[14.68rem] overflow-hidden   ">
        <img
          src={mobile_home_banner_4}
          alt="Clap Urban"
          // id="home_img"
          className="w-full h-full object-cover -z-10"
        />
        {/* <div className="absolute bg-gradient-to-b from-[#7c7c7c22] to-[#7b7777c8] h-full w-full top-0 "></div> */}
      </div>

      {/* Categories */}
      <div className="px-7 py-4 top-shadow bg-white rounded">
        <h1 className="font-medium">Your doorstep, Our service....</h1>
        <div className="w-full grid grid-cols-3 text-sm" id="category">
          {category?.map((elem, index) => {
            const { id } = elem;
            if (elem?.showCategory) {
              return (
                <div
                  key={index}
                  className="h-[7.5rem] flex flex-col items-center justify-center gap-3"
                  onClick={() => handleCategoryClick({ id })}>
                  <img
                    src={elem?.appCategoryUrl}
                    alt=""
                    className="h-[3.75rem] w-[3.75rem]"
                  />
                  <span>{elem?.categoryName}</span>
                </div>
              );
            }
          })}
        </div>
      </div>

      {/* mian thumbnail */}
      {/* Home data */}

      {!isLoadingHome ? (
        home?.map((element, index) => {
          if (element?.completed) {
            if (
              !element?.bannerImgUrl &&
              element?.homeSubSections?.length > 0
            ) {
              return (
                <div
                  className="h-max mt-8 max-[390px]:mt-4 pb-10 max-[390px]:pb-5 flex flex-col items-center expand outfit gap-10"
                  key={index}>
                  <h1 className="text-black  font-normal text-3xl  capitalize  max-[520px]:text-2xl max-[390px]:text-xl">
                    {element?.title}
                  </h1>
                  <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    className={
                      element?.orientation === "tall"
                        ? "grid grid-cols-2 gap-3 "
                        : "flex  w-full  gap-3 overflow-x-auto no-scrollbar "
                    }>
                    {element?.homeSubSections
                      ?.slice(0, 4)
                      ?.map((elem, indx) => {
                        return (
                          <Link
                            to={`/service/${elem.subCategoryId}`}
                            key={indx}
                            className="w-full text-center">
                            <img
                              src={elem?.appThumbnailUrl}
                              alt={elem?.title}
                              className={
                                " object-cover m-auto rounded " +
                                (element?.orientation === "tall"
                                  ? " w-full"
                                  : "min-w-[16rem] drop-shadow")
                              }
                            />
                          </Link>
                        );
                      })}
                  </motion.div>
                  {/* <button className="bg-[#FF57B67D] rounded-3xl py-2 px-[4vw]">
                    View More
                  </button> */}
                </div>
              );
            } else if (element?.bannerImgUrl) {
              return (
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  className="h-full pb-5   mt-10 max-[390px]:mt-6 w-full justify-between items-center"
                  key={index}>
                  <a href="">
                    <img
                      src={element?.appBannerUrl}
                      alt=""
                      className=" w-full object-cover"
                    />
                  </a>
                </motion.div>
              );
            }
          }
        })
      ) : (
        <div className="w-full h-96">
          <ObjectLoader />
        </div>
      )}
      {/* testimonials */}
      <TestimonialsCarousel />

      {/* Featured service */}
    </div>
  );
};

export default Mobile;
