import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import rentalhome from "../../Images/rental_home.svg";
import rentalhome1 from "../../Images/rental_home1.svg";
import rentalhome2 from "../../Images/rental_home2.svg";
import rentalhome3 from "../../Images/Young man standing and looking away.svg";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineLaptop } from "react-icons/md";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const RentalHome = () => {
  const { user } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const handleExploreMorePageNavigate = (path) => {
    if (user) {
      navigate(`${path}`);
    } else {
      navigate(`/nouser${path}`);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div className="flex items-center justify-center mt-[3rem]">
        <img src={rentalhome} alt="" className="h-[23rem]" />
      </div>
      <div className="flex flex-col items-center justify-center  mt-[4rem] gap-[3rem] outfit">
        <div className="text-[26px]">Rent electronic devices</div>
        {/* catagories */}
        <div className="flex items-center justify-center gap-10">
          <IoIosArrowForward className=" rotate-180 text-[#676565]" />
          <div
            className="flex flex-col justify-center items-center h-[13.125rem] cursor-pointer border w-[13.125rem] rounded-2xl border-[#7b7a7a] text-[#676565] "
            onClick={() =>
              handleExploreMorePageNavigate("/rental/explore-more")
            }>
            <MdOutlineLaptop className="text-[5rem] " />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div
            className="flex flex-col justify-center items-center h-[13.125rem] border cursor-pointer w-[13.125rem] rounded-2xl border-[#7b7a7a] text-[#676565]"
            onClick={() =>
              handleExploreMorePageNavigate("/rental/explore-more")
            }>
            <MdOutlineLaptop className="text-[5rem] " />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div
            className="flex flex-col justify-center items-center h-[13.125rem] border cursor-pointer w-[13.125rem] rounded-2xl border-[#7b7a7a] text-[#676565]"
            onClick={() =>
              handleExploreMorePageNavigate("/rental/explore-more")
            }>
            <MdOutlineLaptop className="text-[5rem] " />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div
            className="flex flex-col justify-center items-center h-[13.125rem] border cursor-pointer w-[13.125rem] rounded-2xl border-[#7b7a7a] text-[#676565]"
            onClick={() =>
              handleExploreMorePageNavigate("/rental/explore-more")
            }>
            <MdOutlineLaptop className="text-[5rem] " />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div
            className="flex flex-col justify-center items-center h-[13.125rem] border cursor-pointer w-[13.125rem] rounded-2xl border-[#7b7a7a] text-[#676565]"
            onClick={() =>
              handleExploreMorePageNavigate("/rental/explore-more")
            }>
            <MdOutlineLaptop className="text-[5rem] " />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>

          <IoIosArrowForward className="text-[#676565]" />
        </div>
        <div className="text-sm bg-[#FF57B67D] px-10 py-2  rounded-2xl cursor-pointer">
          Explore More
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex mt-[4.68rem] w-full outfit">
          <div className="flex justify-end pl-[9rem]">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center gap-4 w-1/4  ">
            <div className="text-xl">Laptop</div>
            <div className="text-[#FF179A]">₹400/mo</div>
            <div className="border py-2 px-7 border-primary-600 rounded-3xl">
              36% off
            </div>
          </div>
          <div className="flex flex-col justify-center items-end pr-[9rem] w-1/2 gap-4">
            <div className="flex items-center justify-center w-[16rem] bg-primary-600 text-white py-[0.7rem] rounded-2xl">
              Add to cart
            </div>
            <div
              className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer"
              onClick={() =>
                handleExploreMorePageNavigate("/rental/explore-more")
              }>
              Explore more
            </div>
          </div>
        </div>

        {/* repeated divs from here  */}
        <div className="flex mt-[4.68rem] w-full outfit">
          <div className="flex justify-end pl-[9rem]">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center gap-4 w-1/4  ">
            <div className="text-xl">Laptop</div>
            <div className="text-[#FF179A]">₹400/mo</div>
            <div className="border py-2 px-7 border-primary-600 rounded-3xl">
              36% off
            </div>
          </div>
          <div className="flex flex-col justify-center items-end pr-[9rem] w-1/2 gap-4">
            <div className="flex items-center justify-center w-[16rem] bg-primary-600 text-white py-[0.7rem] rounded-2xl">
              Add to cart
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl">
              Explore more
            </div>
          </div>
        </div>
        <div className="flex mt-[4.68rem] w-full outfit">
          <div className="flex justify-end pl-[9rem]">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center gap-4 w-1/4  ">
            <div className="text-xl">Laptop</div>
            <div className="text-[#FF179A]">₹400/mo</div>
            <div className="border py-2 px-7 border-primary-600 rounded-3xl">
              36% off
            </div>
          </div>
          <div className="flex flex-col justify-center items-end pr-[9rem] w-1/2 gap-4">
            <div className="flex items-center justify-center w-[16rem] bg-primary-600 text-white py-[0.7rem] rounded-2xl">
              Add to cart
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl">
              Explore more
            </div>
          </div>
        </div>
        <div className="text-sm bg-[#FF57B67D] px-10 py-2  rounded-2xl mt-[3rem]">
          View More
        </div>
      </div>
      <div className="flex flex-col items-center justify-center  mt-[4rem] gap-[3rem] outfit">
        <div className="text-[26px] ">New launches</div>
        <div className="flex items-center justify-center gap-10">
          <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome2} alt="" />

            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome2} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome2} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome2} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center  my-[6rem]  gap-[3rem] outfit">
        <div className="text-[26px] ">Testimonial from our customer</div>
        <div className="grid grid-cols-2 items-center justify-center gap-x-20 gap-y-10">
          <div className="flex justify-center items-center h-[9.75rem] border w-[33rem] rounded-2xl border-[#7b7a7a] gap-4 px-4">
            <img src={rentalhome3} alt="" />

            <div className="flex flex-col gap-2">
              <div className="text-sm">
                This platform has been a game-changer for me! I would just
                recommmend it to everyone !!
              </div>
              <div className="flex text-sm text-primary-600">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <div className="text-sm font-semibold">- Sparsh</div>
            </div>
          </div>
          <div className="flex justify-center items-center h-[9.75rem] border w-[33rem] rounded-2xl border-[#7b7a7a] gap-4 px-4">
            <img src={rentalhome3} alt="" />

            <div className="flex flex-col gap-2">
              <div className="text-sm">
                This platform has been a game-changer for me! I would just
                recommmend it to everyone !!
              </div>
              <div className="flex text-sm text-primary-600">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <div className="text-sm font-semibold">- Sparsh</div>
            </div>
          </div>
          <div className="flex justify-center items-center h-[9.75rem] border w-[33rem] rounded-2xl border-[#7b7a7a] gap-4 px-4">
            <img src={rentalhome3} alt="" />

            <div className="flex flex-col gap-2">
              <div className="text-sm">
                This platform has been a game-changer for me! I would just
                recommmend it to everyone !!
              </div>
              <div className="flex text-sm text-primary-600">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <div className="text-sm font-semibold">- Sparsh</div>
            </div>
          </div>
          <div className="flex justify-center items-center h-[9.75rem] border w-[33rem] rounded-2xl border-[#7b7a7a] gap-4 px-4">
            <img src={rentalhome3} alt="" />

            <div className="flex flex-col gap-2">
              <div className="text-sm">
                This platform has been a game-changer for me! I would just
                recommmend it to everyone !!
              </div>
              <div className="flex text-sm text-primary-600">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <div className="text-sm font-semibold">- Sparsh</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalHome;
