import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAddress,
  fetchAddresses,
  getAllCity,
  markAsDefault,
  updateAddress,
} from "../features/address/addressSlice";
import { toast } from "sonner";
import { editProfile } from "../features/Profile/ProfileSlice";

const ManageAddress = ({ handleEditing, purpose, EditAddress }) => {
  const dispatch = useDispatch();
  const initialState = {
    address: "",
    landmark: "",
    locality: "",
    city: "",
    state: "",
    pinCode: null,
    createdDateTime: "2023-09-09T06:28:28.313Z",
    updatedDataTime: "2023-09-09T06:28:28.313Z",
    addressType: null,
    fullName: "",
  };

  const [isLoading, setLoading] = useState(false);
  const [isChanged, setchanged] = useState(false);
  const [isOther, setOtherchecked] = useState(false);
  const [address, setAddress] = useState(
    EditAddress ? EditAddress : initialState
  );

  const { allCity, addresses } = useSelector((store) => store?.address);

  const handleCancel = () => {
    // if (
    //   JSON.stringify(address) === JSON.stringify(initialState) ||
    //   JSON.stringify(address) === JSON.stringify(EditAddress)
    // ) {
    //   setchanged(false);
    // }
    if (isChanged) {
      toast.error("Your Changes Will Be Discarded!", {
        action: {
          label: "OK",
          onClick: () => handleEditing(),
        },
      });
    } else {
      handleEditing();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddress({ ...address, [name]: value });
    setchanged(true);
  };

  const handleSubmit = async () => {
    if (
      !address.pinCode ||
      !address.address ||
      !address.state ||
      !address.locality ||
      !address.city ||
      !address.addressType ||
      !address.fullName
    ) {
      toast.warning("Please fill all the details.");
      return;
    }

    if (
      !EditAddress?.addressType &&
      addresses?.find((item) => item?.addressType == address.addressType)
    ) {
      toast.warning("Address type already exists");
      return;
    }
    setLoading(true);
    handleEditing();
    {
      EditAddress
        ? await dispatch(
            updateAddress({ id: EditAddress.id, address: address })
          )
        : await dispatch(AddAddress(address));
    }

    dispatch(fetchAddresses());

    setLoading(false);
  };

  const handleCityFocus = () => {
    if (allCity?.size > 0) {
      return;
    }
    dispatch(getAllCity());
  };
  // Address type
  const handleRadioChange = (e) => {
    if (EditAddress?.addressType) {
      return;
    }
    if (e.target.value !== "Other") {
      const { value } = e.target;
      setAddress({ ...address, addressType: value });
      setOtherchecked(false);
      setchanged(true);
    } else {
      setOtherchecked(true);
      setAddress({ ...address, addressType: "" });
      setchanged(true);
    }
  };

  const handleCustomAddressChange = (e) => {
    const { value } = e.target;
    setAddress({ ...address, addressType: value?.slice(0, 10)?.toLowerCase() });
  };
  //Mark default
  const handleDefault = async (e) => {
    const checked = e?.target?.checked;
    if (!checked) return;
    const checkedResp = await dispatch(markAsDefault(EditAddress?.id));
    if (checkedResp?.type?.indexOf("fulfilled") > -1) {
      dispatch(fetchAddresses());
      handleEditing();
    }
  };

  return (
    <div className="fixed w-full h-full min-[720px]:bg-[#00000057] z-50 flex items-center max-[720px]:items-end justify-center top-0 ">
      <div
        className="fixed left-0 top-0 h-full w-full bg-[#00000057] min-[720px]:hidden"
        onClick={handleCancel}></div>
      <div className="relative w-[600px] h-[530px] max-[720px]:w-full max-[720px]:h-[400px] bg-gray-200 max-[720px]:rounded-t-xl overflow-hidden popupSlideUp ">
        <div className="relative flex items-center w-full py-3 px-3  font-mono font-semibold uppercase bg-white text-md text-gray-600">
          <span>{purpose}</span>
          <span
            className="absolute right-2 p-2   hover:bg-gray-200 max-[720px]:hidden"
            onClick={handleCancel}>
            <AiOutlineClose className="text-xl " />
          </span>
        </div>

        {/* data fields  */}
        <div className="h-[79%] mt-3  w-full overflow-auto pb-6">
          <div className="flex flex-col h-max bg-white w-full  p-3 ">
            {/* pincode */}

            <div className="flex gap-4 w-11/12 m-4">
              <div className="relative col-span-1 h-10  w-full border border-gray-200  ">
                <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                  Pincode
                </span>
                <input
                  type="number"
                  max={10}
                  onChange={handleChange}
                  name="pinCode"
                  value={address.pinCode}
                  placeholder="Pincode"
                  className="h-full p-3 w-full"
                />
              </div>

              {/*  State */}
              <div className="relative col-span-1 h-10  w-full border border-gray-200  ">
                <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                  State
                </span>
                <input
                  type="text "
                  onChange={handleChange}
                  name="state"
                  value={address.state}
                  placeholder="State"
                  className="h-full p-3 w-full"
                />
              </div>
            </div>
            {/* Full name */}
            <div className="relative col-span-2 h-10 m-4 w-11/12 border border-gray-200 ">
              <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                Full Name
              </span>
              <input
                type="text"
                onChange={handleChange}
                name="fullName"
                value={address.fullName}
                placeholder="Full Name"
                className="h-full p-3 w-full"
              />
            </div>
            {/* Address */}
            <div className="relative col-span-2 h-10 m-4 w-11/12 border border-gray-200 ">
              <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                address
              </span>
              <input
                type="text"
                onChange={handleChange}
                name="address"
                value={address.address}
                placeholder="Address"
                className="h-full p-3 w-full"
              />
            </div>
            {/* Landmark */}
            <div className="relative col-span-2 h-10 m-4  w-11/12 border border-gray-200 ">
              <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                Landmark
              </span>
              <input
                type="text "
                onChange={handleChange}
                name="landmark"
                placeholder="Landmark"
                value={address.landmark}
                className="h-full p-3 w-full"
              />
            </div>

            {/* Locality */}
            <div className="relative col-span-2 h-10 m-4  w-11/12 border border-gray-200  ">
              <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                Locality/Area
              </span>
              <input
                type="text "
                onChange={handleChange}
                name="locality"
                placeholder="Locality/Area"
                value={address.locality}
                className="h-full p-3 w-full"
              />
            </div>

            {/* district */}
            <div className="relative col-span-2 h-10 m-4  w-11/12 border border-gray-200  ">
              <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                city
              </span>

              <select
                name="city"
                id=""
                value={address?.city}
                defaultValue={"default"}
                className="w-full h-full p-2 outline-none "
                onChange={handleChange}
                onFocus={handleCityFocus}>
                <option hidden value="default">
                  Select city
                </option>
                {allCity?.map((elem, index) => {
                  console.log(elem);
                  return (
                    <option value={elem?.cityName} key={index}>
                      {elem?.cityName}
                    </option>
                  );
                })}
              </select>
              {/* <input
                type="text "
                onChange={handleChange}
                name="city"
                placeholder="City/District"
                value={address.city}
                className="h-full p-3 w-full"
              /> */}
            </div>
          </div>
          {/* Address type */}
          <div className="w-full h-max bg-white mt-3 px-3 py-3 ">
            <span className="capitalize text-lg">Select address type</span>
            <div className="grid grid-cols-3 gap-8 items-center mt-2 mx-4 w-11/12">
              <span className="place-self-start flex gap-2 items-center">
                <input
                  type="radio"
                  name="type"
                  value="home"
                  checked={address?.addressType === "home"}
                  onChange={handleRadioChange}
                  className="w-4 h-4"
                />
                <label htmlFor="type">Home</label>
              </span>
              <span className="place-self-center flex gap-2 items-center">
                <input
                  type="radio"
                  name="type"
                  value="office"
                  checked={address?.addressType === "office"}
                  onChange={handleRadioChange}
                  className="w-4 h-4"
                />
                <label htmlFor="type">Office</label>
              </span>
              <span className="place-self-end flex gap-2 items-center">
                <input
                  type="radio"
                  name="type"
                  maxLength={10}
                  value="Other"
                  checked={
                    isOther ||
                    (EditAddress?.addressType?.length > 0 &&
                      EditAddress?.addressType !== "home" &&
                      EditAddress?.addressType !== "office")
                  }
                  onChange={handleRadioChange}
                  className="w-4 h-4"
                />
                <label htmlFor="type" className="capitalize">
                  {isOther ? (
                    <div className="px-1">
                      <input
                        type="text"
                        value={address?.addressType}
                        onChange={handleCustomAddressChange}
                        className="  border-gray-300  flex-1 outline-none border-b w-[6.25rem] h-5 text-sm"
                      />
                    </div>
                  ) : EditAddress?.addressType?.length > 0 ? (
                    EditAddress?.addressType === "home" ||
                    EditAddress?.addressType === "office" ? (
                      "Other"
                    ) : (
                      EditAddress?.addressType
                    )
                  ) : (
                    "Other"
                  )}
                </label>
              </span>
            </div>

            {EditAddress?.active === false && (
              <div className="flex mt-4 gap-2 items-center">
                <input
                  type="checkbox"
                  name="setdefault"
                  className="w-4 h-4"
                  onChange={handleDefault}
                />
                <label htmlFor="setdefault" className="capitalize">
                  make this address as default
                </label>
              </div>
            )}
          </div>
        </div>
        {/* final Submit  */}
        <div className=" bg-white absolute border-t w-full h-[10%] bottom-0 ">
          <button
            className="w-1/2 h-full border-r bg-primary-max text-white hover:bg-primary-600"
            onClick={handleSubmit}
            disabled={isLoading}>
            {isLoading ? "Wait..." : "Save"}
          </button>
          <button
            className="w-1/2 h-full bg-white border-l hover:bg-gray-200 "
            onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageAddress;
