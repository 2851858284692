import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { commaSvg } from "../../Images";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { defaultProfileMale } from "../../utils/Constants";

const TestimonialsCarousel = () => {
  const { testimonial } = useSelector((st) => st.home);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds
    swipe: true, // This is enabled by default
  };

  // Add more testimonial here

  return (
    <div className=" mx-auto my-[10rem] max-[520px]:my-10  ">
      <motion.h1
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="text-black   text-3xl  capitalize mb-12 font-medium w-full max-[520px]:mb-4 max-[520px]:text-2xl max-[390px]:text-xl max-md:text-center  ">
        Customer Testimonials
      </motion.h1>

      <div className="w-full  ">
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          className="relative rounded-t-3xl">
          <div className="h-[4rem] aspect-square bg-[#D9D9D9] rounded-full absolute right-[6%] -top-[2rem] z-10 max-md:h-[3.5rem] max-md:-top-[7%] max-md:right-[7.5%] max-xs:h-[2.5rem] max-xs:-top-[5%]"></div>
          <Slider {...settings}>
            {testimonial?.map((elem, index) => (
              <div
                className="flex flex-col w-full pt-[6.25rem] border border-[#83838320]  rounded-t-3xl pb-[4rem] shadow-2xl max-xl:pt-[3.25rem] max-lg:pt-[4.25rem]  min-h-[22rem] max-[520px]:min-h-max max-[520px]:pb-[3rem] cursor-pointer "
                key={index}>
                <div className="flex justify-center items-center w-[70%] mx-auto relative">
                  <img
                    alt=""
                    src={commaSvg}
                    className="absolute bottom-[105%] w-8 max-lg:w-5 right-[102%]"
                  />
                  <p className="text-lg font-medium w-full text-justify max-lg:text-lg max-[520px]:text-sm max-[390px]:text-xs">
                    {elem?.reviewText}
                  </p>
                </div>
                {/* user Image */}
                <div className="flex justify-center items-center gap-7 mt-8 ">
                  <img
                    className="w-[4rem] aspect-square rounded-full border-[0.5px] border-black max-[520px]w-[3.25rem] max-[390px]:w-[2.5rem] "
                    src={
                      elem?.image && elem?.image != "string"
                        ? elem?.image
                        : defaultProfileMale
                    }
                    alt={elem?.userName}
                  />
                  <div className="h-[4rem] flex flex-col justify-between pt-2 pb-2">
                    <p className=" text-[#021F29] font-semibold tracking-wider max-[520px]:text-sm ">
                      {elem?.userName}
                    </p>
                    <p className=" text-[#62777B] text-sm font-extralight ">
                      {elem?.bookingSubcategory + ", " + elem?.address}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          className="w-full h-[6.75rem] max-lg:h-[4rem] max-xl:h-[5rem] bg-primary-30 mt-[0.375rem] rounded-b-xl flex justify-between items-center px-10  text-primary-max  no-scrollbar text-3xl max-xl:text-2xl max-lg:text-xl max-[520px]:text-xs max-[520px]:px-2 ">
          <h1 className="averialoc uppercase max-xs:hidden">Kormangla</h1>
          <h1 className="scada">HSR Layout</h1>
          <h1 className="single">Marathalli</h1>
          <h1 className="averialoc uppercase">TC Palya</h1>
          <h1 className="secular">More</h1>
        </motion.div>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
