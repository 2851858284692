import React, { useEffect } from "react";
import "../../style/loader.css";
import { ImCross } from "react-icons/im";
import { RxCross2 } from "react-icons/rx";

const Success = ({ message, status, handleStatusNotification }) => {
  useEffect(() => {
    if (handleStatusNotification) {
      setTimeout(() => {
        handleStatusNotification(false);
      }, 2000);
    }
  });
  return (
    <div className=" fixed flex justify-center z-40 w-full ">
      <div className="bg-[#343333] text-white text-xs min-w-max  tracking-wider rounded-xl px-4 h-[4vh] flex items-center justify-center uppercase  font-semibold absolute top-4   overflow-hidden expand-horizontal">
        <h1 className="">{message}</h1>
      </div>
    </div>
  );
};

export default Success;
