import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  loginUser,
  removeStatus,
  setLoading,
  signUpUser,
} from "../features/user/UserSlice";
import { useNavigate } from "react-router-dom";
import { empluxIcon, signin_banner } from "../utils/Urls";
import { Toaster, toast } from "sonner";
import { Encode } from "../utils/GlobalFunctions";
import { BsEye, BsEyeFill, BsEyeSlash, BsEyeSlashFill } from "react-icons/bs";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  password: "",
  confirmpassword: "",
};
const Register = () => {
  const [values, setValues] = useState(initialState);
  const [isDisable, setDisable] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const { user, status, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "mobile") {
      const numbers = value?.replace(/\D/g, "");
      setValues({ ...values, [name]: numbers?.slice(0, 10) });
    } else setValues({ ...values, [name]: value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, mobile, email, password } = values;
    if (!firstName || !password || !mobile || !email) {
      toast.warning("Please fill out all fields");
      return;
    }
    const resp = await dispatch(
      signUpUser({
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        email: email,
        password: password,
      })
    );
    if (resp?.type?.indexOf("fulfilled") > -1) {
      // navigate("/register/verify-otp/" + Encode(values?.email));
      toast.success("Registration Successful", {
        onAutoClose: () => navigate("/login"),
        duration: 1000,
      });
    }
  };

  const toggleMember = () => {
    setTimeout(() => {
      navigate("/login");
    }, 10);
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 10);
    }
  }, [user, navigate]);

  useEffect(() => {
    if (
      values.password === values.confirmpassword &&
      values.password &&
      values.confirmpassword
    ) {
      setDisable(true);
    } else setDisable(false);
  }, [values.confirmpassword, values.password]);

  //  Regex code for validation

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyname: "",
    password: "",
    confirmpassword: "",
  });

  const validateName = (firstName) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(firstName);
  };

  const validateLastName = (lastName) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(lastName);
  };

  const validateCompanyName = (companyname) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/;
    return regex.test(companyname);
  };

  const validateEmail = (login) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(login);
  };
  const validatePassword = (Password) => {
    // const regex = /^(?=.\d)(?=.[!@#$%^&])(?=.[a-z])(?=.*[A-Z]).{8,}$/;
    const regex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*()_\-+=\[\]{}|\\;:'",.<>?/`~]).{6,}$/;
    return regex.test(Password);
  };

  const handleInputFocusOut = (event) => {
    const { name, value } = event.target;
    if (name === "email" && !validateEmail(value)) {
      setErrors({ ...errors, email: "Please enter a valid email" });
    } else if (name === "firstName" && !validateName(value)) {
      setErrors({
        ...errors,
        firstName:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "lastName" && !validateLastName(value)) {
      setErrors({
        ...errors,
        lastName:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "companyname" && !validateCompanyName(value)) {
      setErrors({
        ...errors,
        companyname:
          "at least 2 characters & does not start or end with a special character",
      });
    } else if (name === "password" && !validatePassword(value)) {
      setErrors({
        ...errors,
        password:
          "password should contain atleast 6 characters, 1 special character and 1 number",
      });
    } else if (name === "confirmpassword" && value != values.password) {
      setErrors({
        ...errors,

        confirmpassword: "Password is not matching.",
      });
    } else {
      // input is correct, remove error message for this field
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    dispatch(removeStatus());
    dispatch(setLoading());
  }, []);

  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <Toaster richColors position="top-center" />
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
        {/* <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center text-center">
          <h2 className="text-4xl text-white font-bold mb-5">
            Welcome to ClapUrban
          </h2>
          <p className="text-medium text-white">
            simply dummy text of the printing and typesetting industry
          </p>
        </div> */}
      </div>

      <div className="w-full lg:w-1/2">
        <div className="mt-16">
          <img src={empluxIcon} alt="Logo" className="mx-auto h-32" />
        </div>

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className="form-section">
            <form onSubmit={onSubmit}>
              {/* Sign-up UI starts here */}
              <div className="flex gap-2 items-center mb-4">
                <div className={"  w-full"}>
                  {/* First Name*/}
                  <input
                    className="block w-full px-4 py-2  text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    onChange={handleChange}
                    style={errors.firstName ? { borderColor: "red" } : null}
                  />
                  {errors.firstName && (
                    <div style={{ color: "red" }} className="text-xs pl-2">
                      {errors.firstName}
                    </div>
                  )}
                </div>
                <div className=" w-full">
                  {/* Last Name */}
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    onChange={handleChange}
                    className="block w-full px-4 py-2  text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
              </div>

              <div className="mb-4 mt-3">
                {/* Email field */}
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile Number"
                  onChange={handleChange}
                  value={values?.mobile}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              <div className={" mt-3 " + (!errors.email && " mb-5")}>
                {/* Email field */}
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  onBlur={handleInputFocusOut}
                  style={errors.email ? { borderColor: "red" } : null}
                />
                {errors.email && (
                  <div style={{ color: "red" }} className="text-xs pl-2">
                    {errors.email}
                  </div>
                )}
              </div>
              {/* Password field */}
              <div
                className={" mt-3 relative  " + (!errors.password && " mb-5")}>
                <input
                  type={isShowPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleInputFocusOut}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  style={errors.password ? { borderColor: "red" } : null}
                />
                {errors.password && (
                  <div style={{ color: "red" }} className="text-xs pl-2">
                    {errors.password}
                  </div>
                )}
                <span
                  className="absolute right-3 mt-1 text-2xl top-1"
                  onClick={() => setShowPassword((prev) => !prev)}>
                  {!isShowPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                </span>
              </div>

              <div className={" mt-3 " + (!errors.confirmpassword && " mb-5")}>
                <input
                  type="password"
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  style={errors.confirmpassword ? { borderColor: "red" } : null}
                />
                {errors.confirmpassword && (
                  <div style={{ color: "red" }} className="text-xs pl-2">
                    {errors.confirmpassword}
                  </div>
                )}
              </div>

              <div className="mt-6">
                <button
                  disabled={isLoading || !isDisable}
                  className={
                    isLoading || !isDisable
                      ? " cursor-not-allowed w-full  px-4 py-2 tracking-wide text-white  bg-neutral-800 rounded-md "
                      : "w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-neutral-800 rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600"
                  }>
                  {isLoading ? "Loading..." : "Sign Up"}
                </button>
              </div>

              <p className="mt-8 text-xs font-light text-center text-gray-700">
                Already have an account?
                <button
                  type="button"
                  className="font-medium text-neutral-800 outline-white hover:underline ml-2"
                  onClick={toggleMember}>
                  <strong>Sign In</strong>
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
