import React from "react";
import Completed from "../../components/Completed";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const BookingCompleted = () => {
  const { bookingId } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="w-full min-h-screen bg-white">
      <Completed confirmed={bookingId} />
    </div>
  );
};

export default BookingCompleted;
