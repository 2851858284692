import React from "react";
import { CommingSoonLayout } from "../../../components";

const Guide = () => {
  return (
    <div className="w-full h-full">
      <CommingSoonLayout />
    </div>
  );
};

export default Guide;
