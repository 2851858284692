import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { BsSearch, BsLightningFill } from "react-icons/bs";
import { PiStackFill } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getSubHome } from "../../../features/Home/homeSlice";
import {
  fetchAllSubCategory,
  subCategorySearch,
} from "../../../features/SubCategory/SubCategorySlice";

const Search = () => {
  const { query } = useParams();
  const location = useLocation();
  const searchBar = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { homeSubSection } = useSelector((store) => store.home);
  const { SubCategories } = useSelector((store) => store.subcategory);

  const searchSuggestion = [
    "Mac Repair",
    "Windows Repair",
    "Desktop Repair",
    "I-phone Repair",
    "DSLR Repair",
    "Camera Repair",
    "Washing Machine Repair",
    "Mac Mini Repair",
    "DSLR Repair",
    "Camera Repair",
    "Washing Machine Repair",
    "Mac Mini Repair",
  ];

  const [searchText, setSearchText] = useState(location?.state?.value);
  const [isSearched, setSearched] = useState(false);

  const handleSearchChange = (e) => {
    const val = e?.target?.value?.trim();
    setSearchText(val);
    // if(val?.length>2)
    // {
    //   dispatch(subCategorySearch({name:val}));
    // }
  };

  const handleSuggestionClick = (val) => {
    setSearchText(val);
    document.getElementById("search")?.focus();
  };

  //search
  const onSearchSubmit = async (e, isAll = false) => {
    e.preventDefault();
    if (isAll) {
      await dispatch(fetchAllSubCategory());
      setSearchText("");
    } else await dispatch(subCategorySearch({ name: searchText }));
    setSearched(true);
  };
  useEffect(() => {
    document.getElementById("search")?.focus();
    dispatch(getSubHome(3));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isSearched && SubCategories?.length === 1) {
      const SubCatgId = SubCategories?.slice(0, 1)[0]?.id;
      navigate(`/service/${SubCatgId}`);
    }
  }, [SubCategories, isSearched]);

  useEffect(() => {
    if (query == "Categories") {
      dispatch(fetchAllSubCategory({ page: 0, size: 50 }));
      setSearched(true);
    } else if (query?.length > 0) {
      dispatch(subCategorySearch({ name: query }));
      setSearched(true);
    }
  }, [query]);

  return (
    <div className="w-full pb-20">
      <form
        className={
          "m-auto pl-4 mb-4 flex justify-center bg-white border rounded-lg overflow-hidden " +
          (isSearched ? " w-5/6" : " w-1/2 mt-4")
        }
        onSubmit={onSearchSubmit}>
        <input
          type="search"
          id="search"
          ref={searchBar}
          value={searchText}
          onChange={handleSearchChange}
          className="py-2 px-4 text-xl  w-full outline-none capitalize"
        />
        <button
          type="submit"
          className="px-4 bg-primary-600 flex items-center justify-center text-white">
          <BsSearch className="text-xl" />
        </button>
      </form>
      <div className={isSearched && "flex m-auto w-5/6 "}>
        <div
          className={
            " m-auto  flex gap-6  " +
            (isSearched
              ? " w-full mt-2 overflow-x-scroll no-scrollbar px-5 "
              : " w-1/2 flex-wrap mt-8 justify-center")
          }>
          {searchSuggestion?.map((elem, index) => {
            return (
              <div
                className={
                  "flex border border-primary-600 gap-2 items-center px-1 rounded-md cursor-pointer w-max whitespace-nowrap h-full " +
                  (isSearched ? " py-1" : " py-2")
                }
                key={index}
                onClick={() => handleSuggestionClick(elem)}>
                <BsLightningFill
                  className={
                    " text-primary-600 " + (isSearched ? " " : " text-lg")
                  }
                />
                <span className="font-thin text-sm">{elem}</span>
              </div>
            );
          })}
        </div>
        {isSearched && (
          <div
            className="flex border border-primary-600 gap-2 items-center px-4 ml-2 rounded-md cursor-pointer w-max whitespace-nowrap py-1 text-sm mt-2"
            onClick={(e) => onSearchSubmit(e, true)}>
            <PiStackFill className=" text-primary-600" />
            All
          </div>
        )}
      </div>

      {isSearched && (
        <div className="w-5/6 px-5 m-auto min-h-[20vh] mt-10 gap-4 flex flex-col">
          {SubCategories?.map((elem, index) => {
            return (
              <Link
                to={`/service/${elem?.id}`}
                key={index}
                className="flex items-center w-full border border-primary-30 heading-gradient rounded-md p-4 gap-4 expand-horizontal hover:scale-105 ease-in-out transition-all">
                <img
                  src={elem?.iconUrls}
                  alt=""
                  className="h-14 w-14  object-fill"
                />
                <div className="flex flex-col ">
                  <span>{elem?.name}</span>
                </div>
              </Link>
            );
          })}
        </div>
      )}
      {/* Feature Services */}
      <div
        className={
          "min-h-10  m-auto mt-16 " + (isSearched ? " w-5/6 px-5" : " w-1/2")
        }>
        <h1 className="font-semibold uppercase mb-4">
          {homeSubSection?.title}
        </h1>
        <div className="flex gap-10 w-full overflow-x-scroll no-scrollbar">
          {homeSubSection?.homeSubSections?.map((elem, index) => {
            return (
              <Link
                to={`/service/${elem?.subCategoryId}`}
                className="flex flex-col gap-2">
                <img
                  src={elem?.thumbnailUrl}
                  alt=""
                  className="w-[12rem] object-cover shadow-md"
                />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Search;
