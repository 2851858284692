import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { MdVerified, MdDangerous } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { FiRefreshCw } from "react-icons/fi";
import { MdMoreTime } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  cancelBooking,
  postRating,
} from "../../features/Bookings/BookingSlice";
import Bike from "../../Images/icons/Bike.svg";
import Item from "../../Images/icons/Item.svg";
import Time from "../../Images/icons/Time.svg";
import Ratings from "./../Ratings";
import WriteReview from "./../WriteReview";

const BookingCardsMobile = ({
  amount,
  Id,
  catagory,
  subcatagory,
  date_time,
  status,
  paymentMethod,
  partnerName,
  partnerNumber,
  partnerImage,
  noOfIssues,
  OrderId,
  handlePayment,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slotDate = new Date(date_time || null);
  const options = { hour: "2-digit", minute: "2-digit" };
  const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
    slotDate
  );
  console.log(formattedTime);

  const [rating, setRating] = useState(0);
  const handleRating = (stars) => {
    dispatch(
      postRating({
        data: { rating: stars, review: "", description: "", show: true },
        bookingId: Id,
      })
    );
  };

  const [writeReview, setWriteReview] = useState();
  const handleWriting = () => {
    setWriteReview(!writeReview);
  };

  const dateDifference = () => {
    const today = new Date();
    const tommorow = new Date();
    const yesterday = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      today.getDate() === slotDate.getDate() &&
      today.getMonth() === slotDate.getMonth() &&
      today.getFullYear() === slotDate.getFullYear()
    ) {
      return 0;
    } else if (
      tommorow.getDate() === slotDate.getDate() &&
      tommorow.getMonth() === slotDate.getMonth() &&
      tommorow.getFullYear() === slotDate.getFullYear()
    ) {
      return 1;
    } else if (
      yesterday.getDate() === slotDate.getDate() &&
      yesterday.getMonth() === slotDate.getMonth() &&
      yesterday.getFullYear() === slotDate.getFullYear()
    ) {
      return -1;
    } else {
      return 999;
    }
  };
  const bookingSlot =
    dateDifference() === 0
      ? "Today"
      : dateDifference() === 1
      ? "Tommorow"
      : dateDifference() === -1
      ? "Yesterday"
      : slotDate.toDateString() + " " + formattedTime;

  // Navigate function
  const handleClick = (location, id) => {
    if (location?.toLowerCase() === "details") {
      navigate(`/booking-details/${id}`);
    } else {
      navigate(dispatch(cancelBooking({ bookingId: Id })));
    }
  };

  return (
    <div className="border rounded-lg border-gray-300">
      {/* popups */}
      {writeReview && (
        <WriteReview handleWriting={handleWriting} bookingId={Id} />
      )}
      <div className="flex relative items-center transition-all ease-in-out duration-100 hover:scale-[1.01] cursor-pointer px-[0.875rem] flex-col pb-[1.125rem]">
        {/* old ui */}
        <div
          className="  h-full w-full  py-[1.125rem]  "
          onClick={() => handleClick("details", Id)}>
          <div className="flex items-center gap-5   ">
            <span className=" w-5 flex items-center justify-center">
              <img src={Item} alt="" />
            </span>
            <span className="flex items-center text-sm text-[#000000BF] font-normal">
              <div className="min-w-[12ch] font-bold">{OrderId}</div>
              <span className="h-3 border-r-2 border-[#666666] mr-2 ml-3"></span>
              <span className=" text-[1rem] ">₹</span>

              <span className=" text-[1rem]">{amount}</span>
            </span>
          </div>
          <div className="flex items-center gap-5">
            <span className=" w-5 flex items-center justify-center">
              <img src={Bike} alt="" />
            </span>
            <span>
              <span className="flex items-center text-sm text-[#000000BF] font-normal">
                <div className="min-w-[12ch]">{catagory}</div>
                <span className="h-3 border-r-2 border-[#666666] mr-2 ml-3"></span>
                <span className=" text-[1rem]">{subcatagory}</span>
              </span>
            </span>
          </div>

          <div>
            <span className="flex items-center gap-5 ">
              <span className=" w-5 flex items-center justify-center">
                <img src={Time} alt="" />
              </span>

              <span className="text-sm text-[#6B9279] font-semibold">
                {slotDate?.toDateString() + " " + formattedTime}
              </span>
            </span>
          </div>
        </div>

        <div className="w-full ">
          {status?.toLowerCase() === "future" ||
          (status?.toLowerCase() === "ongoing" &&
            paymentMethod?.toLowerCase() === "cash") ? (
            <div className="flex text-sm font-semibold w-full gap-2  ">
              {status?.toLowerCase() === "future" && (
                <button
                  className="px-3 py-2 border border-[#00000066] rounded-lg  z-10 text-[#676565] hover:bg-[#433f3f18] min-w-max w-full "
                  onClick={() => handleClick("cancel", Id)}>
                  Cancel
                </button>
              )}

              {paymentMethod?.toLowerCase() === "cash" && (
                <button
                  className="px-3 py-2 border border-primary-600 rounded-lg  bg-primary-600 hover:bg-primary-max  text-white z-10 min-w-max w-full"
                  onClick={() => handlePayment(Id, amount)}>
                  Pay Now
                </button>
              )}
            </div>
          ) : (
            <div className=" absolute right-2 p-2  top-1/2 transform -translate-y-1/2 ">
              <IoIosArrowForward className="text-xl text-gray-600" />
            </div>
          )}
        </div>
      </div>
      {status?.toLowerCase() === "completed" && (
        <div className="px-[2rem] flex items-center h-[3rem] border-t border-gray-300">
          <div className="flex items-center w-3/4 gap-[0.7rem]">
            <div className="text-sm">Rate our Service</div>
            <div className="flex gap-[0.3rem] text-[24px] w-[10rem] cursor-pointer">
              <Ratings ratings={rating} getStar={handleRating} isHover={true} />
            </div>
          </div>
          <div
            className="flex w-1/4 justify-end font-bold underline text-pink-600 cursor-pointer"
            onClick={handleWriting}>
            Write Review
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingCardsMobile;
