import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiFillCamera } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { FiUser } from "react-icons/fi";
import { CiWallet } from "react-icons/ci";
import { FaRegAddressBook } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";
import { GrCheckmark } from "react-icons/gr";
import { clearFile, updateImage } from "../features/Profile/ProfileSlice";
import UploadFileTemp from "./UploadFileTemp";
import { uploadFile } from "../features/Files/FilesSlice";
import { noUserIcon } from "../utils/Constants";

const ProfileSidebar = ({ userData, current, className }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(null);
  const { isLoading, uploadUrl } = useSelector((store) => store?.file);
  const location = useLocation();
  const onUploaded = (name, url) => {
    if (url) {
      dispatch(updateImage(url));
    }
  };

  const handleImageChange = async (e) => {
    const getImage = e.target.files[0];

    if (!getImage) {
      return;
    }

    setImage({
      ...image,
      file: getImage,
      filepreview: URL.createObjectURL(getImage),
    });
  };

  const handleUpload = () => {
    const fileData = new FormData();

    if (image?.file) {
      fileData.append("file", image.file, image?.file?.name);
      setUploading("upload");
      dispatch(uploadFile(fileData));
    } else {
      alert("Nothing to upload!");
    }
    setImage(null);
  };
  useEffect(() => {
    if (uploading === "upload" && uploadUrl) {
      onUploaded("uploaded", uploadUrl);
    }
    setUploading(null);
    dispatch(clearFile());
  }, [uploadUrl]);
  // ########################### UpdateUrl #############################
  return (
    <div
      className={
        "min-w-[16.875rem] heading-gradient  flex flex-col items-center justify-start  text-[#FFFFFFCC] pb-[10rem] max-md:min-w-max px-3 " +
        className
      }>
      {/* popups */}

      <label htmlFor="fileInput" className="hidden">
        <input
          type="file"
          id="fileInput"
          name="profile_image_url"
          onChange={handleImageChange}
        />
      </label>
      {location?.pathname?.indexOf("profile") > -1 && (
        <div className="relative mt-8 max-md:mt-3">
          <label htmlFor="fileInput">
            <img
              src={
                image?.filepreview || userData?.profile_image_url || noUserIcon
              }
              alt={userData?.firstName}
              className="object-cover w-32 aspect-square border bg-white rounded-full max-md:w-24"
            />
          </label>
          {image?.filepreview ? (
            <div
              className="absolute bg-[#1c9f29f9] w-10 max-md:w-8 h-10 max-md:h-8 p-2 rounded-full bottom-0 right-0 border-double text-white cursor-pointer"
              onClick={handleUpload}>
              <GrCheckmark className=" absolute bg-[#1c9f29f9] w-10 max-md:w-8 h-10 max-md:h-8 p-2 rounded-full bottom-0 right-0 border-double text-white " />
            </div>
          ) : (
            <label
              htmlFor="fileInput"
              className="absolute bg-[#eebe00f9] w-10 max-md:w-8 h-10 max-md:h-8 p-2 rounded-full bottom-0 right-0 border-double text-white cursor-pointer">
              <AiFillCamera className=" absolute bg-[#eebe00f9] w-10 max-md:w-8 h-10 max-md:h-8 p-2 rounded-full bottom-0 right-0 border-double text-white " />
            </label>
          )}
        </div>
      )}

      <div className="flex-col flex mt-[4rem] gap-[2.25rem] w-[60%]  text-lg  min-w-max max-md:mt-8">
        <Link
          to={"/profile"}
          className="flex gap-6 px-2 max-md:gap-2 items-center">
          <FiUser className="text-2xl" />{" "}
          <span className="max-sm:hidden">Profile</span>
        </Link>
        <Link
          to={"/address"}
          className="flex gap-6 px-2 max-md:gap-2 items-center">
          <FaRegAddressBook className="text-2xl" />{" "}
          <span className="max-sm:hidden">Address</span>
        </Link>
        <Link
          to={"/settings"}
          className="flex gap-6 px-2 max-md:gap-2 items-center">
          <IoSettingsOutline className="text-2xl" />{" "}
          <span className="max-sm:hidden">Settings</span>
        </Link>
        <Link
          to={"/help"}
          className="flex gap-6 px-2 max-md:gap-2 items-center">
          <MdOutlineSupportAgent className="text-2xl" />{" "}
          <span className="max-sm:hidden">Help Center</span>
        </Link>
      </div>
    </div>
  );
};

export default ProfileSidebar;
