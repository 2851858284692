import React, { useEffect, useState } from "react";
import Desktop from "../../components/RentalCart/Desktop";
import Mobile from "../../components/RentalCart/Mobile";

const RentalCart = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 720);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return <div>{isDesktop ? <Desktop /> : <Mobile />}</div>;
};

export default RentalCart;
