import React from "react";

const Confirm = ({ question, handleConfirmation, sence, description }) => {
  return (
    <div className="fixed w-screen h-screen flex items-center justify-center  top-0  left-0 z-50">
      <div
        className="w-full h-full bg-[#0000002f] absolute"
        onClick={() =>
          handleConfirmation ? handleConfirmation(false) : ""
        }></div>
      <div className="bg-white rounded-xl flex flex-col items-center justify-between py-8 px-12 w-[20vw] min-w-max  min-h-max  tracking-wide popup max-w-screen z-10 ">
        <div className="text-lg font-light">{question}</div>
        <p
          className="max-w-[25rem] text-sm font-medium text-center mt-4"
          dangerouslySetInnerHTML={{ __html: description }}></p>
        <div className="flex justify-between w-4/6 mt-8">
          <button
            className={`px-4 py-2  rounded-lg ${
              sence ? " bg-gray-200 " : " bg-primary-600 text-white"
            }`}
            onClick={() =>
              handleConfirmation ? handleConfirmation(true) : ""
            }>
            Yes
          </button>
          <button
            className={`px-4 py-2 rounded-lg ${
              sence ? " bg-primary-600 text-white" : " bg-gray-200 "
            }`}
            onClick={() =>
              handleConfirmation ? handleConfirmation(false) : ""
            }>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
