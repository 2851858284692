import React from "react";
import { SlLocationPin } from "react-icons/sl";
import { BsSearch } from "react-icons/bs";
import { BsCart2 } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import Payment from "../../Images/icons/fluent_payment-24-regular copy.svg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import rentalhome1 from "../../Images/rental_home1.svg";

const Desktop = () => {
  return (
    <div>
      <div className="flex h-[4.375rem] justify-between border-t border-b border-[#5f5f5f75] shadow-lg ">
        <div className="flex items-center justify-center gap-4 w-1/2  h-full">
          <div className="flex justify-center items-center gap-1 text-[#313131]">
            <SlLocationPin />
            <div className="h-[2.313]">Deliver to</div>
          </div>
          <input
            type="text"
            placeholder="Ex: Bangalore, Karnataka"
            className="h-[2.313] p-3 px-4 text-xs w-[20rem] rounded-xl bg-[#ECECEC] outline-none"
          />
        </div>

        <div className="flex items-center justify-center w-1/2 h-full ">
          <div className="flex items-center justify-center bg-[#ECECEC] rounded-xl">
            <div className="pl-4 text-[#676565]">
              <BsSearch />
            </div>
            <input
              type="text"
              placeholder="Search electronic devices"
              className="h-[2.313] p-3  text-sm w-[20rem] bg-[#ECECEC] rounded-xl outline-none"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center my-[6rem]">
        <div className="flex  justify-center gap-[2rem] text-[#5a5959]">
          <div className="flex flex-col items-center justify-center gap-[1rem]">
            <BsCart2 className="text-5xl" />
            <div className="text-lg">Cart</div>
          </div>
          <div className="flex pt-[1rem]">
            ........................................
          </div>
          <div className="flex flex-col items-center justify-center gap-[1.3rem] ">
            <FaLocationDot className="text-[48px]" />
            <div className="text-lg">Address</div>
          </div>
          <div className="flex pt-[1rem]">
            .........................................
          </div>
          <div className="flex flex-col items-center justify-center gap-[0.35rem]">
            <img src={Payment} alt="" />
            <div className="text-lg">Payment</div>
          </div>
          <div className="flex pt-[1rem]">
            ..........................................
          </div>
          <div className="flex flex-col items-center justify-center gap-[1rem]">
            <IoIosCheckmarkCircle className="text-5xl" />
            <div className="text-lg">Confirm</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[6rem] pl-[9rem] pr-[9rem] mb-[6rem]">
        <div className="flex items-center  w-full outfit  ">
          <div className="flex justify-end ">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start  gap-4 w-1/2 pl-[6rem] ">
            <div className="flex flex-col gap-1">
              <div className="text-xl">HP Laptop</div>
              <div className="text-xs ">Best quality premium product</div>
            </div>
            <div className="flex flex-col gap-[1rem]">
              <div className="text-[#FF179A]">₹400/mo</div>
              <div className="border py-2 px-6 border-primary-600 rounded-3xl">
                36% off
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-end  w-1/2 gap-4">
            <div className="flex items-center justify-center gap-[2rem] w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              <div className=" rounded-lg px-[1rem] text-xl bg-[#d9d9d9] border border-primary-600 ">
                +
              </div>
              <div className="">Qn. 2</div>
              <div className="rounded-lg px-[1rem] text-xl bg-[#d9d9d9] border border-primary-600 ">
                -
              </div>
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              Save for later
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              View Detail
            </div>
          </div>
        </div>
        <div className="flex items-center  w-full outfit  ">
          <div className="flex justify-end ">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start  gap-4 w-1/2 pl-[6rem] ">
            <div className="flex flex-col gap-1">
              <div className="text-xl">HP Laptop</div>
              <div className="text-xs ">Best quality premium product</div>
            </div>
            <div className="flex flex-col gap-[1rem]">
              <div className="text-[#FF179A]">₹400/mo</div>
              <div className="border py-2 px-6 border-primary-600 rounded-3xl">
                36% off
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-end  w-1/2 gap-4">
            <div className="flex items-center justify-center gap-[2rem] w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              <div className=" rounded-lg px-[1rem] text-xl bg-[#d9d9d9] border border-primary-600 ">
                +
              </div>
              <div className="">Qn. 2</div>
              <div className="rounded-lg px-[1rem] text-xl bg-[#d9d9d9] border border-primary-600 ">
                -
              </div>
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              Save for later
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              View Detail
            </div>
          </div>
        </div>
        <div className="flex items-center  w-full outfit  ">
          <div className="flex justify-end ">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start  gap-4 w-1/2 pl-[6rem] ">
            <div className="flex flex-col gap-1">
              <div className="text-xl">HP Laptop</div>
              <div className="text-xs ">Best quality premium product</div>
            </div>
            <div className="flex flex-col gap-[1rem]">
              <div className="text-[#FF179A]">₹400/mo</div>
              <div className="border py-2 px-6 border-primary-600 rounded-3xl">
                36% off
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-end  w-1/2 gap-4">
            <div className="flex items-center justify-center gap-[2rem] w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              <div className=" rounded-lg px-[1rem] text-xl bg-[#d9d9d9] border border-primary-600 ">
                +
              </div>
              <div className="">Qn. 2</div>
              <div className="rounded-lg px-[1rem] text-xl bg-[#d9d9d9] border border-primary-600 ">
                -
              </div>
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              Save for later
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer">
              View Detail
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between w-full border-t border-b h-[6rem]  ">
        <div className="flex flex-col pl-[20rem] w-2/3">
          <div>
            <div className="text-2xl">Product Cost</div>
            <div>Offer</div>
            <div>Delivery</div>
          </div>
        </div>
        <div className="flex flex-col items-center w-1/2">
          <div className="flex flex-col items-end">
            <div className="text-2xl">₹600</div>
            <div>-100</div>
            <div>+50</div>
          </div>
        </div>
      </div>
      <div className="flex  w-full border h-[8rem] pl-[20rem] pr-[4rem]">
        <div className="w-2/3 text-2xl font-semibold ">Total</div>
        <div className="flex flex-col items-center w-1/2">
          <div className="text-xl font-semibold">₹600</div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
