import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddresses } from "../features/address/addressSlice";
import { Link } from "react-router-dom";

const SelectAddress = ({ addressId, handleClick, setAddress }) => {
  const dispatch = useDispatch();
  const { addresses } = useSelector((state) => state.address);
  const handleAddressChange = (id) => {
    if (setAddress) setAddress(id);
  };

  useEffect(() => {
    if (
      Object.keys(addresses).length === 0 ||
      Object.keys(addresses?.slice(0, 1)[0]).length === 0
    ) {
      console.log("fetching address");
      dispatch(fetchAddresses());
    }
  }, []);
  return (
    <div className="fixed z-50 w-full h-full flex  top-0 items-center justify-center bg-[#00000083] ">
      {/* main popupo */}
      <div className="relative w-[400px] h-[450px] bg-gray-100 rounded-md  overflow-hidden expand ">
        <div className="flex items-center font-mono justify-center bg-white rounded-t-md p-3 font-semibold">
          <span>Select Address</span>
          <button
            className="absolute right-1 p-2 text-xl hover:bg-gray-200"
            onClick={() => handleClick(2)}>
            <AiOutlineClose />
          </button>
        </div>
        {/* Address Options */}
        <div className="flex flex-col gap-2 bg-white mt-4 h-[315px] w-full hover:overflow-auto overflow-hidden  rounded-md pt-2  px-3">
          {/* Addresses */}
          {addresses?.map((element, index) => {
            const { id, address, city, state, locality, pinCode } = element;
            return (
              <div
                className="flex flex-col h-24 py-2   border-b-2 border-dotted cursor-pointer "
                onClick={() => {
                  handleAddressChange(id);
                  handleClick(3);
                }}
                key={index}>
                <div className="flex items-center gap-2 h-full ">
                  <input
                    type="radio"
                    name="address"
                    className="h-4 w-4 accent-black "
                    value={1}
                    checked={addressId == id}
                  />
                  <div className="text-black font-semibold uppercase text-md ">
                    Home
                  </div>
                </div>

                <div className="flex flex-col flex-wrap text-sm w-11/12 ml-6">
                  <p className="w-full h-6 overflow-hidden  ">
                    {locality + ", " + city + ", "},
                  </p>
                  <p>{state + "-" + pinCode}</p>
                </div>
              </div>
            );
          })}
          {/* <div
              className="flex flex-col h-24 py-2   border-b-2 border-dotted cursor-pointer "
              onClick={() => {
                handleAddressChange(1);
                handleClick(3);
              }}>
              <div className="flex items-center gap-2 h-full ">
                <input
                  type="radio"
                  name="address"
                  className="h-4 w-4 accent-black "
                  value={1}
                  checked={addressId == 1}
                />
                <div className="text-black font-semibold uppercase text-md ">
                  Home
                </div>
              </div>

              <div className="flex flex-col flex-wrap text-sm w-11/12 ml-6">
                <p className="w-full h-6 overflow-hidden  ">
                  Raja Fish Farm, dillian, ward no -20, dehri, Rohtash dillian,
                  ward no -20, dehri, Rohtash, ward no -20, dehri, Rohtash{" "}
                </p>
                <p>Bihar-821307</p>
              </div>
            </div> */}
        </div>
        <div className="flex items-center justify-center border  w-full h-1/6  m-auto  ">
          <Link
            to="/address"
            className="p-1 py-2 my-4 border border-white rounded-md  bg-primary-600 uppercase  font-semibold  w-11/12 text-white text-center">
            Add New Address
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SelectAddress;
