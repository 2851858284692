import React, { useEffect } from "react";
import { BsFillTrash2Fill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoCartOutline } from "react-icons/io5";
import {
  deleteCart,
  getCart,
  setTotalAmount,
} from "../features/Cart/CartSlice";
import { useState } from "react";

const Cart = ({ location }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subCatgId } = useParams();

  const { issuesList } = useSelector((state) => state.issue);
  const { totalAmount, cartItems } = useSelector((state) => state.cart);
  const [isEmpty, setEmpty] = useState(cartItems?.length > 0 ? false : true);

  const handleRemove = (cartId) => {
    dispatch(deleteCart(cartId));

    // setTimeout(() => {
    //   dispatch(getCart());
    // }, 3000);
  };

  const checkEmptyCart = () => {
    if (cartItems?.length === 0) {
      return true;
    } else {
      const target = cartItems?.slice(0, 1)[0]?.issueId;
      const isTargetPresent = issuesList?.some((obj) => obj.id === target);
      return !isTargetPresent;
    }
  };

  useEffect(() => {
    setEmpty(checkEmptyCart());
    if (!cartItems?.length > 0 && location !== "Services") {
      navigate(`/service/${subCatgId}`);
    }
  }, [cartItems]);

  useEffect(() => {
    dispatch(getCart());
  }, []);
  return (
    <div className="relative  min-h-max min-w-[20rem] w-full mt-5 ">
      {/* Heading  */}
      <div className="p-4 text-3xl my-2 font-semibold font-sans min-h-[1rem]"></div>

      {/* Items */}
      {!isEmpty ? (
        <>
          {cartItems?.map((element) => {
            const {
              id,
              issueName,
              issueDuration,
              issuePrice,
              issueDescription,
              active,
            } = element;
            if (active) {
              return (
                <div className="relative flex items-center  border h-14 shadow  mb-2 mx-4 rounded-md expand-horizontal">
                  <span
                    className=" absolute  top-1 left-1 p-1 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleRemove(id)}>
                    <AiFillCloseCircle className="text-xl text-primary-600" />
                  </span>
                  <span className="absolute left-10 ">{issueName}</span>
                  <span className="absolute left-[80%]">Rs. {issuePrice}</span>
                </div>
              );
            }
          })}
          {/* <div className="flex items-center  border shadow-md py-1  h-14 mb-2 mx-4 rounded-md relative px-10 text-white bg-primary-600 ">
            <span className=" font-bold ">Total</span>
            <span className="absolute left-[80%] font-semibold">
              Rs. {totalAmount}
            </span>
          </div> */}
        </>
      ) : (
        <div className="h-1/2 p-2">
          <div className="flex flex-col items-center justify-center border-dashed border-2 text-gray-400 p-4 gap-3 mx-4">
            <BsFillTrash2Fill className="text-3xl" />
            <span>Cart is Empty</span>
          </div>
        </div>
      )}

      {/* View details Button  */}
      {location === "Services" ? (
        <div className="sticky bottom-0 mx-4 left-0 ">
          <div
            className={
              " gap-2 flex  h-14  items-center px-4  text-white bg-primary-600 cursor-pointer " +
              (isEmpty ? " rounded-xl" : " rounded-t-xl rounded-bl-xl")
            }
            onClick={() => {
              if (!isEmpty) navigate(`/check-out-details/cart/${subCatgId}`);
            }}>
            {!isEmpty && (
              <div className="absolute up-to-down top-[100%] bg-[#EEBF00] text-white  py-2 px-5 tracking-wider right-0 rounded-b-md whitespace-nowrap -z-10 cursor-pointer">
                Proceed to checkout
              </div>
            )}
            {!isEmpty ? (
              <div className="w-full flex items-center justify-between px-4">
                <span className="font-semibold">Total</span>
                <span className="">Rs.{totalAmount}</span>
                {/* <span>Rs.{totalAmount}</span> */}
              </div>
            ) : (
              <div className="m-auto text-xl font-semibold font-mono flex items-center gap-4">
                <IoCartOutline className="text-2xl" />
                Your Cart
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className=" gap-2 flex left-0 h-14 rounded-t-xl items-center px-4 sticky bottom-0 w-full border text-white bg-primary-600 cursor-pointer"
          onClick={() => {
            navigate(`/service/${subCatgId}`);
          }}>
          <span className="ml-4 font-semibold">Add More</span>
          <span className="absolute right-14">Total : Rs.{totalAmount}</span>
        </div>
      )}
    </div>
  );
};

export default Cart;
