import React, { useEffect } from "react";

const RefundsCancellations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">
        Refunds and Cancellations Policy
      </h1>
      <p className="text-sm text-gray-600 mb-4">Last Updated: 15th Aug 2024</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Cancellation Policy</h2>
        <p>
          You may cancel your Booking within 1 hour of placing it. After this
          period, cancellations are subject to approval based on the progress of
          the service or order.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Refund Policy</h2>
        <p>
          Refunds are processed within 5 to 7 working days after the
          cancellation is approved. The refund will be credited back to your
          original payment method. Please note that the time taken for the
          refund to appear in your account may vary depending on your bank.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Non-Refundable Items</h2>
        <p>
          Some items or services may be non-refundable. These will be clearly
          marked as such at the time of purchase. Please review the product or
          service description carefully before making a purchase.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
        <p>
          If you have any questions about our Refunds and Cancellations Policy,
          please contact us:
        </p>
        <p>Phone: +91 91133 88758</p>
        <p>Email: help.emplux@gmail.com</p>
        <p>
          Address: #Eco Mitra Road, Near Pinnacle Apartment, Virgo Nagar Post,
          Hirandhalli, Bangalore-49, India
        </p>
      </section>
    </div>
  );
};

export default RefundsCancellations;
