import React from "react";
import { useNavigate } from "react-router-dom";
import CheckoutStatus from "../../CheckoutStatus";
import CheckoutSidebar from "../CheckoutSidebar";
import { toast } from "sonner";

const Mobile = ({
  subCatgId,
  location,
  handleDataChange,
  bookingState,
  selectedDay,
  handleDaySelect,
  thirdDate,
  hourNow,
  selectedHour,
  handleTimeSelect,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (bookingState?.bookingSlot) {
      navigate(`/check-out-details/payment/${subCatgId}#blockBack`, {
        state: bookingState,
      });
    } else {
      toast.error("Please select a slot");
    }
  };
  console.log("day: " + selectedDay);
  return (
    <div className="mb-40">
      <CheckoutStatus location={location} subCatgId={subCatgId} />
      <div className="py-4 mt-10 px-8 w-full  border-r flex flex-col gap-5 items-center">
        <div className="flex  items-center w-full  ">
          <h1 className=" text-xl capitalize font-semibold">Select Slot</h1>
        </div>
        {/* slots */}
        <div
          className={` flex   items-center justify-center gap-y-8 gap-x-2 font-mono ${
            selectedDay >= 0 ? " w-full text-xs" : " flex-col w-full"
          }`}>
          <div
            className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
              selectedDay === 0 ? " bg-black text-white" : ""
            }`}
            onClick={() => handleDaySelect(1)}>
            Today
          </div>
          <div
            className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
              selectedDay === 1 ? " bg-black text-white" : ""
            }`}
            onClick={() => handleDaySelect(2)}>
            Tommorow
          </div>
          <div
            className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
              selectedDay === 2 ? " bg-black text-white" : ""
            }`}
            onClick={() => handleDaySelect(3)}>
            {thirdDate?.toDateString()?.slice(0, -4)}
          </div>
        </div>

        {selectedDay >= 0 && (
          <div className="w-11/12 flex flex-col gap-4 expand text-xs">
            <div className="text-sm font-semibold  text-gray-400">Morning</div>
            <div className="grid gap-4 grid-cols-3">
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 9 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 9
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(9)}>
                09:00-10:00
              </span>
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 10 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 10
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(10)}>
                10:00-11:00
              </span>
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 11 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 11
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(11)}>
                11:00-12:00
              </span>
            </div>

            <div className="text-sm font-semibold  text-gray-400 mt-4">
              Afternoon
            </div>
            <div className="grid gap-4 grid-cols-3">
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 12 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 12
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(12)}>
                12:00-01:00
              </span>
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 13 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 13
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(13)}>
                01:00-02:00
              </span>
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 14 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 14
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(14)}>
                02:00-03:00
              </span>
            </div>

            <div className="text-sm font-semibold  text-gray-400 mt-4">
              Evening
            </div>
            <div className="grid gap-4 grid-cols-3">
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 15 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 15
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(15)}>
                03:00-04:00
              </span>
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 16 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 16
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(16)}>
                04:00-05:00
              </span>
              <span
                className={
                  "p-2 border rounded-md min-w-max  text-center " +
                  (hourNow + 1 >= 17 && selectedDay === 0
                    ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                    : selectedHour === 17
                    ? " border-black cursor-pointer bg-black text-white"
                    : " border-black cursor-pointer")
                }
                onClick={() => handleTimeSelect(17)}>
                05:00-06:00
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="w-11/12 mt-4 mx-auto">
        <CheckoutSidebar
          location={location}
          subCatgId={subCatgId}
          bookingState={bookingState}
        />
      </div>
      <div className="w-full py-[0.5rem] text-center fixed bottom-0 left-0 z-10 bg-white top-shadow min-w-full">
        <div
          onClick={handleNavigate}
          className="min-w-4/5 w-4/5 py-[0.5rem] text-center font-semibold text-white capitalize heading-gradient rounded-[3rem] mx-auto cursor-pointer">
          Proceed
        </div>
      </div>
    </div>
  );
};

export default Mobile;
