import React from "react";
import Services from "../../Images/icons/grommet-icons_services.svg";
import Guide from "../../Images/icons/ri_guide-line.svg";
import Offer from "../../Images/icons/bxs_offer.svg";
import FAQ from "../../Images/icons/mdi_faq.svg";
import Partner from "../../Images/icons/Vector.svg";
import Payment from "../../Images/icons/fluent_payment-24-regular.svg";
import { useNavigate } from "react-router-dom";

const Mobile = () => {
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <div className="">
      <h1 className="text-2xl font-semibold text-center w-full mt-[2rem] max-[500px]:text-xl">
        How can we help you?
      </h1>
      <div className="px-[18vw] pt-[5vw] max-[600px]:pt-[2rem] max-[600px]:px-[12vw] max-[500px]:px-0 max-[500px]:flex max-[500px]:justify-center">
        <div className="grid grid-cols-2  gap-x-[7.5vw] gap-y-[6.375vw]   max">
          <div
            className="bg-white w-[12rem] aspect-square max-sm:w-[11rem] max-sm:aspect-[5/6] max-xs:w-[10rem] max-xxs:w-[9rem] max-xxs:gap-3 rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
            style={{ boxShadow: "0 4px 4px 0 #00000040" }}
            onClick={() => handleNavigate("/help/support")}>
            <img src={Services} alt="" />
            <h1>Customer Service</h1>
          </div>
          <div
            className="bg-white w-[12rem] aspect-square max-sm:w-[11rem] max-sm:aspect-[5/6] max-xs:w-[10rem] max-xxs:w-[9rem] max-xxs:gap-3 rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
            style={{ boxShadow: "0 4px 4px 0 #00000040" }}
            onClick={() => handleNavigate("/help/guide")}>
            <img src={Guide} alt="" />
            <h1>Guide</h1>
          </div>
          <div
            className="bg-white w-[12rem] aspect-square max-sm:w-[11rem] max-sm:aspect-[5/6] max-xs:w-[10rem] max-xxs:w-[9rem] max-xxs:gap-3 rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
            style={{ boxShadow: "0 4px 4px 0 #00000040" }}
            onClick={() => handleNavigate("/_")}>
            <img src={Offer} alt="" />
            <h1>Offers</h1>
          </div>
          <div
            className="bg-white w-[12rem] aspect-square max-sm:w-[11rem] max-sm:aspect-[5/6] max-xs:w-[10rem] max-xxs:w-[9rem] max-xxs:gap-3 rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
            style={{ boxShadow: "0 4px 4px 0 #00000040" }}
            onClick={() => handleNavigate("/help/FAQs")}>
            <img src={FAQ} alt="" />
            <h1>FAQs</h1>
          </div>
          <div
            className="bg-white w-[12rem] aspect-square max-sm:w-[11rem] max-sm:aspect-[5/6] max-xs:w-[10rem] max-xxs:w-[9rem] max-xxs:gap-3 rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem]"
            style={{ boxShadow: "0 4px 4px 0 #00000040" }}
            onClick={() => handleNavigate("/_")}>
            <img src={Partner} alt="" />
            <h1>Partner</h1>
          </div>
          <div
            className="bg-white w-[12rem] aspect-square max-sm:w-[11rem] max-sm:aspect-[5/6] max-xs:w-[10rem] max-xxs:w-[9rem] max-xxs:gap-3 rounded-lg flex flex-col gap-6 items-center justify-center  pt-[3.688rem] pb-[2rem] mb-[10rem]"
            style={{ boxShadow: "0 4px 4px 0 #00000040" }}
            onClick={() => handleNavigate("/_")}>
            <img src={Payment} alt="" />
            <h1>Payment</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobile;
