import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import axios from "axios";
import { getBooking } from "../Bookings/BookingSlice";

const initialState = {
  isLoadingPayment: false,
  orderDetails: {},
};

export const postPaymentOnBooking = createAsyncThunk(
  "payment/postPaymentOnBooking",
  async (bookingId, thunkAPI) => {
    const user = getUserFromLocalStorage();
    const accessToken = user?.accessToken;
    try {
      const resp = await customFetch.post(
        `api/payments/${bookingId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(resp);
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.msg);
    }
  }
);

export const updatePaymentToBooking = createAsyncThunk(
  "payment/updatePaymentToBooking",
  async ({ bookingId, data }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.put(`/api/razorpay/${bookingId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      thunkAPI?.dispatch(getBooking());
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const makePayment = createAsyncThunk(
  "payment/makePayment",
  async (paymentData) => {
    const user = getUserFromLocalStorage();
    const token = user?.accessToken;
    try {
      const response = await customFetch.post(
        "/api/razorpay/payment",
        paymentData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createOrder = createAsyncThunk(
  "payment/createOrder",
  async (amount, thunkAPI) => {
    const user = getUserFromLocalStorage();
    const token = user?.accessToken;
    try {
      const response = await customFetch.post(
        "/api/razorpay/create_order",
        { amount: amount },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response);
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const postPaymentOnAmount = createAsyncThunk(
  "payment/postPaymentOnAmount",
  async (amount, thunkAPI) => {
    const user = getUserFromLocalStorage();
    const accessToken = user?.accessToken;
    try {
      const resp = await customFetch.post(`/api/payment/${amount}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      return resp?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.msg);
    }
  }
);

const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearPaymentDetails: (state) => {
      state.orderDetails = {};
      state.order = {};
    },
  },
  extraReducers: (builder) => {
    {
      builder
        .addCase(postPaymentOnBooking.fulfilled, (state, { payload }) => {
          state.isLoadingPayment = false;
          state.orderDetails = payload;
        })
        .addCase(postPaymentOnBooking.pending, (state) => {
          state.isLoadingPayment = true;
        })
        .addCase(postPaymentOnBooking.rejected, (state) => {
          state.isLoadingPayment = false;
        })
        .addCase(makePayment.fulfilled, (state, { payload }) => {
          state.paymentStatus = "success";
        })
        .addCase(makePayment.rejected, (state, action) => {
          state.paymentStatus = "failed";
          state.error = action?.error?.message;
        })
        .addCase(createOrder.fulfilled, (state, { payload }) => {
          state.orderSatatus = "success";
          state.order = payload;
        })
        .addCase(createOrder.rejected, (state, action) => {
          state.orderStatus = "failed";
          state.error = action?.error?.message;
        });
    }
  },
});

export const { clearPaymentDetails } = PaymentSlice.actions;

export default PaymentSlice.reducer;
