import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { toast } from "sonner";

const initialState = {
  isLoadingFAQ: false,
  FAQList: [],
};

//GET API for FAQs
export const getFAQ = createAsyncThunk("faq/getFAQ", async (thunkAPI) => {
  try {
    const resp = await customFetch.get(`api/faq`);
    return resp?.data;
  } catch (error) {
    if (error?.resp) {
      alert("Error adding FAQS");
    }
    return thunkAPI.rejectWithValue(error.resp);
  }
});

const FAQslice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setLoadingFAQ: (state, { payload }) => {
      state.isLoadingFAQ = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getFAQ.fulfilled, (state, { payload }) => {
        // alert("FAQ Successfully Added!");
        state.isLoadingFAQ = false;
        state.FAQList = payload;
      })
      .addCase(getFAQ.pending, (state) => {
        state.isLoadingFAQ = true;
      })
      .addCase(getFAQ.rejected, (state) => {
        state.isLoadingFAQ = false;
        toast.error("Error! Please refresh.");
      });
  },
});

export default FAQslice.reducer;
