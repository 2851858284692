import React from "react";

const Mobile = ({ FAQList }) => {
	return (
		<div className="flex flex-col items-center justify-center mt-[1rem] px-[4rem]   max-[400px]:px-[2rem]">
			<div className="text-2xl border-b border-[#8b8b8b]  py-[1rem] font-medium w-full text-center max-[400px]:text-lg max-[500px]:text-xl">
				Frequently Asked Questions
			</div>
			<div className="mt-[1rem] mb-[10rem] w-full ">
				{FAQList?.map((elem, index) => {
					return (
						<div className="mt-[2rem]">
							<div className="text-md font-semibold max-[500px]:text-sm ">
								Q.{elem?.question}
							</div>
							<div className="mt-[0.5rem] text-sm max-[500px]:text-xs">
								Ans.{elem?.answer}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Mobile;
