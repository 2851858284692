import React from "react";
import Desktop from "../../../components/CheckOutDetails/CheckoutAddress/Desktop";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  RemoveAddress,
  fetchAddresses,
} from "../../../features/address/addressSlice";
import { useState } from "react";
import Mobile from "../../../components/CheckOutDetails/CheckoutAddress/Mobile";
import Confirm from "../../../components/Notifications/Confirm";

const CheckoutAddress = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { subCatgId } = useParams();
  const { addresses } = useSelector((state) => state.address);

  const [bookingState, setBookingState] = useState({
    address: location?.state?.address,
    categoryName: location?.state?.categoryName,
    subCategoryName: location?.state?.subCategoryName,
    bookingSlot: location?.state?.bookingSlot,
    amount: location?.state?.amount,
    paymentMode: location?.state?.paymentMode,
    couponId: location?.state?.couponId,
    couponCode: location?.state?.couponCode,
    couponDiscount: location?.state?.couponDiscount,
  });
  const [isEditing, setEditing] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [isRemoving, setRemoving] = useState(null);
  const [address, setAddress] = useState({});

  const handleAddressClick = (address) => {
    handleDataChange({ ...bookingState, address: address });
  };

  // ##############################

  const handlePalceOrder = () => {
    navigate(`/check-out-details/slot/${subCatgId}#blockBack`);
  };
  // #######################################
  // const navigate = useNavigate();
  const handleEditing = (purpose, address) => {
    setPurpose(purpose);
    setAddress(address);
    setEditing((isEditing) => !isEditing);
  };

  const handleRemove = async (flag) => {
    const removeId = isRemoving;
    setRemoving(null);
    if (flag) {
      const removeResp = await dispatch(RemoveAddress(removeId));
      if (removeResp?.type?.indexOf("fulfilled") > -1) {
        dispatch(fetchAddresses());
      }
    }
  };
  const markRemoving = (flag) => {
    setRemoving(flag);
  };

  const handleDataChange = (data) => {
    setBookingState(data);
  };

  //Viewport width
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    console.log(window.innerWidth);
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    if (
      Object.keys(addresses).length === 0 ||
      Object.keys(addresses?.slice(0, 1)[0]).length === 0
    ) {
      console.log("fetching address");
      dispatch(fetchAddresses());
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchAddresses());
  }, []);

  return (
    <>
      {/* popups */}
      {isRemoving > 0 && (
        <Confirm
          question={"Are you sure you want to remove this address?"}
          sence={true}
          handleConfirmation={handleRemove}
        />
      )}
      {isDesktop ? (
        <Desktop
          subCatgId={subCatgId}
          addresses={addresses}
          location={location}
          handleDataChange={handleDataChange}
          bookingState={bookingState}
          isEditing={isEditing}
          handleEditing={handleEditing}
          purpose={purpose}
          address={address}
          handleAddressClick={handleAddressClick}
          handleRemove={markRemoving}
        />
      ) : (
        <Mobile
          subCatgId={subCatgId}
          addresses={addresses}
          location={location}
          handleDataChange={handleDataChange}
          bookingState={bookingState}
          isEditing={isEditing}
          handleEditing={handleEditing}
          purpose={purpose}
          address={address}
          handleAddressClick={handleAddressClick}
          handleRemove={handleRemove}
        />
      )}
    </>
  );
};

export default CheckoutAddress;
