import React, { useState } from "react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

const Ratings = ({ ratings, getStar, isHover }) => {
	const [hoverRating, setHoverRating] = useState(ratings);
	const handleHoverRating = (stars) => {
		console.log(hoverRating);
		if (isHover) setHoverRating(stars);
	};
	return (
		<div className="flex gap-1 ">
			{ratings >= 1 || hoverRating >= 1 ? (
				<AiFillStar
					className="text-pink-600"
					onClick={() => {
						getStar(1);
					}}
					onMouseEnter={() => {
						handleHoverRating(1);
					}}
				/>
			) : (
				<AiOutlineStar
					className="text-gray-500"
					onClick={() => {
						getStar(1);
					}}
					onMouseEnter={() => {
						handleHoverRating(1);
					}}
				/>
			)}
			{ratings >= 2 || hoverRating >= 2 ? (
				<AiFillStar
					className="text-pink-600"
					onClick={() => {
						getStar(2);
					}}
					onMouseEnter={() => {
						handleHoverRating(2);
					}}
				/>
			) : (
				<AiOutlineStar
					className="text-gray-500"
					onClick={() => {
						getStar(2);
					}}
					onMouseEnter={() => {
						handleHoverRating(2);
					}}
				/>
			)}
			{ratings >= 3 || hoverRating >= 3 ? (
				<AiFillStar
					className="text-pink-600"
					onClick={() => {
						getStar(3);
					}}
					onMouseEnter={() => {
						handleHoverRating(3);
					}}
				/>
			) : (
				<AiOutlineStar
					className="text-gray-500"
					onClick={() => {
						getStar(3);
					}}
					onMouseEnter={() => {
						handleHoverRating(3);
					}}
				/>
			)}
			{ratings >= 4 || hoverRating >= 4 ? (
				<AiFillStar
					className="text-pink-600"
					onClick={() => {
						getStar(4);
					}}
					onMouseEnter={() => {
						handleHoverRating(4);
					}}
				/>
			) : (
				<AiOutlineStar
					className="text-gray-500"
					onClick={() => {
						getStar(4);
					}}
					onMouseEnter={() => {
						handleHoverRating(4);
					}}
				/>
			)}
			{ratings >= 5 || hoverRating >= 5 ? (
				<AiFillStar
					className="text-pink-600"
					onClick={() => {
						getStar(5);
					}}
					onMouseEnter={() => {
						handleHoverRating(5);
					}}
				/>
			) : (
				<AiOutlineStar
					className="text-gray-500"
					onClick={() => {
						getStar(5);
					}}
					onMouseEnter={() => {
						handleHoverRating(5);
					}}
				/>
			)}
		</div>
	);
};

export default Ratings;
