import React from "react";
import Desktop from "../../../components/CheckOutDetails/CartView/Desktop";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import { useEffect } from "react";
import { fetchProfile } from "../../../features/Profile/ProfileSlice";
import { fetchIssue } from "../../../features/Issue/IssuesSlice";
import Mobile from "../../../components/CheckOutDetails/CartView/Mobile";

const CartView = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { subCatgId } = useParams();
  const { cartItems, totalAmount } = useSelector((store) => store.cart);
  const { subCatagory } = useSelector((store) => store.issue);
  const { addresses } = useSelector((store) => store.address);
  const { userData } = useSelector((store) => store.profile);

  // ##############################catagory name update############################################
  const [bookingState, setBookingState] = useState({
    address: location?.state?.address
      ? location?.state?.address
      : addresses?.slice(0, 1)?.[0],
    categoryName: subCatagory?.categoryName,
    subCategoryName: subCatagory?.name
      ? subCatagory?.name
      : location?.state?.subCategoryName,
    bookingSlot: location?.state?.bookingSlot,
    amount: totalAmount ? totalAmount : location?.state?.amount,
    paymentMode: location?.state?.paymentMode || "ONLINE",
    couponId: 0,
    couponCode: "",
    couponDiscount: 0,
  });

  const handleDataChange = (data) => {
    setBookingState(data);
  };

  //Viewport width
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    console.log(window.innerWidth);
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    if (!subCatagory?.id || subCatagory?.id !== subCatgId) {
      dispatch(fetchIssue(subCatgId));
    }
  }, [subCatgId]);

  useEffect(() => {
    dispatch(fetchProfile());
    window.scrollTo(0, 0);
  }, []);

  //   console.log(subCatgId);
  return (
    <div div className="pb-10">
      {isDesktop ? (
        <Desktop
          subCatgId={subCatgId}
          cartItems={cartItems}
          address={addresses?.slice(0, 1)[0]}
          profile={userData}
          location={location}
          handleDataChange={handleDataChange}
          bookingState={bookingState}
        />
      ) : (
        <Mobile
          subCatgId={subCatgId}
          cartItems={cartItems}
          address={addresses?.slice(0, 1)[0]}
          profile={userData}
          location={location}
          handleDataChange={handleDataChange}
          bookingState={bookingState}
        />
      )}
    </div>
  );
};

export default CartView;
