import React, { useState } from "react";
import CheckoutStatus from "../../CheckoutStatus";
import { useNavigate } from "react-router-dom";
import { FaTag } from "react-icons/fa6";
import { BiSolidOffer } from "react-icons/bi";
import CheckoutSidebar from "../CheckoutSidebar";

const Desktop = ({
  subCatgId,
  location,
  handleDataChange,
  bookingState,
  selectedDay,
  handleDaySelect,
  thirdDate,
  hourNow,
  selectedHour,
  handleTimeSelect,
}) => {
  return (
    <div className="w-full ">
      {/* popups */}
      {/* Address */}

      <CheckoutStatus location={location} subCatgId={subCatgId} />
      <div className="flex w-8/12 m-auto min-w-max py-10">
        {/* cart details and address */}
        <div className="py-4 px-8 w-3/5  border-r flex flex-col gap-10 items-center">
          <div className="flex  items-center w-full  ">
            <h1 className=" text-xl capitalize font-semibold">Select Slot</h1>
          </div>
          {/* slots */}
          <div
            className={` flex   items-center justify-center gap-8  font-mono ${
              selectedDay >= 0 ? " w-full" : " flex-col w-2/3"
            }`}>
            <div
              className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
                selectedDay === 0 ? " bg-black text-white" : ""
              }`}
              onClick={() => handleDaySelect(1)}>
              Today
            </div>
            <div
              className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
                selectedDay === 1 ? " bg-black text-white" : ""
              }`}
              onClick={() => handleDaySelect(2)}>
              Tommorow
            </div>
            <div
              className={`uppercase   py-2 px-4  font-semibold rounded-xl w-full text-center bg-gradient-to-bl border border-black cursor-pointer min-w-max  ${
                selectedDay === 2 ? " bg-black text-white" : ""
              }`}
              onClick={() => handleDaySelect(3)}>
              {thirdDate?.toDateString()?.slice(0, -4)}
            </div>
          </div>

          {selectedDay >= 0 && (
            <div className="w-11/12 flex flex-col gap-4 expand">
              <div className="text-sm font-semibold  text-gray-400">
                Morning
              </div>
              <div className="grid gap-8 grid-cols-3">
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 9 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 9
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(9)}>
                  09:00-10:00
                </span>
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 10 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 10
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(10)}>
                  10:00-11:00
                </span>
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 11 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 11
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(11)}>
                  11:00-12:00
                </span>
              </div>

              <div className="text-sm font-semibold  text-gray-400 mt-4">
                Afternoon
              </div>
              <div className="grid gap-8 grid-cols-3">
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 12 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 12
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(12)}>
                  12:00-01:00
                </span>
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 13 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 13
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(13)}>
                  01:00-02:00
                </span>
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 14 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 14
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(14)}>
                  02:00-03:00
                </span>
              </div>

              <div className="text-sm font-semibold  text-gray-400 mt-4">
                Evening
              </div>
              <div className="grid gap-8 grid-cols-3">
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 15 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 15
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(15)}>
                  03:00-04:00
                </span>
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 16 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 16
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(16)}>
                  04:00-05:00
                </span>
                <span
                  className={
                    "p-2 border rounded-md min-w-max  text-center " +
                    (hourNow + 1 >= 17 && selectedDay === 0
                      ? " bg-gray-200 border-gray-400 text-gray-400 cursor-not-allowed"
                      : selectedHour === 17
                      ? " border-black cursor-pointer bg-black text-white"
                      : " border-black cursor-pointer")
                  }
                  onClick={() => handleTimeSelect(17)}>
                  05:00-06:00
                </span>
              </div>
            </div>
          )}
        </div>
        {/* payment summary */}
        <div className="w-2/5">
          <CheckoutSidebar
            location={location}
            subCatgId={subCatgId}
            bookingState={bookingState}
          />
        </div>
      </div>
    </div>
  );
};

export default Desktop;
