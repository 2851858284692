import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFAQ } from "../../features/FAQ/FAQslice";

const Desktop = ({ FAQList }) => {
	return (
		<div className="flex flex-col items-center justify-center mt-[1rem] px-[16.5rem]  max-[1400px]:px-[13.5rem] max-[1300px]:px-[12rem] max-[900px]:px-[9rem] max-[750px]:px-[6rem] ">
			<div className="text-2xl border-b border-[#8b8b8b] font-medium w-full text-center py-[1rem]">
				Frequently Asked Questions
			</div>
			<div className=" mt-[1rem] mb-[10rem]   w-full ">
				{FAQList?.map((elem, index) => {
					return (
						<div className="mt-[2rem]">
							<div className="text-md font-semibold">Q. {elem?.question}</div>
							<div className="mt-[0.5rem] text-sm">Ans. {elem?.answer}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Desktop;
