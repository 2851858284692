export const signin_banner =
  "https://clapurban.blr1.digitaloceanspaces.com/1707914225102_signin_banner-min.jpg";
export const home_banner_1 =
  "https://clapurban.blr1.digitaloceanspaces.com/1711811618991_baner.jpg";
export const home_banner_2 =
  "https://clapurban.blr1.digitaloceanspaces.com/1711362288231_banner.jpg";
export const home_banner_3 =
  "https://clapurban.blr1.digitaloceanspaces.com/1711834070297_GredientBanner.svg";
export const home_banner_4 =
  "https://clapurban.blr1.digitaloceanspaces.com/1712179160600_BannerNew.svg";
export const home_banner_5 =
  "https://clapurban.blr1.digitaloceanspaces.com/1712446680880_home_banner_5.svg";
export const home_banner_6 =
  "https://clapurban.blr1.digitaloceanspaces.com/1712483575805_home_banner_6.svg";
export const mobile_home_banner_4 =
  "https://clapurban.blr1.digitaloceanspaces.com/1712275404894_Frame 38.svg";
export const menuIcon =
  "https://clapurban.blr1.digitaloceanspaces.com/1712272471446_Frame 27.svg";

export const logoEmplux =
  "https://clapurban.blr1.digitaloceanspaces.com/1713083661052_EMPLUX-removebg-preview.png";
export const empluxIcon =
  "https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png";
