import React from "react";
import CheckoutStatus from "../../CheckoutStatus";
import CheckoutSidebar from "../CheckoutSidebar";
import ManageAddress from "../../ManageAddress";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";

const Mobile = ({
  subCatgId,
  addresses,
  location,
  handleDataChange,
  bookingState,
  isEditing,
  handleEditing,
  purpose,
  address,
  handleAddressClick,
  handleRemove,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (bookingState?.address)
      navigate(`/check-out-details/slot/${subCatgId}#blockBack`, {
        state: bookingState,
      });
    else toast.warning("Please select an address");
  };
  return (
    <div className="mb-40">
      {/* Edit POPUP */}
      {isEditing && (
        <ManageAddress
          handleEditing={handleEditing}
          purpose={purpose}
          EditAddress={address}
        />
      )}
      <CheckoutStatus location={location} subCatgId={subCatgId} />
      <div className="mt-10">
        <div className="py-4 px-4 w-full border-r flex flex-col gap-4">
          <div className="flex justify-center items-center w-full  h-full ">
            <div className="flex flex-col w-11/12  h-full  ">
              {/* Heading */}
              <div className="grid grid-cols-3    items-center">
                <h1 className=" col-span-2 text-xl capitalize font-semibold">
                  Select Addresses
                </h1>
                <button
                  className=" p-2 border-2 rounded-md border-gray-400 font-mono uppercase text-primary-600 font-semibold hover:border-black hover:text-primary-max text-sm max-md:text-xs"
                  onClick={() => handleEditing("add new address")}>
                  Add Addresses
                </button>
              </div>
              {/* Address cards */}
              <div className="w-full h-full mt-4 border-b">
                {/* Address */}
                {addresses?.map((address) => {
                  return (
                    <div
                      className="relative flex flex-col w-full h-[170px] overflow-hidden  hover:scale-105 transition-all duration-100 ease-in-out mb-8 rounded-md"
                      style={{ boxShadow: "1px 1px 8px 0px rgb(0,0,0,0.3)" }}
                      key={address?.id}
                      onClick={() => handleAddressClick(address)}>
                      <div className="flex flex-col w-full px-4 pt-4">
                        {/* lable */}
                        <h1 className="mb-1 uppercase font-semibold ">
                          {address?.addressType || "Other"}
                        </h1>
                        {/* Address line*/}
                        <span className="text-sm text-gray-500">
                          {address?.address}
                        </span>

                        {/* city */}
                        <span className="text-sm text-gray-500">
                          {address?.landmark}
                        </span>
                        {/* city */}
                        <span className="text-sm text-gray-500">
                          {address?.locality}
                        </span>

                        {/* district */}
                        <span className="text-sm text-gray-500">
                          {address?.city}
                        </span>
                        {/* State */}
                        <span className="text-sm text-gray-500">
                          {address?.state + "-" + address?.pinCode}
                        </span>
                      </div>

                      <div className="absolute bottom-0 w-full h-[38px] mt-2">
                        <button
                          className="w-1/2 h-full border-r border-t text-md uppercase hover:bg-primary-600 hover:text-white  "
                          onClick={() =>
                            handleEditing("edit address", address)
                          }>
                          Edit
                        </button>
                        <button
                          className="w-1/2 h-full border-l border-t text-md uppercase hover:bg-gray-200"
                          onClick={() => handleRemove(address.id)}>
                          Remove
                        </button>
                      </div>
                      <span className="absolute top-3 right-3 rounded-md text-xs p-1 text-white   font-semibold">
                        <input
                          type="radio"
                          name="address"
                          className="h-4 w-4 accent-black "
                          value={1}
                          checked={address?.id === bookingState?.address?.id}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CheckoutSidebar
        location={location}
        subCatgId={subCatgId}
        bookingState={bookingState}
        handleDataChange={handleDataChange}
      />
      <div className="w-full py-[0.5rem] text-center fixed bottom-0 left-0 z-10 bg-white top-shadow min-w-full">
        <div
          onClick={handleNavigate}
          className="min-w-4/5 w-4/5 py-[0.5rem] text-center font-semibold text-white capitalize heading-gradient rounded-[3rem] mx-auto cursor-pointer">
          Proceed
        </div>
      </div>
    </div>
  );
};

export default Mobile;
