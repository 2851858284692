import React from "react";

const StatusBarItem = ({
  id,
  icon,
  title,
  status,
  handleNavigate,
  locations,
  currentLocation,
}) => {
  return (
    <div
      className={
        "flex items-center flex-col hover:scale-105 transition-all ease-in-out cursor-pointer justify-center relative   " +
        (locations[currentLocation] === id
          ? " text-primary-600 font-semibold"
          : " ")
      }
      onClick={() => handleNavigate(id)}>
      <div className="min-[410px]:absolute -top-[32px] text-xl max-[520px]:text-sm ">
        <img
          src={icon}
          alt=""
          className="w-[1.5rem] h-[1.5rem] max-[520px]:h-[1.2rem] max-[520px]:w-[1.2rem]  max-[410px]:min-h-[1.5rem] max-[410px]:min-w-[1.5rem] "
        />
        {/* {icon} */}
      </div>
      <div className="min-w-max max-[520px]:text-sm min-h-[1rem] ">
        <span className="max-[410px]:hidden ">{title}</span>
      </div>
      <div className="absolute top-[1.5rem] text-xs min-[720px]:whitespace-nowrap overflow-hidden overflow-ellipsis text-[#000000b5] max-[720px]:text-[8px] min-[720px]:w-[300%] w-[150%] text-center">
        {status || "-"}
      </div>
    </div>
  );
};

export default StatusBarItem;
