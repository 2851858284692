import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAxiosInterceptors } from "../utils/axios";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const path = location?.pathname;

  useAxiosInterceptors();
  const { user } = useSelector((store) => store.user);

  if (!user) {
    return <Navigate to="/login" state={{ path: path }} />;
  }
  return children;
};
export default ProtectedRoute;
