import React, { useState } from "react";
import Desktop from "../../../components/CheckOutDetails/CheckoutPayment/Desktop";
import { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postBooking } from "../../../features/Bookings/BookingSlice";
import Mobile from "../../../components/CheckOutDetails/CheckoutPayment/Mobile";
import swal from "sweetalert";
import {
  clearPaymentDetails,
  createOrder,
  makePayment,
} from "../../../features/payment/PaymentSlice";
import { razorPayKey } from "../../../utils/Constants";

const CheckoutPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subCatgId } = useParams();
  const location = useLocation();

  const [BookingDetails, setBookingState] = useState(location?.state);
  console.log(BookingDetails);
  const [mode, setMode] = useState("cash");

  const handleDataChange = (data) => {
    setBookingState(data);
  };
  const handleSendBookingtoDB = async (rpRes) => {
    const resp = await dispatch(
      postBooking({
        bookingAddressId: BookingDetails?.address?.id,
        paymentBookingId: rpRes?.razorpay_payment_id,
        categoryName: BookingDetails?.categoryName,
        subCategoryName: BookingDetails?.subCategoryName,
        bookingSlot: BookingDetails?.bookingSlot,
        amount: BookingDetails?.amount - BookingDetails?.couponDiscount,
        paymentMode: "ONLINE",
        status: "future",
        cityName: BookingDetails?.address?.city,
        couponDiscount: BookingDetails?.couponDiscount,
        couponId: BookingDetails?.couponId,
      })
    );
    if (resp?.type?.indexOf("fulfilled") > -1) {
      navigate(`/check-out-details/Completed/${resp?.payload?.id}#blockBack`);
    }
  };

  // ###############################################################################
  const { userData } = useSelector((state) => state?.profile);
  const { totalAmount } = useSelector((state) => state?.cart);
  const { order } = useSelector((state) => state.payment);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const savePaymentToDb = async (razorpayRes, amount, orderId, status) => {
    const dateNow = new Date();
    const payment = {
      orderId: orderId,
      amount: amount,
      razorpayPaymentId: razorpayRes ? razorpayRes.razorpay_payment_id : null,
      razorpayOrderId: razorpayRes ? razorpayRes.razorpay_order_id : null,
      razorpaySignature: razorpayRes ? razorpayRes.razorpay_signature : null,
      paymentDateTime: dateNow?.toISOString(),
      status: status,
      mobileNumber: userData?.mobile,
      emailId: userData?.email,
    };

    await dispatch(makePayment(payment));
  };

  var res;
  const handleConfirmBooking = async (e) => {
    e?.preventDefault();
    if (
      !mode ||
      !BookingDetails?.amount ||
      !BookingDetails?.bookingSlot ||
      !BookingDetails?.subCategoryName ||
      !BookingDetails?.address
    ) {
      alert("Some Details are Missing!");
    } else if (mode === "cash") {
      const resp = await dispatch(
        postBooking({
          bookingAddressId: BookingDetails?.address?.id,
          categoryName: BookingDetails?.categoryName,
          subCategoryName: BookingDetails?.subCategoryName,
          bookingSlot: BookingDetails?.bookingSlot,
          amount: BookingDetails?.amount - BookingDetails?.couponDiscount,
          paymentMode: mode,
          status: "FUTURE",
          cityName: BookingDetails?.address?.city,
          couponId: BookingDetails?.couponId,
          couponDiscount: BookingDetails?.couponDiscount,
        })
      );
      if (resp?.type?.indexOf("fulfilled") > -1) {
        navigate(`/check-out-details/Completed/${resp?.payload?.id}#blockBack`);
      }
    } else {
      var res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      await dispatch(createOrder(totalAmount));
    }

    // dispatch(makePayment(paymentData));
  };

  const getOptionsObject = (order) => {
    const options = {
      key: razorPayKey,
      amount: order?.amount,
      currency: order?.currency,
      name: userData?.firstName + " " + userData?.lastName,
      image: "https://www.svgrepo.com/show/261072/rupee.svg",
      description: "For Testing purpose",
      order_id: order?.id,
      handler: async (res) => {
        swal(
          "Payment Successfull!",
          `Your Payment Id is : ${res.razorpay_payment_id}`,
          "success"
        );
        await savePaymentToDb(res, totalAmount, "", "paid");
        handleSendBookingtoDB(res);
      },
      prefill: {
        name: userData?.firstName + " " + userData?.lastName,
        email: userData?.email,
        contact: userData?.mobile,
      },
      notes: {
        address: "This is test note",
      },
      theme: {
        color: "#397B8A",
        background_color: "#fff",
      },
    };
    return options;
  };

  useEffect(() => {
    if (order?.id) {
      if (order?.status === "created") {
        console.log("Order Created ", order);
        const options = getOptionsObject(order);

        const rzp = new window.Razorpay(options);

        rzp.on("payment.failed", function (response) {
          swal(
            "Oops Payment Failed!",
            `Error Description : ${response?.error?.description}`,
            "error"
          );
          savePaymentToDb(res, totalAmount, "", "failed");
        });
        rzp.open();
      } else {
        swal("Oops Order Creation Failed!", "Check backend code", "error");
      }
      dispatch(clearPaymentDetails());
    }
  }, [order]);

  // ###############################################################################

  //Viewport width
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    console.log(window.innerWidth);
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const { isLoadingBooking, status, confirmed } = useSelector(
    (store) => store.booking
  );
  const handleModeChange = (paymentmode) => {
    setMode(paymentmode);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isDesktop ? (
        <Desktop
          BookingDetails={BookingDetails}
          subCatgId={subCatgId}
          location={location}
          isLoadingBooking={isLoadingBooking}
          mode={mode}
          handleModeChange={handleModeChange}
          handleConfirmBooking={handleConfirmBooking}
          handleDataChange={handleDataChange}
        />
      ) : (
        <Mobile
          BookingDetails={BookingDetails}
          handleDataChange={handleDataChange}
          subCatgId={subCatgId}
          location={location}
          isLoadingBooking={isLoadingBooking}
          mode={mode}
          handleModeChange={handleModeChange}
          handleConfirmBooking={handleConfirmBooking}
        />
      )}
    </>
  );
};

export default CheckoutPayment;
