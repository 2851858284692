import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customFetch, { dummyFetch } from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { logoutUser } from "../user/UserSlice";
import { toast } from "sonner";

const initialState = {
  isLoadingProfile: false,
  status: null,
  error: null,
  uploadedFile: null,
  userData: {},
};

export const fetchProfile = createAsyncThunk(
  "userprofile/fetchUserProfile",
  async (_, thunkAPI) => {
    let { userId, accessToken } = getUserFromLocalStorage();

    try {
      const response = await customFetch.get(`api/profile/${userId}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      if (response) {
        return response.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const editProfile = createAsyncThunk(
  "userprofile/editProfile",
  async (profileData, thunkAPI) => {
    console.log(profileData);
    let { userId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.put(`api/profile/${userId}`, profileData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      if (resp.status === 200) {
        return {
          data: resp.data,
          status: resp.status,
          statusText: resp.statusText,
          headers: resp.headers,
          config: resp.config,
        };
      }
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const setPassword = createAsyncThunk(
  "profile/setpassword",
  async (data, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post("api/profile/password/change", data, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      // console.log(resp);
      return resp.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Import the function to get the access token from localStorage

// ########################ERROR#####################
export const updateImage = createAsyncThunk(
  "profile/updateimage",
  async (url, thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(
        `/api/profile/update-url/${userId}`,
        { id: userId, profile_image_url: url },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      thunkAPI.dispatch(fetchProfile());
      return resp?.data;
    } catch (error) {
      if (error.response) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);
// ########################Dummy#####################
export const DummyPatch = createAsyncThunk(
  "profile/dummypatch",
  async (thunkAPI) => {
    const data = JSON.stringify({ title: "Niyam's Laptop" });
    try {
      const resp = await dummyFetch.patch("products/1", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(resp);

      return resp.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearFile: (state) => {
      state.uploadedFile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.isLoadingProfile = true;
      })
      .addCase(fetchProfile.fulfilled, (state, { payload }) => {
        const data = payload.content[0];

        state.userData = data;
        state.status = null;
        state.isLoadingProfile = false;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.isLoadingProfile = false;
      })
      .addCase(editProfile.pending, (state) => {
        state.isLoadingProfile = true;
      })
      .addCase(editProfile.fulfilled, (state, { payload }) => {
        state.isLoadingProfile = false;

        state.status = payload.status;
      })
      .addCase(editProfile.rejected, (state) => {
        state.isLoadingProfile = false;
      })
      .addCase(setPassword.fulfilled, (state, { payload }) => {
        alert("Password successfully updated!");

        state.status = 200;
      }) // saurabh

      .addCase(updateImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        toast("Updated Successfully");
      })
      .addCase(updateImage.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default profileSlice.reducer;
export const { clearFile } = profileSlice.actions;
