import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProfile, fetchProfile } from "../../features/Profile/ProfileSlice";
import { Link, useNavigate } from "react-router-dom";
import { SlUserFemale, SlUser } from "react-icons/sl";
import { MdModeEdit } from "react-icons/md";
import { GrEdit } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import ProfileSidebar from "../../components/ProfileSidebar";
import { formatFormDate } from "../../utils/GlobalFunctions";
import { toast } from "sonner";

const ProfileEdit = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.profile);
  const { status } = useSelector((store) => store.profile);
  const [editingPhone, setEditing] = useState(false);

  const [firstName, setFirstName] = useState(userData?.firstName);
  const [lastName, setLastName] = useState(userData?.lastName);
  const [email, setEmail] = useState(userData?.email);
  const [gender, setGender] = useState(userData?.gender);
  const [location, setLocation] = useState(
    userData?.address?.city ? userData?.address.city : ""
  );
  const [altMobile, setAltMobile] = useState(
    userData?.altMobile ? userData?.altMobile : ""
  );
  const [mobile, setMobile] = useState(userData?.mobile);
  const [age, setAge] = useState(formatFormDate(userData?.dateOfBirth));
  const [createdDateTime, setCreatedDateTime] = useState(
    userData?.createdDateTime
  );
  console.log(age);
  const [updatedDataTime, setUpdatedDataTime] = useState(
    userData?.updatedDataTime
  );

  const handelEdit = () => {
    setEditing((editingPhone) => !editingPhone);
  };
  const handelCancel = () => {
    setMobile(userData?.mobile);
    handelEdit();
  };
  const numberChange = (e) => {
    const { value } = e.target;
    setMobile(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "dateOfBirth":
        setAge(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "altMobile":
        setAltMobile(value);
        break;
    }
  };

  const handleSubmit = () => {
    if (
      mobile === userData.mobile &&
      firstName === userData.firstName &&
      lastName === userData.lastName &&
      gender === userData.gender &&
      age === formatFormDate(userData.dateOfBirth) &&
      email === userData.email
    ) {
      toast.warning("No changes found.");
    } else {
      setLoading(true);
      dispatch(
        editProfile({
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          email: email,
          gender: gender,
          dateOfBirth: age,
          createdDateTime: createdDateTime,
          updatedDataTime: updatedDataTime,
        })
      );
    }
  };
  const genderChange = (id) => {
    const name = id;
    setGender(name === "male" ? "male" : "female");
  };
  useEffect(() => {
    setLoading(false);
    if (status === 200) {
      navigate("/profile");
    }
  }, [status]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="overflow-hidden">
      {editingPhone && (
        <div>
          <div className="fixed z-20 inset-0 flex items-center justify-center max-sm:items-end ">
            <div
              className="fixed inset-0 bg-black z-10 opacity-50 w-full h-full "
              onClick={handelEdit}></div>
            <div className="overflow-hidden opacity-100 z-40 w-1/4 h-2/5 max-sm:w-full rounded-md  bg-white min-w-max px-10 max-sm:rounded-b-none">
              <div className="relative  flex items-center justify-center  bg-white w-full h-[15%]">
                <h4 className=" m-2 text-lg font-light">
                  Change Mobile Number
                </h4>
                <button
                  type="button"
                  onClick={handelEdit}
                  className="absolute right-0 text-xl p-1 m-1 mx-2 hover:bg-gray-200 max-sm:hidden">
                  <AiOutlineClose />
                </button>
              </div>
              <div className=" h-[86%] w-full">
                <div className=" h-2/3 w-full flex justify-center items-center">
                  <div className=" relative border border-black w-5/6">
                    <span className="absolute -top-2 left-2 capitalize text-gray-400 text-xs p-0 mx-1 bg-white px-1">
                      Mobile Number
                    </span>
                    <input
                      type="text"
                      name="mobile"
                      className=" h-12 p-3 w-full "
                      placeholder="Mobile Number"
                      value={mobile}
                      onChange={numberChange}
                    />
                  </div>
                </div>
                <div className=" flex justify-center items-center h-1/3 it gap-32 w-full ">
                  <button
                    className="border-2 px-4 p-1 h-8 border-black"
                    onClick={handelCancel}>
                    Cancel
                  </button>

                  {/* Yet to add mobile verification feature */}
                  <button
                    className="border-2 px-4 p-1 h-8 border-black bg-[#000] text-white"
                    onClick={() => setEditing(false)}>
                    Change
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className=" flex justify-center w-full h-full ">
        <div className="  w-[1200px] h-full my-20  ">
          {/* Head */}
          <div className="flex flex-col w-full border-b mx-4 pb-5 border-gray-400">
            <span className="text-xl font-bold my-1">Account</span>
            <span className="text-sm  font-thin">
              {userData?.firstName + " " + userData?.lastName}
            </span>
          </div>

          {/* Profile box */}
          <div className="flex h-fit w-full ">
            <ProfileSidebar
              userData={userData}
              current={"profile"}
              className={"max-sm:hidden"}
            />

            {/* main box */}
            <div className="flex justify-center items-center w-full h-max ">
              <div className="flex justify-center min-w-max w-11/12 h-max mt-8 border border-gray-400">
                <div className="flex flex-col py-6 w-11/12 h-max text-gray-600">
                  <div className="h-[10%] w-full">
                    <div className="w-full border-b-2 p-4 text-lg font-bold">
                      Edit Details
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 items-center  justify-center border-y h-[80%]">
                    <div className="relative flex items-center h-fit w-11/12 border mt-8 mb-4 border-black p-3">
                      <div>
                        <h4 className="text-xs text-gray-400 mx-3 font-thin">
                          Mobile Number *
                        </h4>
                        <h4 className="mx-3 text-sm font-normal">{mobile}</h4>
                      </div>

                      <button
                        type="button"
                        className="absolute flex justify-center items-center gap-2 right-0 m-4 w-2/6 rounded-sm  h-10 border border-black hidden"
                        onClick={handelEdit}>
                        <MdModeEdit className="text-lg" />
                        <span>Change</span>
                      </button>
                    </div>

                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        first name
                      </span>
                      <input
                        type="text "
                        onChange={handleChange}
                        name="firstName"
                        value={firstName}
                        className="h-full p-3 w-full"
                      />
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        first name
                      </span>
                      <input
                        type="text "
                        onChange={handleChange}
                        name="lastName"
                        value={lastName}
                        className="h-full p-3 w-full"
                      />
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        email
                      </span>
                      <input
                        type="text "
                        name="email"
                        onChange={handleChange}
                        value={email}
                        placeholder="Email Address"
                        className="h-full p-3  w-full"
                      />
                    </div>
                    <div className="flex h-10 m-4  w-11/12 border border-black">
                      <button
                        type="button"
                        name="male"
                        onClick={() => genderChange("male")}
                        className={
                          gender === "male"
                            ? "w-1/2  flex gap-2 justify-center items-center border border-black bg-black text-white"
                            : "w-1/2  flex gap-2 justify-center items-center border border-black hover:bg-gray-100"
                        }>
                        <SlUser className="text-xl" />
                        <span>Male</span>
                      </button>
                      <button
                        name="female"
                        onClick={() => genderChange("female")}
                        className={
                          gender === "female"
                            ? "w-1/2  flex gap-2 justify-center items-center border border-black bg-black text-white"
                            : "w-1/2  flex gap-2 justify-center items-center border border-black hover:bg-gray-100"
                        }>
                        <SlUserFemale className="text-xl" />
                        <span>Female</span>
                      </button>
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        Date of Birth
                      </span>
                      <input
                        type="date"
                        name="dateOfBirth"
                        onChange={handleChange}
                        className="h-full p-3  w-full"
                        value={age}
                        placeholder="dateOfBirth"
                      />
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        Location
                      </span>
                      <input
                        type="text "
                        name="location"
                        onChange={handleChange}
                        className="h-full p-3  w-full"
                        value={location}
                        placeholder="City,State"
                      />
                    </div>
                    <div className=" h-10 mx-4  w-11/12 text-lg font-semibold">
                      Alternate Mobile Number
                    </div>
                    <div className="relative h-10 m-4  w-11/12 border border-black">
                      <span className="absolute -top-2 capitalize text-xs text-gray-400 px-1 left-5 bg-white">
                        Mobile number
                      </span>
                      <input
                        type="text "
                        name="altMobile"
                        onChange={handleChange}
                        className="h-full p-3  w-full"
                        value={altMobile}
                        placeholder="Mobile Number"
                      />
                    </div>
                  </div>
                  <div className="h-[10%] w-full flex border-t justify-center">
                    <button
                      className=" m-auto w-11/12 bg-[#000] text-lg text-white my-4 p-1 "
                      onClick={handleSubmit}
                      disabled={isLoading}>
                      <span>{isLoading ? "Loading...." : "Save Changes"}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
