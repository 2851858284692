import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { logoutUser } from "../user/UserSlice";

const initialState = {
  isLoadingIssue: false,
  error: null,
  issuesList: [],
  subCatagory: {},
  rateChart: [],
};

// Get API
export const fetchIssue = createAsyncThunk(
  "issue/fetchIssue",
  async (subCategoryId, thunkAPI) => {
    // const { accessToken } = getUserFromLocalStorage();
    // console.log(subCategoryId);
    try {
      // const resp = await customFetch.get(`api/issue/${subCate/goryId}`, {
      const resp = await customFetch.get(
        `/api/issue/sub-category/${subCategoryId}`
      );
      return resp.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// get rate chart
export const getRateChart = createAsyncThunk(
  "issue/getRateChart",
  async (subCategoryId, thunkAPI) => {
    try {
      const resp = await customFetch.get(
        `/api/price/rate-chart/${subCategoryId}`
      );
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Post API
// export const postIssue = createAsyncThunk(
//   "issue/postIssue",
//   async ({ subCategoryId, data }, thunkAPI) => {
//     const { accessToken } = getUserFromLocalStorage();
//     try {
//       const resp = await customFetch.post(`api/issue/${subCategoryId}`, data, {
//         headers: {
//           authorization: `Bearer ${accessToken}`,
//         },
//       });
//       return resp.data;
//     } catch (error) {
//       if (error) {
//         alert("Error Occured!");
//       }
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

// // PUT API
// export const updateIssue = createAsyncThunk(
//   "issue/updatesuncategory",
//   async ({ issueId, data }, thunkAPI) => {
//     const { accessToken } = getUserFromLocalStorage();
//     try {
//       const resp = await customFetch.put(`api/sub/category/${issueId}`, data, {
//         headers: {
//           authorization: `Bearer ${accessToken}`,
//         },
//       });
//       return resp.data;
//     } catch (error) {
//       if (error) {
//         alert("Error Occured While Updating issue!");
//       }
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );
const IssuesSlice = createSlice({
  name: "issue",
  initialState,
  reducers: {
    setLoadingIssue: (state) => {
      state.isLoadingIssue = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIssue.pending, (state) => {
        state.isLoadingIssue = true;
        state.error = null;
      })
      .addCase(fetchIssue.fulfilled, (state, { payload }) => {
        state.isLoadingIssue = false;

        // console.log(payload);
        // state.issuesList = payload.content;
        state.issuesList = payload.content[0].issues;
        const { ["issues"]: _, ...targetObject } = payload.content[0];
        state.subCatagory = targetObject;
      })
      .addCase(fetchIssue.rejected, (state) => {
        state.error = true;
        state.isLoadingIssue = false;
      })
      .addCase(getRateChart.fulfilled, (state, { payload }) => {
        state.rateChart = payload;
        state.isLoadingIssue = false;
      })
      .addCase(getRateChart.pending, (state) => {
        state.isLoadingIssue = true;
      })
      .addCase(getRateChart.rejected, (state) => {
        state.isLoadingIssue = false;
      });
  },
});
export default IssuesSlice.reducer;
export const { setLoadingIssue } = IssuesSlice.actions;
