import React from "react";
import ObjectLoader from "../ObjectLoader";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillTrash2Fill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";

const SubCategoryPopup = ({
  category,
  handleSubcategory,
  isLoadingSubCatg,
  SubCategories,
  CategoryId,
}) => {
  return (
    <div className=" fixed inset-0 flex items-center justify-center z-50  bg-[#00000052] overflow-auto ">
      <div className=" border-gray-50 bg-white pb-4  rounded-md border-2  h-max w-fit opacity-100 shadow-inner relative no-scrollbar transform transition-all duration-75 ease-out popup">
        {/* Close Button */}
        <span
          className="absolute -top-10 right-0 bg-white rounded-full  cursor-pointer"
          onClick={() => {
            handleSubcategory(false);
          }}>
          <AiOutlineClose className="m-2" />
        </span>
        <div className="relative bg-white w-full flex justify-center items-center shadow-gray-300 shadow-md">
          <span className="text-xl from-neutral-300 p-3 ">
            {category?.filter((item) => item.id == CategoryId)[0]?.categoryName}
          </span>
        </div>

        <div className="w-[30rem] max-h-[25rem] overflow-auto  bg-white">
          {!isLoadingSubCatg ? (
            <div className=" h-full expand min-h-[100px] ">
              {SubCategories.length > 0 ? (
                SubCategories?.map(({ id, name, iconUrls }) => {
                  return (
                    <Link
                      to={{
                        pathname: `./service/${id}`,
                        // state: { data: title },
                      }}
                      key={id}>
                      <div className="flex items-center gap-8 px-4 py-2  bg-slate-50 hover:bg-gray-100">
                        <img
                          src={iconUrls}
                          alt={name}
                          className="object-fill h-14 w-16 rounded-md my-2"
                        />
                        <span className="relative flex items-center w-full py-6 border-b">
                          <label htmlFor="" className=" font-thin text-md">
                            {name}
                          </label>
                          <IoIosArrowForward className="absolute right-0 text-gray-400 text-xl" />
                        </span>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div className=" expand h-[150px] bg-white flex items-center justify-center p-6 text-[#939393] font-mono">
                  <div className=" h-full w-full border-2 border-dashed border-[#939393] flex items-center justify-between flex-col p-4">
                    <BsFillTrash2Fill className="w-8 h-8" />
                    <h1>Data will be added sortly</h1>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="w-full h-[200px] bg-white">
              <ObjectLoader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubCategoryPopup;
