import React, { useState } from "react";
import Ratings from "../Ratings";
import { Link } from "react-router-dom";
import PartnerDetails from "../PartnerDetails";
import Otp from "../Otp";
import { useSelector } from "react-redux";
import { MdOutlineVerifiedUser } from "react-icons/md";

import { RiCustomerService2Line } from "react-icons/ri";
import { MdMoreTime } from "react-icons/md";

const Desktop = ({
  ratings,
  getStar,
  bookingId,
  booking,
  bookingSlot,
  generateInvoice,
  setCancelling,
}) => {
  console.log(booking);
  return (
    <div className="flex flex-col h-max justify-center bg-white ">
      {/* <div className=" relative flex flex-col justify-center w-11/12 m-auto  p-4 loat-right top-0 bg-[#4169e1] text-white">
        <h1 className="text-2xl ">Booking Details</h1>
        <span>{`${
          BookingDetails?.firstName + " " + BookingDetails?.lastName
        } | ${BookingDetails?.mobile}`}</span>
        <div className=" absolute float-right align-middle right-8 hover:bg-gray-300 hover:text-black text-3xl ">
          <Link to="/my-bookings">
            <AiOutlineRollback className="m-1" />
          </Link>
        </div>
      </div> */}

      {/* ############################################################# */}

      <div className="relative w-2/3 min-h-[380px] bg-[#f5f5f1] m-auto flex flex-col items-center gap-4 h-full pt-8 pb-8 mt-8 mb-8  border-x-4 border-[#f5f5f1]">
        {/* Corner Icons */}
        <div className="absolute right-0 top-0 flex flex-col items-center justify-center p-3 text-sm gap-2 expand">
          <button className="w-full h-1/3   flex  justify-center items-center gap-3">
            <div className="rounded-full p-2 bg-white shadow-sm w-12 h-12 transition ease-in-out hover:scale-110 duration-100">
              <RiCustomerService2Line className="w-full h-full" />
            </div>
          </button>
          {booking?.status?.toLowerCase() === "ongoing" && (
            <button className="w-full h-1/3 relative flex items-center justify-center">
              <div className="absolute w-8 h-8 rounded-full bg-[#ff2833]  animate-ping z-0"></div>
              <div className="rounded-full m-0  shadow-sm w-12 h-12 flex justify-center items-center font-bold bg-[#ff2833] text-white  z-10 transition ease-in-out hover:scale-110 duration-100">
                SOS
              </div>
            </button>
          )}
          {booking?.status?.toLowerCase() === "future" && (
            <button className="w-full h-1/3" onClick={setCancelling}>
              <div className="rounded-full m-0  shadow-sm w-12 h-12 flex justify-center items-center font-bold bg-[#ff2833] text-white text-xs transition ease-in-out hover:scale-110 duration-100">
                Cancel
              </div>
            </button>
          )}
        </div>
        <div className="w-full  flex flex-col justify-center items-center gap-4 py-4">
          <PartnerDetails
            status={booking?.status}
            partnerDetail={booking?.partner}
            bookingId={bookingId}
          />

          <div className="flex flex-col justify-center items-center">
            <span className="text-xl font-medium">{booking?.categoryName}</span>
            <span className="text-lg ">{booking?.subCategoryName}</span>
            {/* <span className="text-sm text-gray-500">
              {booking?.cardConfirmData
                ?.map((elm) => elm.issueName)
                ?.join(", ")}
            </span> */}
          </div>
          {/* Slot detail */}
          <div
            className={
              "w-9/12 flex  py-4 px-4 bg-primary-600 text-white gap-2 rounded-sm relative items-center"
            }>
            <div className=" h-10">
              <MdMoreTime className="  text-2xl  text-[#fff]  " />
            </div>
            <div className="flex flex-col">
              <span className="text-lg font-medium">Booking Slot</span>
              <span className="text-xs font-medium ml-1 text-[#d9f8f1]">
                {bookingSlot}
              </span>
            </div>
            {/* Status */}
            <div className="absolute right-10 flex flex-col items-center justify-between  gap-[6px]  ">
              <div
                className={
                  " text-white font-bold  px-3 py-2 text-xs rounded-lg w-full text-center border-2 border-white uppercase "
                }>
                {booking?.status?.toLowerCase() === "future"
                  ? "Confirmed"
                  : booking?.status?.toLowerCase() === "ongoing"
                  ? "In-Progress"
                  : booking?.status}
              </div>
            </div>
          </div>
          {/* OTP */}
          {(booking?.status?.toLowerCase() === "future" ||
            booking?.status?.toLowerCase() === "ongoing") && (
            <div className=" flex flex-col relative justify-center  py-3 px-4 w-9/12 m-auto border border-gray-200 bg-white gap-2">
              <div className="flex flex-col max-lg:flex-row gap-2 max-lg:items-end">
                <h1 className="text-xl font-semibold font-mono mb-1 max-lg:text-3xl ">
                  OTP
                </h1>
                <h4 className="text-sm   text-gray-600 pb-2 w-1/4 max-lg:w-max ">
                  Will be shared with partner
                </h4>
              </div>
              <div className="absolute right-8 max-lg:static">
                {booking?.status?.toLowerCase() === "future" ? (
                  <Otp otp={booking?.bookingOtp} />
                ) : (
                  <div className="flex items-center justify-center text-cyan-600 gap-1 text-sm font-semibold">
                    <MdOutlineVerifiedUser className="w-6 h-6" /> OTP Verified
                    Successfully
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Issue details */}
        <div className=" w-full bg-white rounded-md py-5 ">
          <div className="h-full w-9/12  m-auto flex gap-4 flex-col">
            <h1 className="text-xl font-semibold mb-2 capitalize">
              Services Booked
            </h1>
            {booking?.cardConfirmData?.map((elm, index) => {
              return (
                <Link
                  to={"/service/" + elm?.subCategoryId}
                  key={index}
                  className="  rounded-sm text-black p-4 "
                  style={{ boxShadow: "2px 4px 8px 4px rgba(0, 0, 0, 0.1)" }}>
                  <div className="flex flex-col  ">
                    <div className="flex flex-col justify-center relative">
                      <span className="text-lg   font-semibold text-gray-500 ">
                        <span className="  uppercase pr-2">Issue</span>
                        <span className="   text-xl font-bold pr-2">:</span>
                        <span className="">{elm?.issueName}</span>
                      </span>
                      <span className=" pb-2 text-sm font-semibold text-gray-500 font-mono">
                        <span className="  uppercase pr-4">Price</span>:
                        <span className="pl-1 text-[#f08432]">
                          <span className="text-[#3a85c6] px-1 ">₹</span>
                          {elm?.issuePrice}
                        </span>
                      </span>
                      {/* Duration */}
                      <span className="absolute  right-[2%] font-mono text-gray-500 flex gap-1 items-center justify-center">
                        <span className="text-xs text-center capitalize">
                          Service <br /> duration
                        </span>
                        <span className="   text-xl font-bold pr-2">:</span>

                        {elm?.issueDuration + " mins"}
                      </span>
                    </div>
                    <span className="w-2/3  text-gray-600 border-t-2 border-dashed min-h-[50px]">
                      {elm?.issueDescription}
                    </span>
                  </div>
                  {/* <div className="flex items-center justify-between px-4 ">
                    <span className="flex items-center">
                      {elm?.issueName}
                      <div className="border-r-2 h-4 mx-1 border-black"></div>
                      {"₹" + elm?.issuePrice}
                    </span>
                  </div> */}
                </Link>
              );
            })}
          </div>
        </div>

        {/* Address */}
        <div className=" w-full bg-white rounded-md py-5 ">
          <div className="w-9/12 m-auto">
            <h1 className="text-xl font-semibold mb-2 capitalize">
              Service address
            </h1>
            <div className="flex flex-col gap-1 ">
              <span className="text-lg pb-2  ">
                <span className="pr-4 border-r-2 border-gray-400 uppercase">
                  {booking?.addresses?.fullName}
                </span>
                <span className="pl-4">{booking?.user?.mobile}</span>
              </span>
              <span className="w-2/3  text-gray-600 border-t-2 border-dashed capitalize">
                <div>
                  {booking?.addresses?.address + ", " + booking?.cityName}
                </div>
                <div>
                  {booking?.addresses?.state +
                    "-" +
                    booking?.addresses?.pinCode}
                </div>
              </span>
            </div>
          </div>
        </div>
        {/* Payment details */}
        <div className=" w-full  bg-white rounded-md    py-5 h-fit">
          <div className="w-9/12 m-auto">
            <div className="capitalize flex items-center w-full border-b pb-3">
              <h1 className="w-1/2 text-xl font-mono">Total Order Price</h1>
              <span className="font-mono  flex items-center justify-end w-1/2 text-lg font-bold ">
                {/* <FaIndianRupeeSign /> */}
                {"₹" + booking?.amount}
              </span>
            </div>
            <div className="w-full my-6 pl-4 pr-3 bg-gray-100 h-2/6 flex items-center">
              <span className="w-1/2  ">Payment Method</span>
              <span className="w-1/2   flex justify-end ">
                <button className="float-right py-2 font-mono font-semibold uppercase">
                  {booking?.paymentMode}
                </button>
              </span>
            </div>
            <div className="w-full h-fit py-3 ">
              <button
                onClick={generateInvoice}
                className="border bg-gradient-to-tr uppercase font-semibold from-[#cda804] to-[#fccf06] w-full h-fit p-1 text-white ">
                Get tax Invoice
              </button>
            </div>
          </div>
        </div>
        {/* Order Is */}
        <div className="w-full  bg-white py-4 font-medium text-gray-500">
          <div className="w-9/12 m-auto">
            Booking Id <strong>#{booking?.orderId}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
