import axios from "axios";
import { useDispatch } from "react-redux";
import { clearStore } from "../features/user/UserSlice";

const live = axios.create({
  // baseURL: "http://64.227.149.177:8080/",
  baseURL: "https://api.emplux.com/",
  withCredentials: true,
});
const local = axios.create({
  baseURL: "http://192.168.211.166:8080/",
  withCredentials: true,
});

const customFetch = live; // local
// Custom hook to set up interceptors
export const useAxiosInterceptors = () => {
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(clearStore());
  };

  // Interceptor for customFetch
  customFetch.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
      if (error.response && error.response.status === 401) {
        Logout(); // Call logout function on status 401
      }
      return Promise.reject(error); // Reject the promise with the error
    }
  );
};

export const dummyFetch = "";
export default customFetch;
