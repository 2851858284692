import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, setPassword } from "../../features/Profile/ProfileSlice";
import { Link, useNavigate } from "react-router-dom";
import ProfileSidebar from "../../components/ProfileSidebar";
import { getUserFromLocalStorage } from "../../utils/localStorage";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setNewPassword] = useState({
    userId: getUserFromLocalStorage().userId,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [disabled, setDisabled] = useState(true);

  const { isLoading, status, userData } = useSelector((state) => state.profile);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPassword({ ...password, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.oldPassword.length == 0 || password.newPassword.length == 0) {
      alert("Please fill all the details!");
    } else {
      const { userId, oldPassword, newPassword } = password;
      dispatch(
        setPassword({
          userId: userId,
          oldPassword: oldPassword,
          newPassword: newPassword,
        })
      );
    }
  };
  useEffect(() => {
    if (Object.keys(userData).length == 0) dispatch(fetchProfile());
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (
      password.newPassword === password.confirmPassword &&
      password.newPassword &&
      password.confirmPassword
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [password]);
  useEffect(() => {
    if (status == 200) {
      navigate("/profile");
    }
  }, [status]);

  return (
    <div className="  overflow-hidden">
      <div className=" flex justify-center w-full h-fit">
        <div className="  w-[900px] h-fit my-20  ">
          {/* Head */}
          <div className="flex flex-col w-full border-b mx-4 pb-5 border-gray-400">
            <span className="text-xl font-bold my-1">Account</span>
            <span className="text-sm  font-thin">
              {userData?.firstName + " " + userData?.lastName}
            </span>
          </div>

          {/* Profile box */}
          <div className="flex h-fit w-full ">
            {/* Side options */}
            <ProfileSidebar userData={userData} current={"privacy"} />

            {/* main details */}
            <div className="flex justify-center items-center w-full h-full">
              <div className="flex justify-center   w-11/12 h-full mt-8 border border-gray-400">
                <div className="flex flex-col items-center py-6 px-24 w-full  text-gray-600">
                  <div className="flex justify-center border-b-2 w-full mb-10">
                    <h1 className="font-bold text-md my-6 ">
                      Set New Password
                    </h1>
                  </div>

                  {/* Main Details */}
                  <form className="h-full w-full " onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-12 min-w-max text-md items-center w-full ">
                      {/* Old password */}
                      <div className=" relative border border-black w-full">
                        <span className="absolute -top-2 left-2 capitalize text-gray-400 text-xs p-0 mx-1 bg-white">
                          Old Password
                        </span>
                        <input
                          type="text"
                          name="oldPassword"
                          className=" h-12 p-3 w-full "
                          onChange={handleChange}
                          value={password.oldPassword}
                          placeholder="Current Password"
                        />
                      </div>
                      {/* new Password */}
                      <div className=" relative border border-black w-full">
                        <span className="absolute -top-2 left-2 capitalize text-gray-400 text-xs p-0 mx-1 bg-white">
                          New Password
                        </span>
                        <input
                          type="text"
                          name="newPassword"
                          className=" h-12 p-3 w-full "
                          onChange={handleChange}
                          value={password.newPassword}
                          placeholder="New Password"
                        />
                      </div>
                      {/* confirm password */}
                      <div className=" relative border border-black w-full">
                        <span className="absolute -top-2 left-2 capitalize text-gray-400 text-xs p-0 mx-1 bg-white">
                          Confirm Password
                        </span>
                        <input
                          type="text"
                          name="confirmPassword"
                          className=" h-12 p-3 w-full "
                          onChange={handleChange}
                          value={password.confirmPassword}
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center w-full mt-10">
                      <button
                        type="submit"
                        className="flex justify-center w-full  p-1 my-6 bg-[#000] rounded-sm"
                        disabled={disabled}>
                        <span
                          className={
                            "font-normal text-lg uppercase text-white " +
                            (disabled && " opacity-80 cursor-not-allowed")
                          }>
                          Submit
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
