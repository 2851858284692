import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { logoutUser } from "../user/UserSlice";
import { fetchBookingDetails } from "../BookingDetails/BookingDetailSlice";

const initialState = {
  isLoadingBooking: false,
  status: null,
  confirmed: null,
  bookings: [],
  allratings: [],
  invoice: "",
};

export const getBooking = createAsyncThunk(
  "booking/getbooking",
  async (thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.get(`api/bookings/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return resp?.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

export const postBooking = createAsyncThunk(
  "booking/postBooking",
  async (data, thunkAPI) => {
    console.log(data);
    const { userId, accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.post(`/api/bookings/${userId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      thunkAPI.dispatch(getBooking());
      return resp?.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

// Cancel Booking
export const cancelBooking = createAsyncThunk(
  "booking/cancelbooking",
  async ({ bookingId, couponId }, thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();

    try {
      const resp = await customFetch.post(
        `/api/bookings/cancel/${bookingId}/${userId}/${couponId || "0"}`,
        { status: "CANCELLED" },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (resp) {
        thunkAPI.dispatch(getBooking());

        return { bookingId: bookingId, data: resp?.data };
      }
    } catch (error) {
      if (error.response.status === 401) {
        // thunkAPI.dispatch(logoutUser());
        console.log(error);
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

//Rating GET API
export const getRating = createAsyncThunk(
  "booking/getRating",
  async (thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/product/review/all`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return resp?.data;
    } catch (error) {
      if (error) {
        console.log("Error occured while fetching reviews and ratings!");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

//Rating POST API
export const postRating = createAsyncThunk(
  "booking/postRating",
  async ({ data, bookingId }, thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();
    console.log(data);
    try {
      const resp = await customFetch.post(
        `api/product/review/create/${bookingId}/${userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Error posting review");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

//Rating partner POST API
export const postPartnerRating = createAsyncThunk(
  "booking/postPartnerRating",
  async ({ data, bookingId, partnerId }, thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();
    console.log(data);
    try {
      const resp = await customFetch.post(
        `api/partner/review/create/${bookingId}/${userId}/${partnerId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Error posting review for partner");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);
//get invoice
export const getInvoice = createAsyncThunk(
  "booking/getInvoice",
  async ({ bookingId, orderId }, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(
        "/api/home/sub-section/generate-invoice?" + "orderId=" + bookingId,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const fileUrl = resp?.data?.url;

      const fileResponse = await fetch(fileUrl);
      const fileBlob = await fileResponse.blob();

      // Step 3: Create a link element to trigger the download
      const fileName = `${orderId || "Invoice" + bookingId}.pdf`; // You can set the desired file name here
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(fileBlob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      return resp?.data;
    } catch (error) {
      if (error) {
        console.log("Error occured while fetching invoice!");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

//booking status

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setLoadingBooking: (state, { payload }) => {
      state.isLoadingBooking = payload;
    },
    clearStatus: (state) => {
      state.status = null;
      state.confirmed = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postBooking.fulfilled, (state, { payload }) => {
        // state.bookings.push(payload);
        state.status = 201;
        state.confirmed = payload.id;
        state.isLoadingBooking = false;
      })
      .addCase(postBooking.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(postBooking.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(getBooking.fulfilled, (state, { payload }) => {
        state.bookings = payload?.content;
        // state.totalPages = payload.totalPages;
        // alert(payload.message);
        state.isLoadingBooking = false;
      })
      .addCase(getBooking.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(getBooking.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(cancelBooking.fulfilled, (state, { payload }) => {
        state.isLoadingBooking = false;
      })
      .addCase(cancelBooking.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(cancelBooking.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      // .addCase(updateCategory.fulfilled, (state) => {
      //   alert("Updated Successfully!");
      //   state.isLoadingBooking = false;
      // })
      // .addCase(updateCategory.pending, (state) => {
      //   state.isLoadingBooking = true;
      // })
      // .addCase(updateCategory.rejected, (state) => {
      //   state.isLoadingBooking = false;
      // });

      .addCase(postRating.fulfilled, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(postRating.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(postRating.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(getRating.fulfilled, (state, { payload }) => {
        state.isLoadingBooking = false;
        state.allratings = payload?.content;
      })
      .addCase(getRating.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(getRating.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(postPartnerRating.fulfilled, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(postPartnerRating.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(postPartnerRating.rejected, (state) => {
        state.isLoadingBooking = false;
      })
      .addCase(getInvoice.fulfilled, (state, { payload }) => {
        state.isLoadingBooking = false;
        state.invoice = payload;
      })
      .addCase(getInvoice.pending, (state) => {
        state.isLoadingBooking = true;
      })
      .addCase(getInvoice.rejected, (state) => {
        state.isLoadingBooking = false;
      });
  },
});
export default BookingSlice.reducer;
export const { setLoadingBooking, clearStatus } = BookingSlice.actions;
