import React from "react";

const PrivacyPolicy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-4">Last Updated: 15th Aug 2024</p>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
        <p>
          We collect personal information such as your name, contact details,
          and payment information when you use our services. This information is
          used to process your orders, manage your account, and improve our
          services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">
          How We Use Your Information
        </h2>
        <p>Your personal information is used to:</p>
        <ul className="list-disc pl-5 flex flex-col text-white">
          <li>Provide and manage our services.</li>
          <li>Process payments and refunds.</li>
          <li>Communicate with you about your orders and services.</li>
          <li>Improve our platform and services.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">
          Sharing Your Information
        </h2>
        <p>
          We do not share your personal information with third parties except as
          necessary to provide our services, comply with legal obligations, or
          protect our rights.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">
          Security of Your Information
        </h2>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or method of electronic storage is
          completely secure.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Your Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal
          information. You may also object to the processing of your personal
          information in certain circumstances.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and we will notify you of significant changes
          through the website or via email.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Contact Information</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <p>Phone: +91 91133 88758</p>
        <p>Email: help.emplux@gmail.com</p>
        <p>
          Address: #Eco Mitra Road, Near Pinnacle Apartment, Virgo Nagar Post,
          Hirandhalli, Bangalore-49, India
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
