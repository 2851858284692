import React, { useState } from "react";

import BookingCardsMobile from "./BookingCardsMobile";

const Mobile = ({ past, present, future, handlePayment }) => {
  const [bookingStatus, setBookingStatus] = useState("future");
  return (
    <div className="h-max w-full">
      <div className="flex flex-col items-center w-full min-h-screen border-t-2 pt-2 mb-6 justify-center px-[4.85vw] overflow-visible">
        <div className="flex w-full text-[#000] mt-4 text-sm border-[1px] border-[#00000030] rounded-lg max-sm:text-xs">
          <button
            className={
              " capitalize   py-4  w-1/3 text-center  " +
              (bookingStatus === "future"
                ? " bg-primary-600 text-white rounded-lg  "
                : "hover:bg-neutral-100 ")
            }
            onClick={() => setBookingStatus("future")}>
            Upcoming
          </button>
          <button
            className={
              " capitalize   py-4  w-1/3 text-center  " +
              (bookingStatus === "ongoing"
                ? " bg-primary-600 text-white rounded-lg  "
                : "hover:bg-neutral-100 ")
            }
            onClick={() => setBookingStatus("ongoing")}>
            Ongoing
          </button>

          <button
            className={
              " capitalize   py-4  w-1/3 text-center  " +
              (bookingStatus === "completed"
                ? " bg-primary-600 text-white rounded-lg  "
                : "hover:bg-neutral-100 ")
            }
            onClick={() => setBookingStatus("completed")}>
            Past
          </button>
        </div>
        {/* {" Future Bookings"} */}

        <div className="w-full    h-full py-[0.875rem]  min-w-max min-h-screen gap-[1.125rem] flex flex-col ">
          {(bookingStatus === "future"
            ? future
            : bookingStatus === "ongoing"
            ? present
            : past
          )?.map((element, index) => {
            const {
              id,
              status,
              bookingSlot,
              amount,
              subCategoryName,
              categoryName,
              paymentMode,
              orderId,
            } = element?.confirmBooking?.slice(0, 1)[0];
            return (
              <BookingCardsMobile
                paymentMethod={paymentMode}
                status={status}
                catagory={categoryName}
                subcatagory={subCategoryName}
                date_time={bookingSlot}
                amount={amount}
                key={index}
                Id={id}
                OrderId={orderId}
                handlePayment={handlePayment}
              />
            );
          })}
          {/* <BookingsCard
          paymentMethod={paymentMode}
            catagory="Laptop"
            subcatagory="Windows repair"
            date_time="Tue, 10th Jan, 7:30 AM"
          /> */}
        </div>
        {/* Ongoing bookings */}
      </div>
    </div>
  );
};

export default Mobile;
