import React from "react";
import { useSelector } from "react-redux";
import { TbLoader2 } from "react-icons/tb";
import { GiSevenPointedStar } from "react-icons/gi";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";

const ProductAndroid = ({
  id,
  subcategoryId,
  title,
  warranty,
  rating,
  reviews,
  price,
  description,
  url,
  isAdded,
  duration,
  handleProductClick,
  clickedId,
}) => {
  const { isLoadingCart } = useSelector((store) => store.cart);

  const handleProductUpdate = (id) => {
    if (!isLoadingCart) handleProductClick(id);
  };

  return (
    <div className="w-full flex items-center mt-5  justify-between pr-6 border-[1px] border-[#0000000c] rounded h-[8.75rem] shadow">
      <div className="max-[430px]:flex-1 min-[430px]:pl-[2rem]  ">
        <img src={url} alt={title} className="h-[4rem] w-[5rem] m-auto" />
      </div>
      <div className="min-w-[10rem] w-2/5">
        <h1 className="font-bold capitalize">{title}</h1>
        <div className="w-full flex items-center justify-between text-secondary-50">
          <div className="flex items-center">
            {rating}
            <FaStar className="h-4 w-4 text-[#EEBF00]" />
          </div>
          <span>{duration + " mins"}</span>
        </div>
        <div className="flex items-baseline gap-2">
          <span>Starting At </span>
          <span className="font-bold text[1rem] text-primary-max">
            ₹ {price}
          </span>
        </div>
        <div
          className=" flex justify-center text-sm mt-2 text-white font-semibold cursor-pointer w-[100%] right-0  h-[2.125rem]  "
          onClick={() => handleProductUpdate(id)}>
          {isAdded ? (
            <div className="flex items-center justify-center  h-full bg-primary-40 rounded-lg w-full">
              {isLoadingCart && id === clickedId ? (
                <TbLoader2 className=" text-xl animate-spin " />
              ) : (
                "Remove"
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center h-full bg-primary-600 rounded-lg w-full">
              {isLoadingCart && id === clickedId ? (
                <TbLoader2 className="text-xl animate-spin" />
              ) : (
                "Add"
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductAndroid;
