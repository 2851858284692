import Product from "../Product";
import React, { useState } from "react";
import { FaClipboardList } from "react-icons/fa";
import { FiStar } from "react-icons/fi";
import { BsChevronDoubleDown } from "react-icons/bs";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Cart from "../Cart";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Encode } from "../../utils/GlobalFunctions";

const Desktop = ({
  subCatgId,
  issuesList,
  subCatg,
  handleProductClick,
  clickedId,
}) => {
  // console.log(subCatg);

  const [isOpenFaqs, setOpen] = useState(false);
  const [isOpenQuestions, setOpenQuestions] = useState(false);
  const { cartItems } = useSelector((state) => state.cart);
  const handelOpenClick = () => {
    setOpen((isOpenFaqs) => !isOpenFaqs);
  };

  const handelQuestionClick = () => {
    setOpenQuestions((isOpenQuestions) => !isOpenQuestions);
  };
  return (
    <div className="w-full h-fit mb-10 pt-[1.25rem] max-[1200px]:pt-[1rem] max-[900px]:pt-[0.5rem]">
      {/* popups */}

      {/* Head */}
      <div className="flex justify-center items-center mb-2 pb-4 pl-[5rem]  pr-[5rem] max-[1024px]:pl-[1.5rem] max-[1024px]:pr-[3.25rem]">
        <div className="text-4xl font-bold w-1/2 px-2 ">
          {subCatg?.name}
          <div className="flex gap-2 items-center text-[0.875rem]  font-normal  ">
            <span className="flex bg-[#eebf00] text-white rounded-md items-center gap-1  text-sm px-2">
              {subCatg?.rating} <FiStar />
            </span>
            <span className="text-[#EEBF00] uppercase">
              {subCatg?.review} bookings
            </span>
          </div>
        </div>
        <span className="w-1/2">
          <Link
            to={`/service/rate-chart/${Encode(subCatgId)}`}
            className="flex flex-col items-center float-right hover:scale-105 delay-50 transition ease-in-out duration-200 hover:shadow-sm p-2 ">
            <FaClipboardList className="m-auto text-3xl " />
            <span className="capitalize font-thin text-sm font-mono">rate</span>
          </Link>
        </span>
      </div>
      {/* Hero image */}

      <img
        src={
          subCatg?.thumbnailUrls ||
          "https://clapurban.blr1.digitaloceanspaces.com/1712179160600_BannerNew.svg"
        }
        alt=""
        className="my-2 rounded-b-xl max-h-[28rem] object-cover w-11/12 m-auto"
      />

      <div className=" flex justify-center h-fit mb-10 mx-[8rem] gap-16 max-[1200px]:mx-[2rem] max-[1100px]:gap-4 max-md:flex-col max-md:items-center">
        <div className="flex flex-col gap-2 items-center justify-center min-w-[25rem] w-[55%]  my-10 py-3 max-md:w-11/12">
          {issuesList?.map((element, index) => {
            const {
              id,
              issueName,
              issueReview,
              issueRating,
              issueDuration,
              issuePrice,
              issueDescription,
              issueIconUrl,
            } = element;

            return (
              <Product
                key={index}
                id={id}
                subcategoryId={subCatgId}
                title={issueName}
                warranty={6}
                rating={issueRating}
                reviews={issueReview}
                price={issuePrice}
                description={issueDescription}
                url={issueIconUrl}
                isAdded={cartItems?.find(
                  (item) => item?.issueName == issueName
                )}
                duration={issueDuration}
                clickedId={clickedId}
                handleProductClick={handleProductClick}
              />
            );
          })}
          {/* proceed */}
          {/* {cartItems?.length > 0 && (
            <Link
              to={`/check-out-details/cart/${subCatgId}`}
              className="sticky bottom-10 left-1/2 transform -translate-x-1/2 uppercase font-medium px-4 py-1 bg-primary-600 rounded-lg z-10 text-white tracking-wider  popup w-1/2">
              Proceed to checkout
            </Link>
          )} */}
        </div>

        {/* cart */}
        <div className="w-[45%]  max-md:w-11/12">
          {/*############################## EDIT ############################## */}

          {/*Side bar */}
          <div className="sticky top-20 mb-8 border-6 mt-20">
            {/* Cart */}
            <Cart location={"Services"} />
            {/* Availabale offers */}
            <div className="relative rounded-md mt-8  w-full  border border-gray-400 hidden">
              <div className="no-scrollbar overflow-auto h-[200px] mb-5 ">
                {/* Offers card */}
                {/* Will go inside map */}
                <div className="flex items-center gap-4 px-4 mx-2 hover:bg-gray-100 ">
                  <img
                    src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_category/w_873,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/luminosity/1653033997914-7727d4.jpeg"
                    alt="title"
                    className="object-fill h-12 w-12 rounded-md my-2"
                  />
                  <span className=" flex items-center w-full py-3 border-b">
                    <span lassName=" font-thin text-md">
                      <div>Some text for offer</div>
                      <div className="text-sm text-gray-400">Offer details</div>
                    </span>
                  </span>
                </div>

                <div className="flex items-center gap-4 px-4 mx-2 hover:bg-gray-100 ">
                  <img
                    src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_category/w_873,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/luminosity/1653033997914-7727d4.jpeg"
                    alt="{title}"
                    className="object-fill h-12 w-12 rounded-md my-2"
                  />
                  <span className="relative flex items-center w-full py-3 border-b">
                    <span lassName=" font-thin text-md">
                      <div>Some text for offer</div>
                      <div className="text-sm text-gray-400">Offer details</div>
                    </span>
                  </span>
                </div>

                <div className="flex items-center gap-4 px-4 mx-2 hover:bg-gray-100 ">
                  <img
                    src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_category/w_873,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/luminosity/1653033997914-7727d4.jpeg"
                    alt="{title}"
                    className="object-fill h-12 w-12 rounded-md my-2"
                  />
                  <span className="relative flex items-center w-full py-3 border-b">
                    <span lassName=" font-thin text-md">
                      <div>Some text for offer</div>
                      <div className="text-sm text-gray-400">Offer details</div>
                    </span>
                  </span>
                </div>

                <div className="flex items-center gap-4 px-4 mx-2 hover:bg-gray-100 ">
                  <img
                    src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_category/w_873,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/luminosity/1653033997914-7727d4.jpeg"
                    alt="{title}"
                    className="object-fill h-12 w-12 rounded-md my-2"
                  />
                  <span className="relative flex items-center w-full py-3 border-b">
                    <span lassName=" font-thin text-md">
                      <div>Some text for offer</div>
                      <div className="text-sm text-gray-400">Offer details</div>
                    </span>
                  </span>
                </div>
                {/* dummy data  */}
              </div>
              <div className="absolute w-full -bottom-3 ">
                <div className=" flex items-center gap-2  m-auto w-fit capitalize  animate-bounce bg-white  px-1">
                  scrool for more
                  <BsChevronDoubleDown className=" text-md mt-1" />
                </div>
              </div>
            </div>

            {/* Frrequentry Asked Questions */}
            {/* TODO: hidden forcefully */}
            <div className="  m-auto rounded-md border-gray-200 mt-16 p-4 border-y w-full transition hover:shadow-md duration-150 bg-gray-200 hidden">
              <button
                className="relative flex items-center w-full"
                onClick={handelOpenClick}>
                <span>Frequently Asked Questions</span>
                {isOpenFaqs ? (
                  <AiOutlineUp className=" absolute right-3" />
                ) : (
                  <AiOutlineDown className="absolute right-3" />
                )}
              </button>
            </div>
            {/* Questions  */}
            {isOpenFaqs && (
              <div className="p-3 rounded-md  mt-2  w-full transition hover:shadow-md duration-150 ">
                <button
                  className="relative flex items-center  border-y py-2 w-full"
                  onClick={handelQuestionClick}>
                  <span>Some XYZ question</span>
                  {isOpenQuestions ? (
                    <AiOutlineUp className=" absolute right-3" />
                  ) : (
                    <AiOutlineDown className="absolute right-3" />
                  )}
                </button>
                {isOpenQuestions && (
                  <div>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Hic, autem voluptatem, fuga sapiente ipsa unde provident
                    dolore sint officia repellendus sit molestiae animi
                    perspiciatis! Ullam delectus id aut culpa qui?
                  </div>
                )}
              </div>
            )}
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default Desktop;
