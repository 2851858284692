import React, { useEffect } from "react";
import { BsFillTrash2Fill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoCartOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import {
  deleteCart,
  getCart,
  setTotalAmount,
} from "../features/Cart/CartSlice";
import { useState } from "react";

const CartMobile = ({ location }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subCatgId } = useParams();

  const { issuesList } = useSelector((state) => state.issue);
  const { totalAmount, cartItems } = useSelector((state) => state.cart);
  const [isEmpty, setEmpty] = useState(cartItems?.length > 0 ? false : true);
  const [isCartItemsVisible, setCartItemsVisible] = useState(false);

  const handleRemove = (cartId) => {
    dispatch(deleteCart(cartId));
  };

  const toggleCartItems = () => {
    setCartItemsVisible((prev) => !prev);
  };
  const checkEmptyCart = () => {
    if (cartItems?.length === 0) {
      return true;
    } else {
      const target = cartItems?.slice(0, 1)[0]?.issueId;
      const isTargetPresent = issuesList?.some((obj) => obj.id === target);
      return !isTargetPresent;
    }
  };

  useEffect(() => {
    setEmpty(checkEmptyCart());
    // if (!cartItems?.length > 0 && location !== "Services") {
    //   navigate(`/service/${subCatgId}`);
    // }
  }, [cartItems]);

  useEffect(() => {
    dispatch(getCart());
  }, []);
  return (
    <div
      className="fixed  min-h-max w-full mt-5 bottom-0 left-0  bg-white rounded-t-xl z-30 "
      style={{ boxShadow: "0px -4px 25px 0px #00000040" }}>
      {!isEmpty && isCartItemsVisible && (
        <div
          className=" fixed top-0 left-0 w-full h-full -z-10"
          onClick={() => setCartItemsVisible(false)}></div>
      )}
      {/* Items */}
      {!isEmpty && isCartItemsVisible && (
        <div className=" flex flex-col gap-2 py-2 z-30">
          {cartItems?.map((element) => {
            const {
              id,
              issueName,
              issueDuration,
              issuePrice,
              issueDescription,
              active,
            } = element;
            if (active) {
              return (
                <div className="relative flex items-center  border h-14 shadow mx-2 rounded-md expand-horizontal">
                  <span
                    className=" absolute  top-0 left-0 bg-secondary-20 rounded-tl-md rounded-br-md  p-1 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleRemove(id)}>
                    <RxCross2 className="text-sm text-secondary-max" />
                  </span>
                  <span className="absolute left-10 ">{issueName}</span>
                  <span className="absolute left-[80%]">Rs. {issuePrice}</span>
                </div>
              );
            }
          })}
        </div>
      )}

      {/* View details Button  */}
      {!isEmpty && (
        <div
          className={
            " gap-2 flex   items-center justify-center   text-white bg-white py-4 cursor-pointer rounded-t-lg border-t z-30 "
          }>
          <div className="max-[500px]:w-[85%] w-[95%] flex justify-between bg-primary-20 h-[3rem] gap-2 rounded  items-center text-primary-max pl-6 min-w-max">
            <div
              className="w-full min-w-max flex items-center max-xs:text-sm   gap-1"
              onClick={toggleCartItems}>
              <span className="text-[#00000080]">Added :</span>
              <span className="underline ">
                {cartItems?.length +
                  (cartItems?.length > 1 ? " items " : " item ") +
                  " |"}
              </span>
              <span className="font-semibold">₹ {totalAmount}</span>
              {/* <span>Rs.{totalAmount}</span> */}
            </div>
            <div
              className="font-bold  tracking-wider ml-2 cursor-pointer text-white bg-primary-max h-full w-full flex items-center justify-center rounded-r max-xs:text-sm"
              onClick={() => {
                if (!isEmpty) navigate(`/check-out-details/cart/${subCatgId}`);
              }}>
              Proceed
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartMobile;
