import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Desktop from "../../components/Help/Desktop";
import Mobile from "../../components/Help/Mobile";

const Help = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return <>{isDesktop ? <Desktop /> : <Mobile />}</>;
};

export default Help;
