import React, { useState, useEffect } from "react";
import Desktop from "../../components/MyBooking/Desktop";
import Mobile from "../../components/MyBooking/Mobile";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus, getBooking } from "../../features/Bookings/BookingSlice";
import {
  clearPaymentDetails,
  createOrder,
  makePayment,
  postPaymentOnBooking,
  updatePaymentToBooking,
} from "../../features/payment/PaymentSlice";
import swal from "sweetalert";
import { fetchProfile } from "../../features/Profile/ProfileSlice";
import { data } from "autoprefixer";
import { env, razorPayKey } from "../../utils/Constants";

const MyBookings = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const { bookings } = useSelector((state) => state?.booking);
  const { userData } = useSelector((state) => state?.profile);

  const dispatch = useDispatch();
  const updateMedia = () => {
    setDesktop(window?.innerWidth > 720);
  };

  //#########################payment########################

  const [bookingId, setBookingId] = useState(null);
  const [userAmount, setUserAmount] = useState(null);
  const { order } = useSelector((state) => state.payment);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  var res;
  const handlePayNow = async (Id, amount) => {
    setUserAmount(amount);
    setBookingId(Id);
    console.log(bookingId + " " + userAmount);
    var res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    await dispatch(createOrder(amount));

    // dispatch(makePayment(paymentData));
  };

  const savePaymentToDb = async (razorpayRes, amount, orderId, status) => {
    console.log(bookingId + " " + userAmount);
    const dateNow = new Date();
    const payment = {
      orderId: bookingId,
      amount: userAmount,
      razorpayPaymentId: razorpayRes ? razorpayRes.razorpay_payment_id : null,
      razorpayOrderId: razorpayRes ? razorpayRes.razorpay_order_id : null,
      razorpaySignature: razorpayRes ? razorpayRes.razorpay_signature : null,
      paymentDateTime: dateNow?.toISOString(),
      status: status,
      mobileNumber: userData?.mobile,
      emailId: userData?.email,
    };

    await dispatch(makePayment(payment));
  };

  const getOptionsObject = (order) => {
    const options = {
      key: razorPayKey,
      amount: env === order?.amount,
      currency: order?.currency,
      name: userData?.firstName + " " + userData?.lastName,
      image: "https://www.svgrepo.com/show/261072/rupee.svg",
      description: "For Testing purpose",
      order_id: order?.id,
      handler: async (res) => {
        swal(
          "Payment Successfull!",
          `Your Payment Id is : ${res.razorpay_payment_id}`,
          "success"
        );
        await dispatch(
          updatePaymentToBooking({
            bookingId: bookingId,
            data: {
              paymentBookingId: res?.razorpay_payment_id,
              paymentMode: "ONLINE",
            },
          })
        );
        savePaymentToDb(res, order?.amount, bookingId, "Paid");
      },
      prefill: {
        name: userData?.firstName + " " + userData?.lastName,
        email: userData?.email,
        contact: userData?.mobile,
      },
      notes: {
        address: "This is test note",
      },
      theme: {
        color: "#3399cc",
      },
    };
    return options;
  };

  useEffect(() => {
    if (order?.id) {
      console.log(order);
      if (order?.status === "created") {
        console.log("Order Created ", order);
        const options = getOptionsObject(order);

        const rzp = new window.Razorpay(options);

        rzp.on("payment.failed", function (response) {
          savePaymentToDb(res, order?.amount, bookingId, "Failed");

          swal(
            "Oops Payment Failed!",
            `Error Description : ${response?.error?.description}`,
            "error"
          );
        });
        rzp.open();
      } else {
        swal("Oops Order Creation Failed!", "Check backend code", "error");
      }
      dispatch(clearPaymentDetails());
    }
  }, [order]);

  //  ############################################################
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window?.removeEventListener("resize", updateMedia);
  });
  useEffect(() => {
    // if (!Object.keys(bookings).length) {
    dispatch(getBooking());
    dispatch(clearStatus());
    dispatch(fetchProfile());
    window.scrollTo(0, 0);
    // }
  }, []);

  return (
    <>
      {isDesktop ? (
        <Desktop
          handlePayment={handlePayNow}
          future={bookings?.filter(
            (item) =>
              item?.confirmBooking?.slice(0, 1)[0]?.status?.toLowerCase() ===
              "future"
          )}
          present={bookings?.filter(
            (item) =>
              item?.confirmBooking?.slice(0, 1)[0]?.status?.toLowerCase() ===
              "ongoing"
          )}
          past={bookings?.filter(
            (item) =>
              item?.confirmBooking?.slice(0, 1)[0]?.status?.toLowerCase() !==
                "ongoing" &&
              item?.confirmBooking?.slice(0, 1)[0]?.status?.toLowerCase() !==
                "future"
          )}
        />
      ) : (
        <Mobile
          handlePayment={handlePayNow}
          future={bookings?.filter(
            (item) =>
              item?.confirmBooking?.slice(0, 1)[0]?.status?.toLowerCase() ===
              "future"
          )}
          present={bookings?.filter(
            (item) =>
              item?.confirmBooking?.slice(0, 1)[0]?.status?.toLowerCase() ===
              "ongoing"
          )}
          past={bookings?.filter(
            (item) =>
              item?.confirmBooking?.slice(0, 1)[0]?.status?.toLowerCase() !==
                "ongoing" &&
              item?.confirmBooking?.slice(0, 1)[0]?.status?.toLowerCase() !==
                "future"
          )}
        />
      )}
    </>
  );
};

export default MyBookings;
