import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ManageAddress from "../../components/ManageAddress";
import {
  RemoveAddress,
  fetchAddresses,
} from "../../features/address/addressSlice";
import ProfileSidebar from "../../components/ProfileSidebar";
import { fetchProfile } from "../../features/Profile/ProfileSlice";
import { Toaster, toast } from "sonner";
import { Toast } from "react-bootstrap";
import Confirm from "../../components/Notifications/Confirm";

const SavedAddress = () => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.profile);
  const { addresses } = useSelector((state) => state.address);
  const [isEditing, setEditing] = useState(false);
  const [isRemoving, setRemoving] = useState(null);
  const [purpose, setPurpose] = useState("");
  const [address, setAddress] = useState({});

  // const navigate = useNavigate();
  const handleEditing = (purpose, address) => {
    setPurpose(purpose);
    setAddress(address);
    setEditing((prev) => !prev);
  };

  const handleRemove = async (flag) => {
    const removeId = isRemoving;
    setRemoving(null);
    if (flag) {
      const removeResp = await dispatch(RemoveAddress(removeId));
      if (removeResp?.type?.indexOf("fulfilled") > -1) {
        dispatch(fetchAddresses());
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(userData).length === 0) {
      dispatch(fetchProfile());
    }
    dispatch(fetchAddresses());
  }, []);
  return (
    <div className=" bg-slate-50 overflow-hidden">
      {/* Removing pop up */}
      {isRemoving > 0 && (
        <Confirm
          question={"Are you sure you want to remove this address?"}
          sence={true}
          handleConfirmation={handleRemove}
        />
      )}
      {/* Edit POPUP */}
      {isEditing && (
        <ManageAddress
          handleEditing={handleEditing}
          purpose={purpose}
          EditAddress={address}
        />
      )}
      <div className=" flex justify-center w-full h-fit">
        <div className="  w-[900px] h-fit my-20  max-md:my-5  ">
          {/* Head */}
          <div className="flex flex-col w-full border-b mx-4 pb-5 border-gray-400">
            <span className="text-xl font-bold my-1">Account</span>
            <span className="text-sm  font-thin">
              {userData?.firstName + " " + userData?.lastName}
            </span>
          </div>

          {/* Profile box */}
          <div className="flex h-fit w-full ">
            {/* Side options */}
            <ProfileSidebar userData={userData} current={"address"} />
            {/* main details */}
            <div className="flex justify-center items-center w-full  h-full py-6 ">
              <div className="flex flex-col w-full px-2 h-full  ">
                {/* Heading */}
                <div className="grid grid-cols-3   w-11/12 m-auto items-center py-2">
                  <h1 className=" col-span-2 sm:text-xl capitalize font-semibold">
                    Saved Addresses
                  </h1>
                  <button
                    className=" p-2 border-2 rounded-md border-gray-400 font-mono uppercase text-primary-600 font-semibold hover:border-black hover:text-primary-max max-md:text-sm max-sm:p-1 "
                    onClick={() => handleEditing("add new address")}>
                    <span className=" text-sm">
                      Add <span className="max-md:hidden ">Addresses</span>
                    </span>
                  </button>
                </div>
                {/* Address cards */}
                <div className="w-full  min-h-[40vh] overflow-y-auto no-scrollbar   pt-4">
                  {/* Address */}
                  {addresses?.map((address, index) => {
                    return (
                      <div
                        className="relative flex flex-col h-[170px] w-11/12 m-auto shadow-custom-sm overflow-hidden border-t border-gray-100 rounded-md mb-8"
                        key={index}>
                        <div className="flex flex-col w-full px-4 pt-4">
                          {/* lable */}
                          <h1 className="mb-1 uppercase font-semibold ">
                            {address?.addressType || "Other"}
                          </h1>
                          {/* Address line*/}
                          <span className="text-sm text-gray-500 font-medium tracking-wider">
                            {address?.fullName}
                          </span>
                          <span className="text-sm text-gray-500">
                            {address?.address}
                          </span>

                          {/* city */}
                          <span className="text-sm text-gray-500">
                            {address?.landmark}
                          </span>
                          {/* city */}
                          <span className="text-sm text-gray-500">
                            {address?.locality}
                          </span>

                          {/* district */}
                          <span className="text-sm text-gray-500">
                            {address?.city}
                          </span>
                          {/* State */}
                          <span className="text-sm text-gray-500">
                            {address?.state + "-" + address?.pinCode}
                          </span>
                        </div>

                        <div className="absolute bottom-0 w-full border h-[38px] mt-2">
                          <button
                            className="w-1/2 h-full border-r text-md uppercase hover:bg-primary-600 hover:text-white"
                            onClick={() =>
                              handleEditing("edit address", address)
                            }>
                            Edit
                          </button>
                          <button
                            className="w-1/2 h-full border-l text-md uppercase hover:bg-gray-200"
                            onClick={() => setRemoving(address?.id)}>
                            Remove
                          </button>
                        </div>
                        {address?.active && (
                          <span className="absolute bg-gray-400 top-3 right-3 rounded-md text-xs p-1 text-white  font-semibold">
                            Default
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedAddress;
