import React from "react";

import { VscCircleSmallFilled } from "react-icons/vsc";
import { useState } from "react";
import { useEffect } from "react";
const ObjectLoader = () => {
  const [loadDot, setLoadDot] = useState(0);
  useEffect(() => {
    const intervalLD = setInterval(() => {
      setLoadDot((prevDot) => (prevDot + 1) % 8);
    }, 300);
    return () => clearInterval(intervalLD);
  });
  return (
    <div className="w-full h-full flex items-center justify-center ">
      <div className="w-12 h-12 flex items-center justify-center animate-spin">
        {loadDot >= 0 && (
          <div className="  absolute  w-12 h-12  ">
            <VscCircleSmallFilled class="  text-[#000] " />
          </div>
        )}
        {loadDot >= 1 && (
          <div className="  absolute rotate-[45deg]  w-12 h-12  ">
            <VscCircleSmallFilled class="  text-[#000] " />
          </div>
        )}
        {loadDot >= 2 && (
          <div className="  absolute rotate-[90deg]  w-12 h-12  ">
            <VscCircleSmallFilled class="  text-[#000] " />
          </div>
        )}
        {loadDot >= 3 && (
          <div className="  absolute rotate-[135deg]  w-12 h-12  ">
            <VscCircleSmallFilled class="  text-[#000] " />
          </div>
        )}
        {loadDot >= 4 && (
          <div className="  absolute rotate-[180deg]  w-12 h-12  ">
            <VscCircleSmallFilled class="  text-[#000] " />
          </div>
        )}
        {loadDot >= 5 && (
          <div className="  absolute rotate-[225deg]  w-12 h-12  ">
            <VscCircleSmallFilled class="  text-[#000] " />
          </div>
        )}
        {loadDot >= 6 && (
          <div className="  absolute rotate-[270deg]  w-12 h-12  ">
            <VscCircleSmallFilled class="  text-[#000] " />
          </div>
        )}
        {loadDot >= 7 && (
          <div className="  absolute rotate-[315deg]  w-12 h-12  ">
            <VscCircleSmallFilled class="  text-[#000] " />
          </div>
        )}
      </div>
    </div>
  );
};

export default ObjectLoader;
