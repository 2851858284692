import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../features/Profile/ProfileSlice";
import { Link } from "react-router-dom";
import ProfileSidebar from "../../components/ProfileSidebar";
import Success from "../../components/Notifications/Success";

const Profile = () => {
  const dispatch = useDispatch();
  const { status } = useSelector((store) => store.profile);

  const { userData } = useSelector((state) => state.profile);

  // status popup
  const [isStatusNotification, setStatus] = useState(false);
  const handleStatusNotification = (value) => {
    setStatus(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (status === 200) {
      handleStatusNotification(true);
    }

    dispatch(fetchProfile());
  }, [dispatch, status]);

  return (
    <div className=" bg-slate-50 w-full ">
      {isStatusNotification && (
        <Success
          message={"Update Successfull!"}
          handleStatusNotification={handleStatusNotification}
        />
      )}
      <div className=" flex justify-center w-full h-fit">
        <div className="  w-[900px] h-fit my-20 max-sm:my-8 max-[900px]:w-full">
          {/* Head */}
          <div className="flex flex-col w-full border-b px-4 pb-5 border-gray-400">
            <span className="text-xl font-bold my-1">Account</span>
            <span className="text-sm  font-thin">
              {userData?.firstName + " " + userData?.lastName}
            </span>
          </div>

          {/* Profile box */}
          <div className="flex h-fit w-full ">
            {/* Side options */}
            <ProfileSidebar userData={userData} current={"profile"} />

            {/* main details */}
            <div className="flex justify-center items-center w-full h-full">
              <div className="flex justify-center max-w-max  w-11/12 h-full mt-8 border border-gray-400 max-md:border-0 max-md:mt-0 max-md:w-full ">
                <div className="flex flex-col items-center py-6 px-24 w-full  text-gray-600 max-lg:px-10 max-sm:p-4">
                  <div className="flex justify-center border-b-2 w-full mb-10">
                    <h1 className="font-bold text-md my-6 ">Profile Details</h1>
                  </div>

                  {/* Main Details */}
                  <div className=" gap-8  text-md grid grid-cols-2 max-lg:mx-2 max-sm:gap-x-2 max-sm:text-sm max-[500px]:text-xs ">
                    <div>Full Name</div>
                    <div className="ml-auto ">
                      {userData?.firstName + " " + userData?.lastName}
                    </div>
                    <div>Mobile number</div>
                    <div className="ml-auto ">{userData?.mobile}</div>
                    <div>Email</div>
                    <div className="ml-auto ">{userData?.email}</div>
                    <div>Date of Birth</div>
                    <div className="ml-auto ">
                      {userData?.dateOfBirth
                        ? new Date(userData?.dateOfBirth).toDateString(
                            "dd-MM-yyyy"
                          )
                        : "- not added -"}
                    </div>
                    <div>Gender</div>
                    <div className="ml-auto ">
                      {userData?.gender ? userData.gender : "-not added-"}
                    </div>
                    <div>Alternate Mobile</div>
                    <div className="ml-auto ">
                      {userData?.alterPhone
                        ? userData.alterPhone
                        : "- not added -"}
                    </div>

                    <div>Location</div>
                    <div className="ml-auto ">
                      {userData.address?.city
                        ? userData.address?.city + "," + userData.address?.state
                        : "- not added -"}
                    </div>
                    {/* 
                    <div>Mobile number</div>
                    <div className="ml-auto ">+9199999999999</div> */}
                  </div>
                  <div className="flex justify-center w-full mt-10">
                    <Link
                      to="./edit"
                      className="flex justify-center w-full  p-1 my-6 bg-secondary-600 rounded-sm">
                      <span className="font-normal text-lg uppercase text-white">
                        edit
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
