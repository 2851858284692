import { noUserImg } from "../Images";

export const env = "live"; //"local","live"
export const cryptoSecretKey = "mySecretKey123";
export const themeColor = "#065668";
export const defaultProfileMale =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRMgSCbiuqPPFMzNmdj_K56xzcOhj0ZN4JaxFBJbXWdA&s";

export const noUserIcon = noUserImg;
export const razorPayKey =
  env === "live" ? "rzp_live_NM4GBPgp6syAES" : "rzp_test_WD6M4HZ1re5KIw";
