import React from "react";
import { FiTwitter } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { SiInstagram } from "react-icons/si";
import { FaWhatsapp } from "react-icons/fa";
import { FiLinkedin } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { PiCopyright } from "react-icons/pi";
import { BiLogoPlayStore } from "react-icons/bi";
import { GrAppleAppStore } from "react-icons/gr";
import { empluxIcon } from "../utils/Urls";

const Footer = () => {
  const location = useLocation();
  const hash = location?.hash?.slice(1);
  return (
    <div
      id="footer"
      className={`relative outfit flex bg-black overflow-hidden w-full  ${
        hash === "blockBack" && " hidden"
      }`}>
      <div className="text-[14px] whitespace-nowrap absolute text-white bottom-2 left-5 ">
        © 2023 - 2024 EMPLUX. All rights reserved.
      </div>

      <div className="flex gap-20 text-white p-10 w-full justify-center tracking-wider  max-[1100px]:gap-[10rem] max-md:flex-col max-md:gap-10">
        <div className="flex flex-col justify-between ">
          <div className="flex flex-col items-start  ">
            <Link
              to={"/"}
              className="   text-center flex items-center gap-2   ">
              <img
                src={empluxIcon}
                alt=""
                className="w-16 object-fill m-auto "
              />

              <span className="uppercase text-primary-sharp font-semibold text-3xl">
                EMPLUX
              </span>
            </Link>
            {/* <div className="flex flex-col gap-[1.5rem] max-xs:gap-2 px-[1rem] opacity-0">
              <div className=" flex gap-[0.5rem] items-center border h-[3.5rem] w-[10rem] px-[1rem] rounded-xl ">
                <BiLogoPlayStore className="text-4xl" />
                <div className="text-sm">Download on Play Store</div>
              </div>
              <div className="flex gap-[0.5rem] items-center border h-[3.5rem] w-[10rem] px-[1rem] rounded-xl">
                <GrAppleAppStore className="text-4xl" />
                <div className="text-sm">Download on App Store</div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex gap-20  max-xl:flex-col max-xl:text-sm max-xs:gap-6 ">
          <div className="flex  gap-10 max-sm:gap-4 justify-between max-xs:flex-col max-xs:gap-6 ">
            <div className="flex flex-col">
              <h4 className="  border-b w-[10.6rem] max-xs:w-full tracking-widest max-xl:w-[8rem] xl:text-[18px] font-bold pb-[1.3rem] max-xs:pb-2 max-xs:mb-3 mb-[2rem] border-primary-600 ">
                Company
              </h4>
              <div className="flex flex-col gap-[1.5rem] max-xs:gap-2">
                <Link className="hover:underline" to={""}>
                  About Us
                </Link>
                <Link className="hover:underline" to={"/term-condition"}>
                  Terms and Conditions
                </Link>
                <Link className="hover:underline" to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
                <Link className="hover:underline" to={"/refunds/cancellations"}>
                  Cancellation Policy
                </Link>
              </div>
            </div>
            <div className="flex flex-col ">
              <h4 className=" xl:text-[18px] font-bold border-b w-[10.6rem] max-xs:w-full tracking-widest max-xl:w-[8rem] pb-[1.3rem] max-xs:pb-2 max-xs:mb-3 mb-[2rem] border-primary-600">
                For customers
              </h4>
              <div className="flex flex-col gap-[1.5rem] max-xs:gap-2">
                <Link className="hover:underline" to="/reviews">
                  Reviews
                </Link>
                <Link className="hover:underline" to={"/#category"}>
                  Categories
                </Link>
                <Link className="hover:underline" to={"/_"}>
                  Blog
                </Link>
                <Link to={""}>Contact Us</Link>
              </div>
            </div>
          </div>
          <div className="flex  gap-10 max-sm:gap-4 justify-between max-xs:flex-col max-xs:gap-6">
            <div className="flex flex-col">
              <h4 className=" xl:text-[18px] font-bold border-b w-[10.6rem] max-xs:w-full tracking-widest max-xl:w-[8rem] pb-[1.3rem] max-xs:pb-2 max-xs:mb-3 mb-[2rem] border-primary-600">
                For partners
              </h4>
              <a
                href="http://142.93.215.182:9000/register/individual"
                target="_blank"
                className="transition-all ease-in-out hover:font-bold hover:underline duration-300">
                Register as a professional
              </a>
            </div>

            <div className="flex flex-col gap-3">
              <h4 className=" xl:text-[18px] font-bold border-b w-[10.6rem] max-xs:w-full tracking-widest max-xl:w-[8rem] pb-[1.3rem] max-xs:pb-2 max-xs:mb-3 mb-[2rem] border-primary-600">
                Follow Us On
              </h4>
              <div className="flex flex-col text-3xl gap-[1.5rem]">
                <div className="flex gap-[2rem]">
                  <FiTwitter />
                  <FiFacebook />
                  <SiInstagram />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
