import React, { useEffect, useState } from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { useParams } from "react-router-dom";
import { Decode } from "../../../utils/GlobalFunctions";
import { useDispatch } from "react-redux";
import { getRateChart } from "../../../features/Issue/IssuesSlice";

const RateChart = () => {
  const dispatch = useDispatch();
  const { subCatgId } = useParams();
  const subCatId = Decode(subCatgId);
  //Viewport width
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    console.log(window.innerWidth);
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  //   #####################################
  useEffect(() => {
    dispatch(getRateChart(subCatId));
  }, []);
  return (
    <>
      {isDesktop ? (
        <Desktop subCatId={subCatId} />
      ) : (
        <Mobile subCatId={subCatId} />
      )}
    </>
  );
};

export default RateChart;
