import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCart } from "../features/Cart/CartSlice";
import address from "../Images/Address.svg";
import Cart from "../Images/cart.svg";
import Bookings from "../Images/Bookings.svg";
import payment from "../Images/Payment.svg";
import Confirm from "../Images/Confirm.svg";
import StatusBarItem from "./StatusBarItem";

const CheckoutStatus = ({ subCatgId, location }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems } = useSelector((store) => store.cart);

  const currentLocation = location?.pathname?.slice(0, -1);
  const bookingState = location?.state;

  // Slot logic
  const slotDate = new Date(bookingState?.bookingSlot || null);

  const options = { hour: "2-digit", minute: "2-digit" };
  const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
    slotDate
  );

  const dateDifference = () => {
    const today = new Date();
    const tommorow = new Date();
    const yesterday = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      today.getDate() === slotDate.getDate() &&
      today.getMonth() === slotDate.getMonth() &&
      today.getFullYear() === slotDate.getFullYear()
    ) {
      return 0;
    } else if (
      tommorow.getDate() === slotDate.getDate() &&
      tommorow.getMonth() === slotDate.getMonth() &&
      tommorow.getFullYear() === slotDate.getFullYear()
    ) {
      return 1;
    } else if (
      yesterday.getDate() === slotDate.getDate() &&
      yesterday.getMonth() === slotDate.getMonth() &&
      yesterday.getFullYear() === slotDate.getFullYear()
    ) {
      return -1;
    } else {
      return 999;
    }
  };
  const bookingSlot =
    dateDifference() === 0
      ? "Today" + ", " + formattedTime
      : dateDifference() === 1
      ? "Tommorow" + ", " + formattedTime
      : dateDifference() === -1
      ? "Yesterday"
      : slotDate.toDateString() + " " + formattedTime;

  const paths = {
    1: `/check-out-details/cart/${subCatgId}`,
    2: `/check-out-details/address/${subCatgId}#blockBack`,
    3: `/check-out-details/slot/${subCatgId}#blockBack`,
    4: `/check-out-details/payment/${subCatgId}#blockBack`,
    5: `/check-out-details/Completed/${subCatgId}#blockBack`,
  };
  const locations = {
    "/check-out-details/cart/": 1,
    "/check-out-details/address/": 2,
    "/check-out-details/slot/": 3,
    "/check-out-details/payment/": 4,
    "/check-out-details/Completed/": 5,
  };
  const handleNavigate = (path) => {
    if (locations[currentLocation] > path) {
      if (path === 1) {
        navigate(paths[path], {
          state: { ...location?.state, address: null, bookingSlot: null },
        });
      } else navigate(paths[path], { state: location?.state });
    }
  };
  useEffect(() => {
    dispatch(getCart(subCatgId));
  }, []);
  useEffect(() => {
    if (!cartItems || cartItems?.length === 0) {
      navigate(`/service/${subCatgId}`);
    }
  }, [cartItems]);
  return (
    <div className="w-[62%] mx-auto mt-[10vh] max-xs:mt-[4vh] max-[720px]:w-[90%]">
      <div className="flex w-full items-center  ">
        <StatusBarItem
          icon={Cart}
          status={
            cartItems?.length +
            (cartItems?.length > 1 ? " items selected" : " item selected")
          }
          title={"Cart"}
          handleNavigate={handleNavigate}
          locations={locations}
          currentLocation={currentLocation}
          id={1}
        />
        <div className="w-full border-dashed border-b-2 h-0 "></div>
        <StatusBarItem
          icon={address}
          status={
            bookingState?.address?.pinCode
              ? (
                  bookingState?.address?.addressType +
                  ", " +
                  bookingState?.address?.city +
                  "-" +
                  bookingState?.address?.pinCode
                )?.toUpperCase()
              : null
          }
          title={"Address"}
          handleNavigate={handleNavigate}
          locations={locations}
          currentLocation={currentLocation}
          id={2}
        />
        <div className="w-full border-dashed border-b-2 h-0"></div>
        <StatusBarItem
          icon={Bookings}
          status={bookingState?.bookingSlot ? bookingSlot : null}
          title={"Booking slot"}
          handleNavigate={handleNavigate}
          locations={locations}
          currentLocation={currentLocation}
          id={3}
        />
        <div className="w-full border-dashed border-b-2 h-0"></div>
        <StatusBarItem
          icon={payment}
          status={"-"}
          title={"Payment"}
          handleNavigate={handleNavigate}
          locations={locations}
          currentLocation={currentLocation}
          id={4}
        />
        <div className="w-full border-dashed border-b-2 h-0"></div>
        <StatusBarItem
          icon={Confirm}
          status={"-"}
          title={"Confirm"}
          handleNavigate={handleNavigate}
          locations={locations}
          currentLocation={currentLocation}
          id={5}
        />
      </div>
    </div>
  );
};

export default CheckoutStatus;
