import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { toast } from "sonner";

const initialState = {
  isLoadingCoupon: false,
  couponList: [],
  redeem: {},
};

//GET API for FAQs
export const fetchNonExpiredCoupon = createAsyncThunk(
  "coupon/fetchNonExpiredCoupon",
  async (thunkAPI) => {
    try {
      const { accessToken } = getUserFromLocalStorage();
      const resp = await customFetch.get(`/api/coupon/nonExpired`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return resp?.data;
    } catch (error) {
      if (error?.resp) {
        toast.error("Please Refresh!");
      }
      return thunkAPI.rejectWithValue(error.resp);
    }
  }
);

// post ewdeem coupon
export const redeemCoupon = createAsyncThunk(
  "coupon/redeemCoupon",
  async ({ couponId }, thunkAPI) => {
    console.log(couponId);
    const { userId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.post(
        "/api/user-coupons/redeem",
        { userId: userId, couponId: couponId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return resp?.data;
    } catch (error) {
      if (error) {
        toast.error("This coupon is not applicable for you.");
      }
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

const CouponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setLoadingCoupon: (state, { payload }) => {
      state.isLoadingCoupon = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchNonExpiredCoupon.pending, (state) => {
        state.isLoadingCoupon = true;
      })
      .addCase(fetchNonExpiredCoupon.fulfilled, (state, { payload }) => {
        state.isLoadingCoupon = false;
        state.couponList = payload;
      })
      .addCase(fetchNonExpiredCoupon.rejected, (state) => {
        state.isLoadingCoupon = false;
      })
      .addCase(redeemCoupon.pending, (state) => {})
      .addCase(redeemCoupon.fulfilled, (state, { payload }) => {
        state.isLoadingCoupon = false;
        state.redeem = payload;
      })
      .addCase(redeemCoupon.rejected, (state) => {
        state.isLoadingCoupon = false;
      });
  },
});

export default CouponSlice.reducer;
