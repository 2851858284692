import React, { useState } from "react";
import rentalhome1 from "../../Images/rental_home1.svg";
import rentalhome2 from "../../Images/rental_home2.svg";
import rentalhome3 from "../../Images/Young man standing and looking away.svg";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineLaptop } from "react-icons/md";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const ExploreMore = () => {
  const { user } = useSelector((store) => store.user);
  console.log(user);
  const navigate = useNavigate();
  const handleViewDetailNavigate = (path) => {
    if (user) {
      navigate(`${path}`);
    } else {
      navigate(`/nouser${path}`);
    }
  };
  return (
    <div className="outfit">
      <div className="flex items-center justify-center h-[3.5rem] border-t border-[#5f5f5f75] shadow-lg gap-[6rem] font-semibold">
        <div className="flex justify-center ">Electronics</div>
        <div className="flex justify-center ">Home Appliances</div>
        <div className="flex justify-center ">Mobile Phones</div>
        <div className="flex justify-center ">Camera</div>
        <div className="flex justify-center ">Musical instruments</div>
        <div className="flex justify-center ">Entertainment</div>
      </div>

      <div className="flex flex-col items-center justify-center  mt-[4rem] gap-[3rem] outfit">
        <div className="flex items-center justify-center gap-10">
          <IoIosArrowForward className=" rotate-180 text-[#7e7c7c] text-4xl" />
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] ">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>

          <IoIosArrowForward className="text-[#7e7c7c] text-4xl" />
        </div>
      </div>
      <div className="flex flex-col mt-[4.68rem]">
        <div className="flex justify-start text-xl font-bold pl-[9rem]">
          Results
        </div>
        <div className="flex items-center  w-full outfit mt-[2rem]">
          <div className="flex justify-end pl-[9rem]">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start pl-[6rem] gap-4  pr-[8rem] ">
            <div className="flex flex-col gap-1">
              <div className="text-xl">HP Laptop</div>
              <div className="text-xs ">
                Pavilion Aero AMD Ryzen 7 7735U 13.3 inch(33.8cm) WUXGA IPS
                Micro-Edge Laptop(16GB RAM/1TB SSD/AMD Radeon Graphics/Win
                11/MSO/Backlit
              </div>
            </div>
            <div className="flex items-center  gap-[4rem]">
              <div className="text-[#FF179A]">₹400/mo</div>
              <div className="border py-1 px-6 border-primary-600 rounded-3xl">
                36% off
              </div>
            </div>
            <div className="text-[#FF179A]">Delivery by 28 march</div>
          </div>
          <div className="flex flex-col justify-center items-end pr-[9rem] w-1/2 gap-4">
            <div className="flex items-center justify-center w-[16rem] bg-primary-600 text-white py-[0.7rem] rounded-2xl">
              Add to cart
            </div>
            <div
              className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer"
              onClick={() =>
                handleViewDetailNavigate(
                  "/rental-home/explore-more/view-detail"
                )
              }>
              View Detail
            </div>
          </div>
        </div>

        {/* repeated divs from here  */}
        <div className="flex items-center  w-full outfit mt-[2rem]">
          <div className="flex justify-end pl-[9rem]">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start pl-[6rem] gap-4  pr-[8rem] ">
            <div className="flex flex-col gap-1">
              <div className="text-xl">HP Laptop</div>
              <div className="text-xs ">
                Pavilion Aero AMD Ryzen 7 7735U 13.3 inch(33.8cm) WUXGA IPS
                Micro-Edge Laptop(16GB RAM/1TB SSD/AMD Radeon Graphics/Win
                11/MSO/Backlit
              </div>
            </div>
            <div className="flex items-center  gap-[4rem]">
              <div className="text-[#FF179A]">₹400/mo</div>
              <div className="border py-1 px-6 border-primary-600 rounded-3xl">
                36% off
              </div>
            </div>
            <div className="text-[#FF179A]">Delivery by 28 march</div>
          </div>
          <div className="flex flex-col justify-center items-end pr-[9rem] w-1/2 gap-4">
            <div className="flex items-center justify-center w-[16rem] bg-primary-600 text-white py-[0.7rem] rounded-2xl">
              Add to cart
            </div>
            <div
              className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer"
              onClick={() =>
                handleViewDetailNavigate(
                  "/rental-home/explore-more/view-detail"
                )
              }>
              View Detail
            </div>
          </div>
        </div>

        <div className="flex items-center  w-full outfit mt-[2rem]">
          <div className="flex justify-end pl-[9rem]">
            <div className="relative flex items-center justify-center h-[16.5rem] w-[29rem] border rounded-2xl border-[#7b7a7a]">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-start pl-[6rem] gap-4  pr-[8rem] ">
            <div className="flex flex-col gap-1">
              <div className="text-xl">HP Laptop</div>
              <div className="text-xs ">
                Pavilion Aero AMD Ryzen 7 7735U 13.3 inch(33.8cm) WUXGA IPS
                Micro-Edge Laptop(16GB RAM/1TB SSD/AMD Radeon Graphics/Win
                11/MSO/Backlit
              </div>
            </div>
            <div className="flex items-center  gap-[4rem]">
              <div className="text-[#FF179A]">₹400/mo</div>
              <div className="border py-1 px-6 border-primary-600 rounded-3xl">
                36% off
              </div>
            </div>
            <div className="text-[#FF179A]">Delivery by 28 march</div>
          </div>
          <div className="flex flex-col justify-center items-end pr-[9rem] w-1/2 gap-4">
            <div className="flex items-center justify-center w-[16rem] bg-primary-600 text-white py-[0.7rem] rounded-2xl">
              Add to cart
            </div>
            <div
              className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl cursor-pointer"
              onClick={() =>
                handleViewDetailNavigate(
                  "/rental-home/explore-more/view-detail"
                )
              }>
              View Detail
            </div>
          </div>
        </div>

        <div className="flex justify-center text-sm px-10 py-2  rounded-2xl mt-[1rem]">
          <IoIosArrowForward className="text-[#7e7c7c] text-5xl rotate-90" />
        </div>
      </div>

      <div className="flex flex-col items-start justify-center  mt-[3rem]  gap-[3rem] outfit pl-[9rem] pr-[9rem]">
        <div className="flex flex-col gap-[1rem]">
          <div className="flex justify-start text-xl font-bold ">
            Customer Reviews
          </div>
          <div className="flex justify-start pl-[0.2rem]">600+ reviews</div>
          <div className="flex h-[1.75rem] w-[16rem] ">
            <div className="w-4/5 h-full bg-[#EEBF00] rounded-l-xl"></div>
            <div className="w-1/5 h-full bg-[#eae8e8] rounded-r-xl"></div>
          </div>
        </div>
        <div className="flex gap-[4rem] ">
          <div className="flex flex-col gap-[1rem]">
            <div>
              <div className="text-lg font-bold">Customer Say</div>
              <div className="text-sm">
                Customers like the performance and battery quality of the
                headphones. They mention that it works well, has a good battery
                backup, and is user friendly. They are also happy with
                appearance, and value. However, some customers have reported
                issues with charging and connectivity. Customers are mixed on
                sound quality, connectivity, and quality.
              </div>
            </div>
            <div className="flex gap-[1rem]">
              <img
                src={rentalhome3}
                alt=""
                className="rounded-full h-14 w-14"
              />
              <div className="flex flex-col justify-center items-start gap-[0.3rem]">
                <div className=" text-sm font-semibold">Raghubir Singh</div>
                <div className="flex justify-center items-center gap-1 rounded-lg px-[0.8rem] py-[0.2rem] bg-[#EEBF00] text-white text-xs">
                  4.3 <FaStar />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[1rem] border-x px-[4rem] border-[#676565]">
            <div>
              <div className="text-lg font-bold">Customer Say</div>
              <div className="text-sm">
                Customers like the performance and battery quality of the
                headphones. They mention that it works well, has a good battery
                backup, and is user friendly. They are also happy with
                appearance, and value. However, some customers have reported
                issues with charging and connectivity. Customers are mixed on
                sound quality, connectivity, and quality.
              </div>
            </div>
            <div className="flex gap-[1rem]">
              <img
                src={rentalhome3}
                alt=""
                className="rounded-full h-14 w-14"
              />
              <div className="flex flex-col justify-center items-start gap-[0.3rem]">
                <div className=" text-sm font-semibold">Raghubir Singh</div>
                <div className="flex justify-center items-center gap-1 rounded-lg px-[0.8rem] py-[0.2rem] bg-[#EEBF00] text-white text-xs">
                  4.3 <FaStar />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[1rem]">
            <div>
              <div className="text-lg font-bold">Customer Say</div>
              <div className="text-sm">
                Customers like the performance and battery quality of the
                headphones. They mention that it works well, has a good battery
                backup, and is user friendly. They are also happy with
                appearance, and value. However, some customers have reported
                issues with charging and connectivity. Customers are mixed on
                sound quality, connectivity, and quality.
              </div>
            </div>
            <div className="flex gap-[1rem]">
              <img
                src={rentalhome3}
                alt=""
                className="rounded-full h-14 w-14"
              />
              <div className="flex flex-col justify-center items-start gap-[0.3rem]">
                <div className=" text-sm font-semibold">Raghubir Singh</div>
                <div className="flex justify-center items-center gap-1 rounded-lg px-[0.8rem] py-[0.2rem] bg-[#EEBF00] text-white text-xs">
                  4.3 <FaStar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col  my-[6rem] gap-[3rem] outfit">
        <div className="flex font-bold text-xl pl-[9rem]">More products</div>
        <div className="flex items-center justify-center gap-10">
          <div className="flex flex-col items-center justify-center gap-1">
            <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-t-2xl border-[#7b7a7a] ">
              <img src={rentalhome2} alt="" />

              <div className="mt-[1.5vw]">Laptop</div>
            </div>
            <div className="flex items-center justify-center w-[17.5rem] bg-primary-600 text-white py-[0.8rem] rounded-b-2xl">
              View product
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-1">
            <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-t-2xl border-[#7b7a7a] ">
              <img src={rentalhome2} alt="" />

              <div className="mt-[1.5vw]">Laptop</div>
            </div>
            <div className="flex items-center justify-center w-[17.5rem] bg-primary-600 text-white py-[0.8rem] rounded-b-2xl">
              View product
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-1">
            <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-t-2xl border-[#7b7a7a] ">
              <img src={rentalhome2} alt="" />

              <div className="mt-[1.5vw]">Laptop</div>
            </div>
            <div className="flex items-center justify-center w-[17.5rem] bg-primary-600 text-white py-[0.8rem] rounded-b-2xl">
              View product
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-1">
            <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-t-2xl border-[#7b7a7a] ">
              <img src={rentalhome2} alt="" />

              <div className="mt-[1.5vw]">Laptop</div>
            </div>
            <div className="flex items-center justify-center w-[17.5rem] bg-primary-600 text-white py-[0.8rem] rounded-b-2xl">
              View product
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;
