import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  loginUser,
  removeStatus,
  setLoading,
} from "../features/user/UserSlice";
import { Link, useNavigate } from "react-router-dom";
import { empluxIcon, signin_banner } from "../utils/Urls";
import talency_logo from "../Images/talency_logo.png";
import { Toaster } from "sonner";

const initialState = {
  username: "",
  password: "",
  rememberMe: true,
};

const Login = () => {
  const [values, setValues] = useState(initialState);
  const { user, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };

  console.log(values);
  const onSubmit = (e) => {
    e.preventDefault();
    const { username, password, rememberMe } = values;
    if (!username || !password) {
      alert("Please fill out all fields");
      return;
    }
    dispatch(
      loginUser({
        emailOrMobile: username,
        password: password,
        rememberMe: rememberMe,
      })
    );
  };
  const handleRememberMe = (e) => {
    const isChecked = e.target.checked;
    setValues({ ...values, rememberMe: isChecked });
  };

  // ###################

  const toggleMember = () => {
    setTimeout(() => {
      navigate("/register");
    }, 10);
  };

  const forgotPassword = () => {
    setTimeout(() => {
      navigate("/forgotpassword");
    }, 10);
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 10);
    }
  }, [user, navigate]);

  useEffect(() => {
    dispatch(removeStatus());
    dispatch(setLoading());
  }, []);
  // Regex code for

  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <Toaster richColors position="top-center" />
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
      </div>

      <div className="w-full lg:w-1/2 mt-[2rem]">
        <Link to={"/"} className="mt-10">
          <img src={empluxIcon} alt="Logo" className="mx-auto h-32" />
        </Link>

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className="form-section">
            <form onSubmit={onSubmit}>
              {/* Login UI starts here */}
              <div className="mb-2 mt-5">
                {/* Email field */}
                <input
                  type="email"
                  name="username"
                  placeholder="Email Address"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              {/* Password field */}
              <div className="mb-2 mt-5">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border rounded-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                />

                <div className="flex justify-between">
                  <p className="mt-3 text-xs font-light text-start text-gray-700">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
                        checked={values?.rememberMe}
                        onChange={handleRememberMe}
                      />
                      <span className="ml-2 text-sm font-medium text-gray-700">
                        Remember me
                      </span>
                    </label>
                  </p>
                  <p className="mt-3 text-xs font-light text-start text-gray-700">
                    <span
                      className="ml-2 mr-1 font-medium text-gray-700 cursor-pointer hover:underline hidden"
                      onClick={forgotPassword}>
                      Forgot Password
                    </span>

                    <Link
                      to={"/login"}
                      className="ml-1 font-medium text-gray-700 cursor-pointer hover:underline">
                      Login with OTP
                    </Link>
                  </p>
                </div>
              </div>

              <div className="mt-6">
                <button
                  disabled={isLoading}
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-neutral-800 rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600">
                  {isLoading ? "Loading..." : "Sign In"}
                </button>
              </div>

              <p className="mt-8 text-xs font-light text-center text-gray-700 hidden">
                Don't have an account?{""}
                <button
                  type="button"
                  className="font-medium text-neutral-800 outline-white hover:underline ml-2"
                  onClick={toggleMember}>
                  <strong>Sign Up</strong>
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
