import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../features/Profile/ProfileSlice";
import "../style/loader.css";

const ChangeNumber = ({ handleClick, setMobileNo, mobileNo }) => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.profile);
  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      dispatch(fetchProfile());
    }
  }, []);
  return (
    <div className="fixed w-full h-full bg-[#00000083] top-0 z-50">
      <div className="absolute w-full h-full flex  top-0 items-center justify-center ">
        <div className="relative w-1/4 h-1/2 bg-gray-100 rounded-md expand">
          <div className="flex items-center justify-center bg-white rounded-t-md p-3 font-semibold font-mono">
            <span>Select Number</span>
            <button
              className="absolute right-1 p-2 text-xl hover:bg-gray-200"
              onClick={() => handleClick(1)}>
              <AiOutlineClose />
            </button>
          </div>
          <div className="flex flex-col gap-2 bg-white mt-4 h-[315px] w-full hover:overflow-auto overflow-hidden  rounded-md pt-2  px-1">
            {/* Addresses */}
            <div
              className="flex flex-col h-14 py-2   border  cursor-pointer px-3"
              onClick={() => {
                setMobileNo(userData?.mobile);
                handleClick(2);
              }}>
              <div className="flex items-center gap-6 h-full ">
                <input
                  type="radio"
                  name="mobile"
                  className="h-4 w-4 accent-black "
                  // value={1}
                  checked={userData?.mobile == mobileNo}
                />
                <div className="text-black font-semibold uppercase text-md ">
                  {userData?.mobile}
                </div>
              </div>
            </div>
            <div
              className="flex flex-col h-14 py-2   border  cursor-pointer px-3"
              onClick={() => {
                // handleAddressChange(1);
                handleClick(2);
              }}>
              <div className="flex items-center gap-6 h-full ">
                <input
                  type="radio"
                  name="mobile"
                  className="h-4 w-4 accent-black "
                  // value={1}
                  // checked={addressId == 1}
                />
                <div className="text-black font-semibold uppercase text-md ">
                  Alternate Number
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeNumber;
