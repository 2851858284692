import React, { useState } from "react";
import rentalhome1 from "../../Images/rental_home1.svg";
import rentalhome2 from "../../Images/rental_home2.svg";
import rentalhome3 from "../../Images/Young man standing and looking away.svg";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineLaptop } from "react-icons/md";
import { FaStar } from "react-icons/fa6";

const Desktop = () => {
  return (
    <div className="outfit">
      <div className="flex items-center justify-center h-[3.5rem] border-t border-[#5f5f5f75] shadow-lg gap-[6rem] font-semibold max-[1401px]:gap-[4rem] max-[1000px]:gap-[2rem] max-[900px]:gap-[1rem] max-[800px]:text-sm max-[800px]:gap-[2rem] ">
        <div className="flex justify-center ">Electronics</div>
        <div className="flex justify-center ">Home Appliances</div>
        <div className="flex justify-center ">Mobile Phones</div>
        <div className="flex justify-center ">Camera</div>
        <div className="flex justify-center ">Musical instruments</div>
        <div className="flex justify-center ">Entertainment</div>
      </div>

      <div className="flex flex-col items-center justify-center  mt-[4rem] gap-[3rem] outfit ">
        <div className="flex items-center justify-center gap-10 max-[1401px]:flex max-[1401px]:gap-[1rem] max-[1200px]:gap-[2.5rem] max-[1100px]:gap-[1rem] max-[900px]:gap-[2rem] max-[800px]:gap-[1rem]">
          <IoIosArrowForward className=" rotate-180 text-[#7e7c7c] text-4xl max-[1200px]:text-3xl" />
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] max-[1200px]:w-[10rem] max-[1200px]:h-[12rem] max-[1100px]:w-[12rem]">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] max-[1200px]:w-[10rem] max-[1200px]:h-[12rem] max-[1100px]:w-[12rem]">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] max-[1200px]:w-[10rem] max-[1200px]:h-[12rem] max-[1100px]:w-[12rem]">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>
          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] max-[1200px]:w-[10rem] max-[1200px]:h-[12rem] max-[1100px]:w-[12rem] max-[900px]:hidden">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>

          <div className="flex flex-col justify-center items-center h-[13.125rem] border w-[13.125rem] rounded-2xl border-[#7b7a7a] max-[1200px]:w-[10rem] max-[1200px]:h-[12rem] max-[1100px]:hidden">
            <img src={rentalhome1} alt="" />
            <div className="mt-[1.5vw]">Laptop</div>
          </div>

          <IoIosArrowForward className="text-[#7e7c7c] text-4xl max-[1200px]:text-3xl" />
        </div>
      </div>
      <div className="flex flex-col mt-[4.68rem]">
        <div className="flex justify-start text-xl font-bold pl-[9rem] max-[1401px]:pl-[5rem] max-[1200px]:pl-[6rem] max-[1000px]:pl-[4rem]">
          Results
        </div>

        {/* repeated divs from here  */}
        <div className="flex items-center  w-full outfit mt-[2rem]">
          <div className="flex flex-col justify-end pl-[9rem] max-[1401px]:pl-[5rem] max-[1200px]:pl-[6rem] max-[1000px]:pl-[4rem] ">
            <div className="relative flex items-center justify-center h-[12.5rem] w-[26rem] border-t border-x rounded-t-2xl border-[#7b7a7a] max-[1401px]:w-[20rem] max-[900px]:w-[17rem] max-[800px]:w-[14rem] ">
              <img src={rentalhome1} alt="" />
              <div className="absolute flex items-center justify-center top-6 -right-[4.4rem] h-[1.875rem] w-[4.375rem] bg-[#67656533] rounded-r-md">
                Rent
              </div>
            </div>
            <div className="flex items-center justify-center h-[12.5rem] w-[26rem] border-x border-[#7b7a7a] max-[1401px]:w-[20rem] max-[900px]:w-[17rem] max-[800px]:w-[14rem] ">
              <img src={rentalhome1} alt="" />
            </div>
            <div className="flex items-center justify-center h-[12.5rem] w-[26rem] border-b border-x rounded-b-2xl border-[#7b7a7a] max-[1401px]:w-[20rem] max-[900px]:w-[17rem] max-[800px]:w-[14rem] ">
              <img src={rentalhome1} alt="" />
            </div>
          </div>

          <div className="flex flex-col justify-center items-start pl-[6rem] gap-4  pr-[8rem] max-[1100px]:pr-[4rem] max-[900px]:pr-[2rem] max-[800px]:pl-[2rem] max-[800px]:pr-[2rem]">
            <div className="flex flex-col gap-1">
              <div className="text-xl font-semibold">HP Laptop</div>
              <div className="text-[14px] max-[1200px]:text-[12px]">
                Pavilion Aero AMD Ryzen 7 7735U 13.3 inch(33.8cm) WUXGA IPS
                Micro-Edge Laptop(16GB RAM/1TB SSD/AMD Radeon Graphics/Win
                11/MSO/Backlit
              </div>
            </div>
            <div className="flex flex-col gap-[1rem] max-[1100px]:gap-[0.5rem]">
              <div className="text-md font-semibold">About this item:</div>
              <div className="text-[14px] max-[1200px]:text-[12px]">
                <ol className="flex flex-col gap-4 bg-white list-disc">
                  <li>
                    Processor: Intel Core i3-1115G4(up to 4.1 GHz with Intel
                    Turbo Boost Technology(2g),6 MB L3 cache, 2 cores)|Memory: 8
                    GB DDR4-3200 SDRAM (1 x 8 GB)| Storage: 256 GB PCIe NVMe M.2
                    SSD
                  </li>
                  <li className="">
                    Processor: Intel Core i3-1115G4(up to 4.1 GHz with Intel
                    Turbo Boost Technology(2g),6 MB L3 cache, 2 cores)|Memory: 8
                    GB DDR4-3200 SDRAM (1 x 8 GB)| Storage: 256 GB PCIe NVMe M.2
                    SSD
                  </li>
                  <li className="max-[1100px]:hidden">
                    Processor: Intel Core i3-1115G4(up to 4.1 GHz with Intel
                    Turbo Boost Technology(2g),6 MB L3 cache, 2 cores)|Memory: 8
                    GB DDR4-3200 SDRAM (1 x 8 GB)| Storage: 256 GB PCIe NVMe M.2
                    SSD
                  </li>
                </ol>
              </div>
            </div>

            <div className="font-semibold text-[#FF179A]">
              Delivery by 28 march
            </div>
          </div>
          <div className="flex flex-col justify-center items- pr-[9rem] w-1/2 gap-4 max-[1401px]:pr-[6rem] max-[1000px]:pr-[4rem] max-[800px]:pr-[3rem]">
            <div className="flex items-center justify-center w-[16rem] bg-primary-600 text-white py-[0.7rem] rounded-2xl max-[1200px]:w-[12rem] max-[900px]:w-[10rem] max-[900px]:py-[0.4rem]">
              Add to cart
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl max-[1200px]:w-[12rem] max-[900px]:w-[10rem] max-[900px]:py-[0.4rem]">
              Review
            </div>
            <div className="flex items-center  gap-[8rem] max-[1200px]:gap-[4rem] max-[900px]:gap-[2rem]  ">
              <div className=" rounded-3xl text-[#FF179A] font-bold">
                ₹400/mo
              </div>
              <div className=" rounded-3xl  max-[1200px]:text-sm text-[#FF179A] font-bold">
                36% off
              </div>
            </div>
            <div className="flex items-center justify-center w-[16rem] border-primary-600 py-[0.6rem] border rounded-2xl pl-[1.5rem] font-semibold text-sm max-[1200px]:w-[12rem] max-[900px]:w-[10rem] max-[900px]:py-[0.4rem]">
              11th Gen i3/8GB DDR4 256 SSD ₹33,990.00
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start justify-center  mt-[3rem]  gap-[3rem] outfit pl-[9rem]  max-[1401px]:pl-[5rem] max-[1200px]:pl-[6rem] max-[1000px]:pl-[4rem]">
        <div className="flex flex-col gap-[1rem]">
          <div className="flex justify-start text-xl font-bold ">
            Customer Reviews
          </div>
          <div className="flex justify-start pl-[0.2rem]">600+ reviews</div>
          <div className="flex h-[1.75rem] w-[16rem] ">
            <div className="w-4/5 h-full bg-[#EEBF00] rounded-l-xl"></div>
            <div className="w-1/5 h-full bg-[#eae8e8] rounded-r-xl"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col  my-[6rem] gap-[3rem] outfit px-[9rem]  max-[1401px]:px-[5rem] max-[1200px]:px-[6rem] max-[1000px]:px-[4rem]">
        <div className="flex font-bold text-xl ">More products</div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-center justify-center gap-1">
            <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-t-2xl border-[#7b7a7a] max-[1401px]:h-[16rem] max-[1401px]:w-[16rem] max-[1200px]:h-[14rem] max-[1200px]:w-[14rem] max-[1100px]:w-[15rem] max-[900px]:w-[14rem] max-[800px]:w-[12rem] ">
              <img src={rentalhome2} alt="" className="max-[1200px]:h-[8rem]" />

              <div className="mt-[1.5vw]">Laptop</div>
            </div>
            <div className="flex items-center justify-center w-[17.5rem] bg-primary-600 text-white py-[0.8rem] rounded-b-2xl  max-[1401px]:w-[16rem] max-[1200px]:w-[14rem] max-[1100px]:w-[15rem] max-[900px]:w-[14rem] max-[800px]:w-[12rem] ">
              View product
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-1 ">
            <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-t-2xl border-[#7b7a7a] max-[1401px]:h-[16rem] max-[1401px]:w-[16rem] max-[1200px]:h-[14rem] max-[1200px]:w-[14rem] max-[1100px]:w-[15rem] max-[900px]:w-[14rem] max-[800px]:w-[12rem] ">
              <img src={rentalhome2} alt="" className="max-[1200px]:h-[8rem]" />

              <div className="mt-[1.5vw]">Laptop</div>
            </div>
            <div className="flex items-center justify-center w-[17.5rem] bg-primary-600 text-white py-[0.8rem] rounded-b-2xl  max-[1401px]:w-[16rem] max-[1200px]:w-[14rem] max-[1100px]:w-[15rem] max-[900px]:w-[14rem] max-[800px]:w-[12rem] ">
              View product
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-1">
            <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-t-2xl border-[#7b7a7a] max-[1401px]:h-[16rem] max-[1401px]:w-[16rem] max-[1200px]:h-[14rem] max-[1200px]:w-[14rem] max-[1100px]:w-[15rem] max-[900px]:w-[14rem] max-[800px]:w-[12rem] ">
              <img src={rentalhome2} alt="" className="max-[1200px]:h-[8rem]" />

              <div className="mt-[1.5vw]">Laptop</div>
            </div>
            <div className="flex items-center justify-center w-[17.5rem] bg-primary-600 text-white py-[0.8rem] rounded-b-2xl  max-[1401px]:w-[16rem] max-[1200px]:w-[14rem] max-[1100px]:w-[15rem] max-[900px]:w-[14rem] max-[800px]:w-[12rem] ">
              View product
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-1 max-[1100px]:hidden">
            <div className="flex flex-col justify-center items-center h-[17.5rem] border w-[17.5rem] rounded-t-2xl border-[#7b7a7a] max-[1401px]:h-[16rem] max-[1401px]:w-[16rem] max-[1200px]:h-[14rem] max-[1200px]:w-[14rem] max-[900px]:w-[14rem] max-[800px]:w-[12rem] ">
              <img src={rentalhome2} alt="" className="max-[1200px]:h-[8rem]" />

              <div className="mt-[1.5vw]">Laptop</div>
            </div>
            <div className="flex items-center justify-center w-[17.5rem] bg-primary-600 text-white py-[0.8rem] rounded-b-2xl  max-[1401px]:w-[16rem] max-[1200px]:w-[14rem]">
              View product
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
