import React, { useState } from "react";
import { BiDownArrow } from "react-icons/bi";
import { useSelector } from "react-redux";

const Desktop = ({ subCatId }) => {
  const [open, setOpen] = useState([]);
  const { rateChart } = useSelector((st) => st.issue);

  const rates = Object.entries(
    rateChart.reduce((acc, item) => {
      if (!acc[item.subCategoryName]) {
        acc[item.subCategoryName] = [];
      }
      acc[item.subCategoryName]?.push({
        item: item?.description,
        price: item?.serviceCharge,
        labour: item?.labourCharge,
      });
      return acc;
    }, {})
  ).map(([key, value]) => ({
    heading: key,
    rates: value,
  }));

  const handleExpand = (id) => {
    if (open?.indexOf(id) < 0) {
      setOpen([...open, id]);
    } else {
      setOpen(open.filter((i) => i !== id));
    }
  };
  return (
    <div className="min-h-screen  pt-10">
      <div className="flex flex-col w-[85%] m-auto gap-12 ">
        <div className="text-xl font-bold text-primary-max w-1/3 min-w-max pb-2 border-b-2 border-primary-600">
          Rate Chart
        </div>
        {rates?.map((rate, index) => (
          <div className="w-full " key={index}>
            <div
              className="w-full px-5 py-2 bg-gray-50 border border-primary-70 flex justify-between items-center text-primary-600 font-thin text-lg top-shadow"
              onClick={() => handleExpand(index)}>
              <span className="capitalize">{rate?.heading}</span>
              <BiDownArrow
                className={
                  "text-lg " +
                  (open?.indexOf(index) >= 0 ? "rotate-180" : "rotate-0")
                }
              />
            </div>
            {open?.indexOf(index) >= 0 && (
              <div className="w-full bg-slate-50 px-6 py-4 border-b border-x border-gray-100 flex flex-col gap-2">
                {rate?.rates?.map((rate, index) => (
                  <div
                    className="w-full flex justify-between items-center border-b pb-2"
                    key={index}>
                    <span className=" tracking-wide text-black capitalize">
                      {rate?.item}
                    </span>
                    <div className="font-bold text-gray-500 flex items-center justify-between w-1/5 min-h-max ">
                      <div className="h-full font-extralight">:</div>
                      <span>
                        <span className="text-black">
                          {"₹" +
                            (parseInt(rate?.price) +
                              parseInt(rate?.labour))}{" "}
                        </span>
                        <span className="text-sm font-light">
                          {" (₹" + rate?.labour + " labour cost )"}
                        </span>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Desktop;
