import React from "react";
import { commingSoonIcon, signUnderline } from "../Images";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const CommingSoonLayout = () => {
  return (
    <div className="w-full h-full min-h-[90vh] flex flex-col items-center justify-center">
      <motion.img
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        src={commingSoonIcon}
        alt=""
        className="max-sm:w-[16rem] w-[24rem]"
      />
      <div className=" mt-12 tracking-wide sm:text-xl">
        <p>
          Till then{" "}
          <Link
            className="text-primary-max font-medium underline cursor-pointer"
            to={"/"}>
            click here
          </Link>{" "}
          to explore other Services
        </p>
        <motion.img
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          src={signUnderline}
          alt=""
          className=" w-full mt-4"
        />
      </div>
    </div>
  );
};

export default CommingSoonLayout;
