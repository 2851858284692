import React, { useState } from "react";
import { AiOutlineClose, AiOutlineArrowRight } from "react-icons/ai";
import DateTime from "./DateTime";

const SlotOption = ({ handleClick, slot, setSlot }) => {
  console.log(slot);

  // console.log(currentDateTime.toString().slice(0, 10));
  return (
    <div className="fixed top-0 w-full h-full z-40">
      <div className="absolute w-full h-full flex z-50 top-0 items-center justify-center bg-[#00000049]">
        <div className="relative w-[400px]  bg-white rounded-md expand">
          <div className="flex items-center justify-center font-mono font-semibold  rounded-t-md p-2 mx-4  border-b">
            <span>Select Slot</span>
            <button
              className="absolute right-1 p-2 text-xl hover:bg-gray-200"
              onClick={() => handleClick(3)}>
              <AiOutlineClose />
            </button>
          </div>
          {/* Stots section */}
          <div>
            {/* date Options */}
            {/* <div className=" flex gap-5 py-2 px-4 ">
              <button
                className={
                  "capitalize flex flex-col items-center justify-center w-14 h-16 border rounded-lg hover:bg-black hover:text-white " +
                  (SelDate === 0 && "bg-black text-white")
                }
                onClick={() => handleDateSelect(0)}>
                <span>{dateOption[0].toString().slice(0, 3)}</span>
                <span className="font-medium ">{dateOption[0].getDate()}</span>
              </button>
              <button
                className={
                  "capitalize flex flex-col items-center justify-center w-14 h-16 border rounded-lg hover:bg-black hover:text-white " +
                  (SelDate === 1 && "bg-black text-white")
                }
                onClick={() => handleDateSelect(1)}>
                <span>{dateOption[1].toString().slice(0, 3)}</span>
                <span className="font-medium ">{dateOption[1].getDate()}</span>
              </button>
              <button
                className={
                  "capitalize flex flex-col items-center justify-center w-14 h-16 border rounded-lg hover:bg-black hover:text-white " +
                  (SelDate === 2 && "bg-black text-white")
                }
                onClick={() => handleDateSelect(2)}>
                <span>{dateOption[2].toString().slice(0, 3)}</span>
                <span className="font-medium ">{dateOption[2].getDate()}</span>
              </button>
            </div> */}
            {/* {/* <DateTime />.  */}
            <input
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3 outline-none"
              id="grid-first-name"
              type="datetime-local"
              name="time"
              value={slot}
              min={
                slot.includes("T") ? new Date().toISOString().substr(0, 16) : ""
              }
              // max={
              //   slot.includes("T") ? new Date().toISOString().substr(0, 16) : ""
              // }
              onChange={(e) => setSlot(e)}
            />

            {/* <input
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3 outline-none"
              id="grid-first-name"
              type="time"
              name="time"
              value={slot}
              min={
                slot.includes("T") ? new Date().toISOString().substr(0, 16) : ""
              }
              // max={
              //   slot.includes("T") ? new Date().toISOString().substr(0, 16) : ""
              // }
              onChange={(e) => setSlot(e)}
            /> */}

            {/* Timing Options */}
            {/*<div className="grid grid-cols-3 gap-4 items-center justify-center  bg-white h-[270px] w-11/12 m-auto border rounded-md mt-4 overflow-auto no-scrollbar p-4 ">
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center">
                {(shift % 12) + ":00-" + ((shift + 1) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center">
                {((shift + 1) % 12) + ":00-" + ((shift + 2) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center">
                {((shift + 2) % 12) + ":00-" + ((shift + 3) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center col-span-3 mb-2">
                {((shift + 3) % 12) + ":00-" + ((shift + 4) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3 place-self-center ">
                {((shift + 4) % 12) + ":00-" + ((shift + 5) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center">
                {((shift + 5) % 12) + ":00-" + ((shift + 6) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center">
                {((shift + 6) % 12) + ":00-" + ((shift + 7) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center col-span-3 mb-2">
                {((shift + 7) % 12) + ":00-" + ((shift + 8) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center ">
                {((shift + 8) % 12) + ":00-" + ((shift + 9) % 12) + ":00"}
              </button>
              <button className="bg-gray-100 border border-gray-400 text-[15px] font-thin rounded-md py-2 px-3  place-self-center">
                {((shift + 9) % 12) + ":00-" + ((shift + 10) % 12) + ":00"}
              </button>
            </div> */}
            {/* Final  */}
            <div className=" h-12 items-center px-4 p-1 my-2 grid grid-cols-2  w-full">
              {/* <span className="ml-2">
                <span className="font-semibold capitalize text-sm mr-2">
                  {dateOption[SelDate].toString()
                    .slice(0, 10)
                    .replace(" ", ",")}
                </span>
                <span className="text-sm my-4 bg-gray-100 border p-1 rounded-md border-black">
                  {SelTime + ":00" + "-" + (SelTime + 1) + ":00"}
                </span>
              </span> */}
              {/* <button className="flex relative  gap-4 items-center justify-center bg-primary-600 place-self-stretch rounded-md text-white">
                Continue
              
              </button> */}
            </div>
          </div>
          <div className="flex items-center justify-center border  w-full h-1/6  m-auto  ">
            <button
              to="/address"
              className="p-1 py-2 my-4 border border-white rounded-md  bg-primary-600 uppercase  font-semibold  w-11/12 text-white text-center"
              onClick={() => handleClick(4)}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotOption;
