import React from "react";

const Otp = ({ otp }) => {
  const integerOtp = parseInt(otp || "123456");
  return (
    <div className="flex gap-2  ">
      <span className="px-4 py-3  text-white bg-gradient-to-tr from-gray-600 to-black text-xl max-xs:text-sm font-medium rounded-xl">
        {Math.floor(integerOtp / 100000) % 10}
      </span>
      <span className="px-4 py-3  text-white bg-gradient-to-tr from-gray-600 to-black text-xl max-xs:text-sm font-medium rounded-xl">
        {Math.floor(integerOtp / 10000) % 10}
      </span>
      <span className="px-4 py-3  text-white bg-gradient-to-tr from-gray-600 to-black text-xl max-xs:text-sm font-medium rounded-xl">
        {Math.floor(integerOtp / 1000) % 10}
      </span>
      <span className="px-4 py-3  text-white bg-gradient-to-tr from-gray-600 to-black text-xl max-xs:text-sm font-medium rounded-xl">
        {Math.floor(integerOtp / 100) % 10}
      </span>
      <span className="px-4 py-3  text-white bg-gradient-to-tr from-gray-600 to-black text-xl max-xs:text-sm font-medium rounded-xl">
        {Math.floor(integerOtp / 10) % 10}
      </span>
      <span className="px-4 py-3  text-white bg-gradient-to-tr from-gray-600 to-black text-xl max-xs:text-sm font-medium rounded-xl">
        {Math.floor(integerOtp / 1) % 10}
      </span>
    </div>
  );
};

export default Otp;
