import React from "react";
import CheckoutStatus from "../../CheckoutStatus";
import { RxCross1 } from "react-icons/rx";
import { IoMdPricetag } from "react-icons/io";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SelectAddress from "../../SelectAddress";
import CheckoutSidebar from "../CheckoutSidebar";
import { useDispatch } from "react-redux";
import { deleteCart } from "../../../features/Cart/CartSlice";
import Confirm from "../../Notifications/Confirm";

const Mobile = ({
  subCatgId,
  cartItems,
  address,
  profile,
  location,
  handleDataChange,
  bookingState,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [removeCartItem, setRemove] = useState(null);

  const [isChangingAddress, setChangingAddress] = useState(false);
  const handleRemoveCartItem = (status, cartId) => {
    if (removeCartItem) {
      if (status === true) {
        dispatch(deleteCart(removeCartItem));
      }
      setRemove(null);
    } else {
      setRemove(cartId);
    }
  };

  const handleAddressChange = () => {
    if (isChangingAddress === false) {
      setChangingAddress(true);
    } else {
      setChangingAddress(false);
    }
  };

  const handleNavigate = () => {
    navigate(`/check-out-details/address/${subCatgId}#blockBack`, {
      state: bookingState,
    });
  };

  return (
    <div>
      {/* popups */}
      {/* Address */}
      {isChangingAddress && <SelectAddress handleClick={handleAddressChange} />}
      {removeCartItem && (
        <Confirm
          question={"Are you sure to remove cart item?"}
          handleConfirmation={handleRemoveCartItem}
        />
      )}
      <CheckoutStatus location={location} subCatgId={subCatgId} />
      <div className="py-4 px-4 w-full mt-8  border-r flex flex-col gap-4">
        {/* address */}
        <div className="border p-4 hidden items-center rounded-sm">
          <div className="w-5/6">
            <div>
              {"Arrive at:" +
                profile?.firstName +
                " " +
                profile?.lastName +
                ", " +
                address?.pinCode}
            </div>
            <div className="w-3/4 text-sm text-gray-500">
              {address?.address + ", " + address?.city + ", " + address?.state}
            </div>
          </div>
          <div
            className="border px-4 py-2 cursor-pointer"
            onClick={handleAddressChange}>
            Change
          </div>
        </div>

        {/* offers */}
        {/* <div className="border p-4 flex  flex-col  rounded-sm">
          <div className="font-semibold text-sm">Available offers</div>
          <div className="py-1 flex flex-col text-gray-600 text-xs">
            <div>7.5% Instant discount on every spend</div>
            <div className="text-blue-500 font-semibold mt-1">Show more</div>
          </div>
        </div> */}

        {/* Cart items */}
        <div className="py-4 flex  flex-col gap-2">
          <h1 className="font-semibold mb-2 text-xl">Cart Items</h1>
          {/* Items */}
          {cartItems?.map((element, index) => {
            return (
              <div className="shadow-custom flex relative" key={index}>
                <div className="w-[7rem]  p-4">
                  <img
                    src={element?.issueAppIcon}
                    alt=""
                    className="w-full h-full object-fill aspect-square "
                  />
                </div>
                <div className="flex-1 flex flex-col px-2 py-2">
                  <div className="font-semibold text-sm">
                    {element?.issueName}
                  </div>
                  <div className="text-xs w-2/3 mb-1">
                    {element?.description}
                  </div>
                  <div className="rounded-md p-1 bg-gray-200 text-xs font-mono w-max mb-1">
                    {element?.issueDuration + " mins"}
                  </div>
                  <div className="text-sm font-bold">
                    {"₹ " + element?.issuePrice}
                  </div>
                </div>
                <div
                  className="absolute right-0 px-2 py-2 text-sm transition-all ease-in-out duration-75 hover:scale-110 cursor-pointer"
                  onClick={() => handleRemoveCartItem(undefined, element?.id)}>
                  <RxCross1 className="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <CheckoutSidebar
          location={location}
          subCatgId={subCatgId}
          bookingState={bookingState}
          handleDataChange={handleDataChange}
        />
      </div>

      {/* Next button */}
      <div className="w-full py-[0.5rem] text-center fixed bottom-0 left-0 z-10 bg-white top-shadow min-w-full">
        <div
          onClick={handleNavigate}
          className="min-w-4/5 w-4/5 py-[0.5rem] text-center font-semibold text-white capitalize heading-gradient rounded-[3rem] mx-auto cursor-pointer">
          Proceed
        </div>
      </div>
    </div>
  );
};

export default Mobile;
