import React from "react";
import CheckoutStatus from "../../CheckoutStatus";
import { RxCross1 } from "react-icons/rx";
import { IoMdPricetag } from "react-icons/io";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SelectAddress from "../../SelectAddress";
import CheckoutSidebar from "../CheckoutSidebar";
import { useDispatch } from "react-redux";
import { deleteCart } from "../../../features/Cart/CartSlice";
import Confirm from "../../Notifications/Confirm";

const Desktop = ({
  subCatgId,
  cartItems,
  address,
  profile,
  location,
  bookingState,
  handleDataChange,
}) => {
  const dispatch = useDispatch();
  const [removeCartItem, setRemove] = useState(null);

  const [isChangingAddress, setChangingAddress] = useState(false);
  const handleRemoveCartItem = (status, cartId) => {
    if (removeCartItem) {
      if (status === true) {
        dispatch(deleteCart(removeCartItem));
      }
      setRemove(null);
    } else {
      setRemove(cartId);
    }
  };

  const handleAddressChange = () => {
    if (isChangingAddress === false) {
      setChangingAddress(true);
    } else {
      setChangingAddress(false);
    }
  };
  return (
    <div className="w-full max-w-screen overflow-hidden ">
      {/* popups */}
      {/* Remove cart confirmation */}
      {removeCartItem && (
        <Confirm
          question={"Are you sure to remove cart item?"}
          handleConfirmation={handleRemoveCartItem}
        />
      )}
      {/* Address */}

      <CheckoutStatus location={location} subCatgId={subCatgId} />

      <div className="flex w-8/12 m-auto min-w-max py-10">
        {/* cart details and address */}
        <div className="py-4 px-4 w-3/5  border-r flex flex-col gap-4">
          {/* address */}
          <div className="border p-4  items-center rounded-sm hidden">
            <div className="w-5/6">
              <div>
                {"Arrive at:" +
                  profile?.firstName +
                  " " +
                  profile?.lastName +
                  ", " +
                  address?.pinCode}
              </div>
              <div className="w-3/4 text-sm text-gray-500">
                {address?.address +
                  ", " +
                  address?.city +
                  ", " +
                  address?.state}
              </div>
            </div>
            <div
              className="border px-4 py-2 cursor-pointer"
              onClick={handleAddressChange}>
              Change
            </div>
          </div>

          {/* offers */}
          {/* <div className="border p-4 flex  flex-col  rounded-sm">
            <div className="font-semibold text-sm">Available offers</div>
            <div className="py-1 flex flex-col text-gray-600 text-xs">
              <div>7.5% Instant discount on every spend</div>
              <div className="text-blue-500 font-semibold mt-1">Show more</div>
            </div>
          </div> */}

          {/* Cart items */}
          <div className="py-4 flex  flex-col gap-2">
            <h1 className="font-semibold mb-2 text-xl">Cart Items</h1>
            {/* Items */}
            {cartItems?.map((element, index) => {
              return (
                <div className="border flex relative" key={index}>
                  <div className="w-1/5 p-2">
                    <img
                      src={element?.issueIcon}
                      alt=""
                      className="w-full h-full object-fill "
                    />
                  </div>
                  <div className="w-4/5 flex flex-col px-2 py-2">
                    <div className="font-semibold text-sm">
                      {element?.issueName}
                    </div>
                    <div className="text-xs w-2/3 mb-1">
                      {element?.description}
                    </div>
                    <div className="rounded-md p-1 bg-gray-200 text-xs font-mono w-max mb-1">
                      {element?.issueDuration + " mins"}
                    </div>
                    <div className="text-sm font-bold">
                      {"₹ " + element?.issuePrice}
                    </div>
                  </div>
                  <div
                    className="absolute right-0 px-2 py-2 text-sm transition-all ease-in-out duration-75 hover:scale-110 cursor-pointer"
                    onClick={() =>
                      handleRemoveCartItem(undefined, element?.id)
                    }>
                    <RxCross1 className="" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* payment summary */}
        <div className="w-2/5">
          <CheckoutSidebar
            location={location}
            subCatgId={subCatgId}
            bookingState={bookingState}
            handleDataChange={handleDataChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Desktop;
