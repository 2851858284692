import React, { useEffect, useState } from "react";
import Desktop from "../../components/FAQs/Desktop";
import Mobile from "../../components/FAQs/Mobile";
import { useDispatch, useSelector } from "react-redux";
import { getFAQ } from "../../features/FAQ/FAQslice";

const Faqs = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 720);
  };
  const dispatch = useDispatch();
  const { FAQList } = useSelector((store) => store.faq);

  useEffect(() => {
    dispatch(getFAQ());
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div>
      {isDesktop ? <Desktop FAQList={FAQList} /> : <Mobile FAQList={FAQList} />}
    </div>
  );
};

export default Faqs;
