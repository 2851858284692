import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { logoutUser } from "../user/UserSlice";
import customFetch from "../../utils/axios";

const initialState = {
  isLoadingBookingDetail: false,
  BookingDetails: {},
};

// GET DETAILS
export const fetchBookingDetails = createAsyncThunk(
  "booking-detail/getbookingdetail",
  async (thunkAPI) => {
    const { userId, accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/bookings/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(resp);
      return resp?.data;
    } catch (error) {
      if (error.response?.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const BookingDetailSlice = createSlice({
  name: "bookingdetail",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingDetails.pending, (state) => {
        state.isLoadingBookingDetail = true;
      })
      .addCase(fetchBookingDetails.rejected, (state) => {
        state.isLoadingBookingDetail = false;
      })
      .addCase(fetchBookingDetails.fulfilled, (state, { payload }) => {
        state.isLoadingBookingDetail = false;
        state.BookingDetails = payload?.content[0];
      });
  },
});

export default BookingDetailSlice.reducer;
