import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { data } from "autoprefixer";
import { logoutUser } from "../user/UserSlice";
import { toast } from "sonner";

const initialState = {
  isLoadingSubCatg: false,
  categoryName: "",
  SubCategories: [],
};

// Get API
export const fetchSubCategory = createAsyncThunk(
  "subcategory/fetchsubcategory",
  async (categoryId, thunkAPI) => {
    // const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/sub/category/${categoryId}`);
      return resp.data;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Post :sub category Search
export const subCategorySearch = createAsyncThunk(
  "subcategory/subCategorySearch",
  async ({ name }, thunkAPI) => {
    // const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(
        "api/sub/category/search/name/" + name
      );
      return resp?.data;
    } catch (error) {
      if (error) {
        alert("Error Occured!");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

//Get all SubCategory
export const fetchAllSubCategory = createAsyncThunk(
  "subcategory/fetchAllSubCategory",
  async (queryParams, thunkAPI) => {
    // const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/sub/category`, {
        query: queryParams,
      });
      return resp.data;
    } catch (error) {
      if (error) {
        toast.error("Error! Please refresh.");
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// PUT API
// export const updateSubCategory = createAsyncThunk(
//   "subCategory/updatesuncategory",
//   async ({ subCatId, data }, thunkAPI) => {
//     console.log(data);
//     const { accessToken } = getUserFromLocalStorage();
//     try {
//       const resp = await customFetch.put(`api/sub/category/${subCatId}`, data, {
//         headers: {
//           authorization: `Bearer ${accessToken}`,
//         },
//       });
//       return resp.data;
//     } catch (error) {
//       if (error) {
//         alert("Error Occured While Updating Sub-Category!");
//       }
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );
const SubCatagorySlice = createSlice({
  name: "subcategory",
  initialState,
  reducers: {
    setLoadingSubCatg: (state) => {
      state.isLoadingSubCatg = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubCategory.pending, (state) => {
        state.isLoadingSubCatg = true;
      })
      .addCase(fetchSubCategory.fulfilled, (state, { payload }) => {
        state.isLoadingSubCatg = false;
        // console.log(payload.content[0].subCategory);
        state.categoryName = payload.content[0]?.categoryName;
        state.SubCategories = payload.content[0]?.subCategory;
      })
      .addCase(fetchSubCategory.rejected, (state) => {
        state.isLoadingSubCatg = false;
      })
      .addCase(subCategorySearch.pending, (state) => {
        state.isLoadingSubCatg = true;
      })
      .addCase(subCategorySearch.fulfilled, (state, { payload }) => {
        state.SubCategories = payload;
        state.isLoadingSubCatg = false;
      })
      .addCase(subCategorySearch.rejected, (state) => {
        state.isLoadingSubCatg = false;
      })
      .addCase(fetchAllSubCategory.pending, (state) => {
        state.isLoadingSubCatg = true;
      })
      .addCase(fetchAllSubCategory.fulfilled, (state, { payload }) => {
        state.SubCategories = payload?.content;
        state.isLoadingSubCatg = false;
      })
      .addCase(fetchAllSubCategory.rejected, (state) => {
        state.isLoadingSubCatg = false;
      });
    // .addCase(updateSubCategory.pending, (state) => {
    //   state.isLoadingSubCatg = true;
    // })
    // .addCase(updateSubCategory.fulfilled, (state, { payload }) => {
    //   alert("SubCategory Updated Successfully!");
    //   state.isLoadingSubCatg = false;
    // })
    // .addCase(updateSubCategory.rejected, (state) => {
    //   state.isLoadingSubCatg = false;
    // });
  },
});
export default SubCatagorySlice.reducer;
export const { setLoadingSubCatg } = SubCatagorySlice.actions;
