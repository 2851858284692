import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { MdVerified, MdDangerous } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { FiRefreshCw } from "react-icons/fi";
import { MdMoreTime } from "react-icons/md";
import { useDispatch } from "react-redux";
import { cancelBooking, postRating } from "../features/Bookings/BookingSlice";
import Bike from "../Images/icons/Bike.svg";
import Item from "../Images/icons/Item.svg";
import Time from "../Images/icons/Time.svg";
import Ratings from "./Ratings";
import WriteReview from "./WriteReview";
import Confirm from "./Notifications/Confirm";

const BookingsCard = ({
  amount,
  Id,
  catagory,
  subcatagory,
  date_time,
  status,
  paymentMethod,
  partnerName,
  partnerNumber,
  partnerImage,
  noOfIssues,
  OrderId,
  couponId,
  handlePayment,
}) => {
  const [isCancelling, setCancelling] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slotDate = new Date(date_time || null);
  const options = { hour: "2-digit", minute: "2-digit" };
  const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
    slotDate
  );
  console.log(formattedTime);

  const [rating, setRating] = useState(0);
  const handleRating = (stars) => {
    dispatch(
      postRating({
        data: { rating: stars, review: "", description: "", show: true },
        bookingId: Id,
      })
    );
  };

  const [writeReview, setWriteReview] = useState();
  const handleWriting = () => {
    setWriteReview(!writeReview);
  };

  const dateDifference = () => {
    const today = new Date();
    const tommorow = new Date();
    const yesterday = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    yesterday.setDate(yesterday.getDate() - 1);

    if (
      today.getDate() === slotDate.getDate() &&
      today.getMonth() === slotDate.getMonth() &&
      today.getFullYear() === slotDate.getFullYear()
    ) {
      return 0;
    } else if (
      tommorow.getDate() === slotDate.getDate() &&
      tommorow.getMonth() === slotDate.getMonth() &&
      tommorow.getFullYear() === slotDate.getFullYear()
    ) {
      return 1;
    } else if (
      yesterday.getDate() === slotDate.getDate() &&
      yesterday.getMonth() === slotDate.getMonth() &&
      yesterday.getFullYear() === slotDate.getFullYear()
    ) {
      return -1;
    } else {
      return 999;
    }
  };
  const bookingSlot =
    dateDifference() === 0
      ? "Today"
      : dateDifference() === 1
      ? "Tommorow"
      : dateDifference() === -1
      ? "Yesterday"
      : slotDate.toDateString() + " " + formattedTime;

  // Navigate function
  const handleClick = (location, id) => {
    if (location?.toLowerCase() === "details") {
      navigate(`/booking-details/${id}`);
    } else {
      navigate(dispatch(cancelBooking({ bookingId: Id })));
    }
  };

  // Cancel Booking
  const handleCancelBooking = (flag) => {
    if (flag) {
      dispatch(
        cancelBooking({
          bookingId: Id,
          couponId: couponId,
        })
      );
    }
    setCancelling(false);
  };

  return (
    <div className="border rounded-lg border-gray-300">
      {/* popups */}
      {isCancelling && (
        <Confirm
          question="Are you sure you want to cancel?"
          handleConfirmation={handleCancelBooking}
          sence={true}
          // description="A <strong>10% cancelation</strong> charge will be asked if you cancel the booking within <strong>1 hour."
        />
      )}
      {writeReview && (
        <WriteReview handleWriting={handleWriting} bookingId={Id} />
      )}
      <div className="relative   flex items-center transition-all ease-in-out duration-100 hover:scale-[1.01] cursor-pointer px-[0.875rem]">
        {/* old ui  */}
        <div
          className="  h-full w-full  py-[1.125rem]  "
          onClick={() => handleClick("details", Id)}>
          <div className="flex items-center gap-5   ">
            <span className=" w-5 flex items-center justify-center">
              <img src={Item} alt="" />
            </span>
            <span className="flex items-center text-sm text-[#000000BF] font-normal">
              <div className="min-w-[12ch]">{OrderId}</div>
              <span className="h-3 border-r-2 border-[#666666] mr-2 ml-3"></span>
              <span className=" text-[1rem] ">₹</span>

              <span className=" text-[1rem]">{amount}</span>
            </span>
          </div>
          <div className="flex items-center gap-5">
            <span className=" w-5 flex items-center justify-center">
              <img src={Bike} alt="" />
            </span>
            <span>
              <span className="flex items-center text-sm text-[#000000BF] font-normal">
                <div className="min-w-[12ch]">{catagory}</div>
                <span className="h-3 border-r-2 border-[#666666] mr-2 ml-3"></span>
                <span className=" text-[1rem]">{subcatagory}</span>
              </span>
            </span>
          </div>

          <div>
            <span className="flex items-center gap-5 ">
              <span className=" w-5 flex items-center justify-center">
                <img src={Time} alt="" />
              </span>

              <span className="text-sm text-[#6B9279] font-semibold">
                {slotDate?.toDateString() + " " + formattedTime}
              </span>
            </span>
          </div>
        </div>
        {/* <div

        className="  flex items-center   min-w-[200px] gap-2 h-full w-full  py-4 pr-8 pl-1  "
        onClick={() => handleClick("details", Id)}>
        <div className="h-24 ">
          {status?.toLowerCase() === "completed" ? (
            <MdVerified className="  text-4xl  text-[#7cc53d] " />
          ) : status?.toLowerCase() === "cancelled" ? (
            <MdDangerous className="  text-4xl  text-[#e44d42] " />
          ) : status?.toLowerCase() === "future" ? (
            <MdMoreTime className="  text-4xl  text-[#4094d8]  " />
          ) : (
            <FiRefreshCw className=" animate-spin text-4xl  text-[#e8d242] rotate-[-175deg] " />
          )}
        </div>
        <div className="flex flex-col gap-1 ">
          <span className="uppercase text-2xl font-semibold flex items-center">
            {"UCB" + Id}
            <span className="h-4 border-r-2 border-black mx-2"></span>
            <span className="text-[#3a85c6] mr-1">₹</span>

            <span className="text-[#f08432]">{amount}</span>
          </span>
          <span className="text-lg">{catagory + " | " + subcatagory}</span>
          <span className="text-md font-semibold text-[#3a85c6] pt-1">
            {bookingSlot}
          </span>
        </div>
      </div> */}

        {/* pay / cancel */}
        {status?.toLowerCase() === "future" ||
        (status?.toLowerCase() === "ongoing" &&
          paymentMethod?.toLowerCase() === "cash") ? (
          <div className="absolute right-5 flex gap-4 text-sm font-semibold ">
            {status?.toLowerCase() === "future" && (
              <button
                className="p-3 w-[9.5vw] border border-[#00000066] rounded-lg  z-10 text-[#676565] hover:bg-[#433f3f18] min-w-max "
                onClick={() => setCancelling(true)}>
                Cancel
              </button>
            )}

            {paymentMethod?.toLowerCase() === "cash" && (
              <button
                className="p-3 w-[9.5vw] border border-primary-600 rounded-lg  bg-primary-600 hover:bg-primary-max  text-white z-10 min-w-max"
                onClick={() => handlePayment(Id, amount)}>
                Pay Now
              </button>
            )}
          </div>
        ) : (
          <div className=" absolute right-4 p-2  ">
            <IoIosArrowForward className="text-xl text-gray-600" />
          </div>
        )}
      </div>
      {status?.toLowerCase() === "completed" && (
        <div className="px-[2rem] flex items-center h-[3rem] border-t border-gray-300">
          <div className="flex items-center w-3/4 gap-[0.7rem]">
            <div className="text-sm">Rate our Service</div>
            <div className="flex gap-[0.3rem] text-[24px] w-[10rem] cursor-pointer">
              <Ratings ratings={rating} getStar={handleRating} isHover={true} />
            </div>
          </div>
          <div
            className="flex w-1/4 justify-end font-bold underline text-pink-600 cursor-pointer"
            onClick={handleWriting}>
            Write Review
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingsCard;
